import React from 'react'
import { IncidentById, selectIncidentById } from '../reduxToolkit/selectors/incidentById'
import { NotFound } from '../screens/notFound'
import { useParams } from 'react-router'
import { useAppSelector } from '../hooks/reduxToolkit'
import { SplashScreen } from '../screens/splashScreen'

export interface RequireIncidentProvided {
  incident: IncidentById
}

export function requireIncident<TProps extends React.PropsWithChildren<object>>(
  Component: React.ComponentType<RequireIncidentProvided & TProps>
): React.ComponentType<TProps> {

  return function IncidentRequired(props: TProps) {
    let params = useParams();
    const incidentId = params['incidentId'] || params['id']
    if (!incidentId) { return <NotFound /> }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const incident = useAppSelector(selectIncidentById(incidentId))
    if (!incident) {
      // Render splash screen until we load the incident
      return <SplashScreen timeout={15000} />
    }

    return <Component incident={incident} {...props} />
  }
}
