import React, { useCallback } from "react";
import Dropzone from 'react-dropzone'
import { OnUploadCallback, useBlobUpload } from "../hooks/useBlobUpload";

interface BlobUploadDropzoneProps<TResult> {
  onUpload: OnUploadCallback<TResult>
  
  maxFiles?: number

  children?: React.ReactNode
}

export function BlobUploadDropzone<TResult>({
  onUpload,
  maxFiles,
  children
}: BlobUploadDropzoneProps<TResult>) {
  const [uploadingState, onDrop] = useBlobUpload(onUpload)

  let icon = children || [<i key='icon' className='material-icons-outlined'>photo_camera</i>]
  if (uploadingState.filter((s) => !s.complete).length > 0) {
    icon = <div className="dropzone">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
  }

  return <div className='dropzone'>
    <Dropzone onDrop={onDrop} maxFiles={maxFiles || 1}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />

          <div className="dropzone__content">{icon}</div>
        </div>
      )}
    </Dropzone>
  </div>
}
