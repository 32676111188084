
import { useMemo, useState } from "react";
import { useAppSelector } from "../../hooks/reduxToolkit";
import { formatDateInTimeZone } from "../../../lib/formatDateInTimeZone";
import { byStartDateDescending } from "../../../lib/util/sort";
import { Patient } from "../../reduxToolkit/dependentsSlice";
import { IncidentModel } from "../../reduxToolkit/incidentsSlice"
import { DropdownSelect, DropdownSelectProps } from "../dropdownSelect";
import { addDays, addMonths, startOfYear } from "date-fns";
import { present } from "../../../lib/util/present";

interface IncidentSelectProps extends Omit<DropdownSelectProps<IncidentModel>, 'options' | 'renderOption' | 'onChange'> {
  renderOption?: (value: IncidentModel) => React.ReactNode

  patient?: Patient | null | undefined | false

  denyNewIncidents?: boolean
  denySubmittedIncidents?: boolean
  denyOldIncidents?: boolean

  hideUnsubmittedIncidents?: boolean
  showSubmittedIncidents?: boolean
  showOldIncidents?: boolean

  onSelect?: (incident: IncidentModel | null) => void
  onSelectAdditionalOption?: (key: string) => void
  onSelectNewIncident?: () => void
  
  children?: React.ReactNode
}

type IncidentMap = {
  [id: string]: {
    submitted?: boolean
    old?: boolean,
    fromLastYear?: boolean,
    matchesPatient?: boolean
  }
}

export function IncidentSelect({
  renderOption,
  onSelect,
  onSelectNewIncident,
  patient,
  denyNewIncidents,
  denyOldIncidents,
  denySubmittedIncidents,
  hideUnsubmittedIncidents,
  showSubmittedIncidents: showSubmittedIncidentsControlled,
  showOldIncidents: showOldIncidentsControlled,
  value,
  children,
  ...props
}: IncidentSelectProps) {
  const sixMonthsAgo = useMemo(() => addDays(addMonths(Date.now(), -6), -1).toISOString(), [])
  const startOfThisYear = useMemo(() => startOfYear(new Date(Date.now())).toISOString(), [])
  
  const incidentMap: IncidentMap = useAppSelector((s) => {
    const incidentMap: IncidentMap = {}
    s.incidents.incidents.forEach((i) => {
      const incidentExpenses = s.expenses.expenses.filter((e) => e.incident_id === i.id)
      
      incidentMap[i.id] = {
        submitted: incidentExpenses.every((e) => present(e.submitted_at)),
        old: incidentExpenses.every((e) => e.date && e.date < sixMonthsAgo),
        fromLastYear: i.start_date < startOfThisYear,
        // if no "patient" is provided, then every incident matches the patient.
        matchesPatient: patient ? (i.patient_name === patient.full_name && i.patient_dob === patient.date_of_birth) : true
      }
    })
    return incidentMap
  })

  const hasOpenIncidents = Object.values(incidentMap).some((i) => !i.submitted && !i.old)
  const hasSubmittedIncidentsThatAreNotOld = Object.values(incidentMap).some((i) => i.submitted && !i.old)
  const hasOldIncidents = Object.values(incidentMap).some((i) => i.old)
  
  const [_showSubmittedIncidents, setShowSubmittedIncidents] = useState(!hasOpenIncidents)
  const showSubmittedIncidents = showSubmittedIncidentsControlled ?? _showSubmittedIncidents
  const [_showOldIncidents, setShowOldIncidents] = useState(!hasOpenIncidents && !hasSubmittedIncidentsThatAreNotOld && showSubmittedIncidents)
  const showOldIncidents = showOldIncidentsControlled ?? _showOldIncidents

  let selectedIncidentId: string | null = null
  if (typeof value == 'string') {
    selectedIncidentId = value
  } else if (value && 'id' in value) {
    selectedIncidentId = value.id
  }

  const incidentIDs: string[] = []
  for (const id in incidentMap) {
    const { submitted, old, matchesPatient } = incidentMap[id]
    if (!matchesPatient) { continue }

    if (!submitted && !old) {
      if (!hideUnsubmittedIncidents) {
        incidentIDs.push(id)
      }
    } else if (submitted && !old) {
      if (showSubmittedIncidents) {
        incidentIDs.push(id)
      }
    } else {
      if (showOldIncidents) {
        incidentIDs.push(id)
      }
    }
  }
  // always include the selected incident in the list of options
  if (selectedIncidentId) {
    incidentIDs.push(selectedIncidentId)
  }

  const incidentOptions = useAppSelector((s) =>
    s.incidents.incidents.filter((i) => incidentIDs.includes(i.id))
  )

  incidentOptions.sort(byStartDateDescending)
  
  // Show the "Show Submitted Incidents" option if there are submitted incidents that are not old
  const revealShowSubmittedIncidentsOption = !denySubmittedIncidents && !showSubmittedIncidents && hasSubmittedIncidentsThatAreNotOld
  // show the "Show Older Incidents" option only if we are not showing the "reveal submitted incidents" option
  const revealShowOldIncidentsOption = !denyOldIncidents && !showOldIncidents && hasOldIncidents && !revealShowSubmittedIncidentsOption

  const additionalIncidentOptions: Array<{ key: string, label: React.ReactNode }> = [
    !denyNewIncidents && { key: '!new', label: <><i className='material-icons'>add</i>&nbsp;New Incident</> },
    revealShowSubmittedIncidentsOption &&
      { key: '!showSubmittedIncidents', label: <><i className='material-icons'>expand_more</i>Show Submitted Incidents</> },
    revealShowOldIncidentsOption &&
      { key: '!showOldIncidents', label: <><i className='material-icons'>expand_more</i>Show Older Incidents</> },
  ].filter(present)
  
  const hasExhaustedFilteringOptions = showOldIncidents && showSubmittedIncidents
  
  return <><DropdownSelect {...props}
    value={value}
    options={incidentOptions}
    additionalOptions={[
      ...additionalIncidentOptions,
      ...props.additionalOptions || []
    ]}
    renderOption={renderOption ||
      ((i) => {
        const {old, submitted, fromLastYear} = incidentMap[i.id] ?? {}

        return <span className={old || submitted ? 'muted' : ''}>
          {[
            formatDateInTimeZone(i.start_date, { format: fromLastYear ? 'LLL d, yyyy' : 'LLL d' }),
            i.description,
            !patient ? i.patient_name.split(' ')[0] : null
          ].filter(present).join(' - ')}
        </span>
      })}
    onChange={(e) => {
      if (e.value && 'key' in e.value) {
        if (props.onSelectAdditionalOption &&
            props.additionalOptions?.map(x => x.key).includes(e.value.key)) {
          props.onSelectAdditionalOption?.(e.value.key)
        } else {
          switch (e.value.key) {
            case '!new':
              onSelectNewIncident?.()
              return
            case '!showSubmittedIncidents':
              e.preventDefault();
              setShowSubmittedIncidents(true)
              return
            case '!showOldIncidents':
              e.preventDefault();
              setShowOldIncidents(true)
              return
          }
        }
        return
      }

      onSelect?.(e.value)
    }}>
      {props.required &&
        <div className="invalid-tooltip">
          Please select an incident.
        </div>}
        
      {children}
    
        
    {
        hasExhaustedFilteringOptions && Object.values(incidentMap).some((i) => !i.submitted && !i.old && !i.matchesPatient) &&
        <div className="w-100">
          <p className="text-muted">
            If the incident you're looking for isn't listed, try changing the patient.
          </p>
        </div>
      }
    </DropdownSelect>
    </>
}
