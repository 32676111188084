import { IncidentModel } from "../../../app/reduxToolkit/incidentsSlice"
import { formatDateInTimeZone } from "../../formatDateInTimeZone"
import {fillPdf} from '../fillPdf'

const formFields = [
  'Name',
  'Text2',
  'Text3',
  'Text4',
  'Text5',
  'Text6',
  'Text7',
  'Where did it happen?',
  'When did it happen?',
  'What Happened?',
  'Anything to Add?'
] as const

type LetterOfExplanationFormField = typeof formFields[number]


export interface LetterOfExplanationExtraData {
  what?: string
  where?: string,
  additionalInfo?: string
}

export async function fillLetterOfExplanation(
  pdfBytes: string | Uint8Array | ArrayBuffer,
  {membership, incident}: {
    membership: { member_number?: string | null },
    incident: Pick<IncidentModel, 'start_date' | 'patient_name' | 'description'>
  },
  extraData: LetterOfExplanationExtraData | null | undefined
): Promise<Uint8Array> {

  const fields: { [k in LetterOfExplanationFormField]?: string | null | undefined } = {
    Name: incident?.patient_name
  }

  if (membership?.member_number) {
    for (let i = 0; i < 6; i++) {
      fields[`Text${i + 2}` as LetterOfExplanationFormField] = membership.member_number.charAt(i)
    }
  }

  const what = extraData?.what || incident?.description
  if (what) {
    fields['What Happened?'] = what
  }

  if (extraData?.where) {
    fields['Where did it happen?'] = extraData.where
  }

  if (incident?.start_date) {
    fields['When did it happen?'] = formatDateInTimeZone(incident.start_date, { format: 'MMM d, yyyy'})
  }

  if (extraData?.additionalInfo) {
    fields['Anything to Add?'] = extraData.additionalInfo
  }

  return fillPdf(pdfBytes, fields)
}
