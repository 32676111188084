import { present } from "../../../../../../../lib/util/present"
import { ExpenseModel } from "../../../../../../reduxToolkit/expensesSlice"
import { SubmissionData2024Expense, SubmissionData2024_MedicalBillWorksheetData } from "../../../../../../reduxToolkit/submissionsSlice"

/**
 * Ensures that the data in the MedicalBillWorksheet matches the expense data for this incident, including
 * any new pending expenses that have been added since the last time the data was saved.
 */
export function reconcileExpenses<TData extends { medicalBillWorksheetData: SubmissionData2024_MedicalBillWorksheetData }>(
  data: TData,
  expenses: ExpenseModel[]
): TData {
  for(const expense of expenses) {
    const {paidAmount, listedAmount} = expense
    let discountAmount: number | undefined = undefined
    if (present(paidAmount) && present(listedAmount)) {
      discountAmount = Math.round((parseFloat(listedAmount) * 100) - (parseFloat(paidAmount) * 100)) / 100
    }

    // These fields can't be changed when we get to the MedicalBillWorksheet, they need to match the data in the expense table
    let expenseRow: SubmissionData2024Expense = { // type assertion
      expenseId: expense.id,
      dateOfService: expense.date || '',
      billingProvider: expense.provider || '',
      paymentAmount: paidAmount,
      originalCharges: listedAmount,
      discountAmount: typeof discountAmount != 'undefined' ? discountAmount.toFixed(2) : null,
    }
    
    const existingIdx = data.medicalBillWorksheetData.expenseRows.findIndex((r) => r.expenseId === expense.id)
    if (existingIdx >= 0) {
      expenseRow = Object.assign(data.medicalBillWorksheetData.expenseRows[existingIdx], expenseRow)
    } else {
      data.medicalBillWorksheetData.expenseRows.push(expenseRow)
    }
    
    data.medicalBillWorksheetData.expenseRows.sort(byDateOfService)
  }
  
  return data
}

function byDateOfService(a: SubmissionData2024Expense, b: SubmissionData2024Expense): number {
  return a.dateOfService.localeCompare(b.dateOfService)
}
