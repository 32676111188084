
export function openFile(
  data: Blob,
  fileName?: string
) {
  const downloadUrl = window.URL.createObjectURL(data)
  const tempLink = document.createElement('a')
  tempLink.href = downloadUrl
  if (fileName) {
    tempLink.setAttribute('download', fileName)
  }
  tempLink.target = '_blank'

  tempLink.click()
}
