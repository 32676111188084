
export function downloadFile(
  filename: string,
  data: Blob
) {
  const downloadUrl = window.URL.createObjectURL(data)
  const tempLink = document.createElement('a')
  tempLink.href = downloadUrl
  tempLink.setAttribute('download', filename)
  tempLink.click()
}