import React, { useEffect } from "react"
import { ProfileModel } from "../../reduxToolkit/membershipSlice"
import { SignatureCanvas } from "../signatureCanvas"
import { Tooltip } from "../tooltip"
import trimCanvas from "trim-canvas"
import toast from "react-hot-toast"
import { keysPresent, present } from "../../../lib/util/present"
import { NotNull } from "../../../types/util"

import './profileForm.scss'

interface ProfileFormProps {
  initial?: Partial<ProfileModel> | null

  hideSignature?: boolean

  /**
   * On submit, the only thing we can say about the profile is that it has the required fields.
   */
  onSubmit: (profile: FormFilledProfile) => Promise<void> | void
}

/**
 * The list of fields marked as "required" in the ProfileForm
 */
const RequiredFields = [
  'full_name',
  'date_of_birth',
  'phone',
  'address'
] as const
export type ProfileFormRequiredFields = typeof RequiredFields[number]

export type FormFilledProfile = NotNull<Partial<ProfileModel>, ProfileFormRequiredFields>

export function ProfileForm({
  initial,
  hideSignature,
  onSubmit: _onSubmit
}: ProfileFormProps) {
  const [row, setRow] = React.useState<Partial<ProfileModel>>(initial || {})
  const [submitting, setSubmitting] = React.useState(false)

  return <form className="profile-form" onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="fullName" className="form-label">Full Name</label>
        <input type="text" className="form-control" id="fullName"
          required
          value={row.full_name|| ''}
          onChange={(e) => { setRow({...row, full_name: e.target.value }) }} />
      </div>
      <div className="form-group">
        <label htmlFor="dob" className="form-label">Date of Birth</label>
        <input name="datepic" placeholder="DateRange" type="date" id="dob"
          className="form-control"
          required
          value={row.date_of_birth || ''}
          onChange={(e) => { setRow({...row, date_of_birth: e.target.value }) }}></input>
      </div>

      <div className="form-group row">
        <div className='col-12 col-lg-9'>
          <label htmlFor="phoneNum" className="form-label">Phone Number</label>
          <input type="phone" className="form-control" id="phoneNum"
            required
            value={row.phone|| ''}
            onChange={(e) => { setRow({...row, phone: e.target.value }) }} />
          </div>

        <div className='col-12 col-lg-3'>
          <label htmlFor="phoneType" className="form-label">Phone Type</label>
          <select className="form-select" aria-label="Default select example"
              value={row.phone_type || ''}
              onChange={(e) => { setRow({...row, phone_type: e.target.value }) }}>
            <option value="cell">Cell</option>
            <option value="home">Home</option>
            <option value="work">Work</option>
          </select>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="memberNumber" className="form-label">Full Address</label>
        <textarea className="form-control" id="address"
          required
          value={row.address|| ''}
          onChange={(e) => { setRow({...row, address: e.target.value }) }} />
        <small className="form-text text-muted">
          Use "enter" to separate lines.
        </small>
      </div>
      
      <hr />

      {!hideSignature && <div className="col-12 signature-row">
        <label>
          Your E-Signature
          &nbsp;
          <Tooltip tooltip="We use this to sign CHM forms on your behalf."/>
        </label>
        <SignatureCanvas data={row.signature_data}
        onChange={(data) => { setRow({...row, signature_data: data }) }} />
      </div>}

      <div className="col-12 mt-2">
          <button type='submit'
              className={`btn btn-primary`}
          >
            {submitting ?
              <span className="spinner-border" role="status">
                <span className="visually-hidden">Submitting...</span>
              </span> :
              `Submit`}
          </button>
      </div>
    </form>

  function onSubmit(e: React.FormEvent) {
    e.preventDefault()
    if (!(e.target as HTMLFormElement).checkValidity()) { return }

    async function doSubmit() {
      const canSubmit = keysPresent(...RequiredFields)
      if (!canSubmit(row)) { throw new Error('Profile not complete!') }

      setSubmitting(true)
      try {
        // clone the row so we can postprocess it (react state is read only)
        const toSubmit = { ...row }
        if (present(toSubmit.signature_data)) {
          toSubmit.signature_data = await postProcessSignatureData(toSubmit.signature_data)
        }

        await _onSubmit(toSubmit)
      } catch (e) {
        console.error(e)
        toast.error('Something went wrong!  Please try again')
      } finally {
        setSubmitting(false)
      }
    }
    doSubmit()
  }
}

/**
 * Trims the signature data to the actual signature
 */
export function postProcessSignatureData(imageDataUrl: string): Promise<string> {
  // write the image to the canvas
  let canvas = document.createElement('canvas')
  let ctx = canvas.getContext('2d')!
  let img = new Image()
  img.src = imageDataUrl

  return new Promise<string>((resolve, reject) => {
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      
      // then trim it
      canvas = trimCanvas(canvas)
      
      resolve(canvas.toDataURL())
    }
    img.onerror = reject
  }).finally(() => {
    canvas.remove()
  })
}
