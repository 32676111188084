import { HTMLInputTypeAttribute, useCallback } from "react"
import { present } from "../../../../../../../lib/util/present"


export function TextInputGroup<TKey extends string>({
  field,
  label,
  value,
  inputType = 'text',
  placeholder,
  changeData,
  validationError,
  className,
  children
}: React.PropsWithChildren<{
  field: TKey,
  label?: string | null | undefined,
  value: string | null | undefined,
  inputType?: HTMLInputTypeAttribute,
  placeholder?: string,
  changeData: (field: TKey, value: string | null | undefined) => void,
  className?: string | null,
  validationError?: { message: string },
}>) {
  if (!placeholder) {
    if (inputType === 'date') {
      placeholder = "  /  /    "
    }
  }
  return <div className={`input-group ${className}`}>
    {label && <label className="input-group-text">{label}</label>}

    <input type={inputType || 'text'}
      id={field}
      placeholder={placeholder}
      className={`form-control ${validationError && 'is-invalid'}`}
      value={value || undefined}
      onChange={
        useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
          const value = present(evt.target.value) ? evt.target.value : null
          changeData(field, value)
        }, [field, changeData])
      }/>

    <div className="invalid-feedback">{validationError?.message}</div>

    {children}
  </div>
}
