
import { useCallback } from "react";
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from "./reduxToolkit";
import { useConfirm } from "../providers/confirmDialog";
import { merge } from "lodash";
import { useClient } from "../providers/supabase";
import { setSettings } from "../reduxToolkit/membershipSlice";
import { useSentry } from "../providers/sentry";

export function useTextractOptInConfirm() {
  const settings = useAppSelector((s) => s.membership.settings)
  const [show] = useConfirm()
  const dispatch = useAppDispatch()
  const client = useClient()
  const { captureException } = useSentry()
  
  const optedInToOCR = settings?.textract_opt_in === true
  
  const setPreference = useCallback(async (optedIn: boolean) => {
    if (!settings) { throw new Error('Settings not loaded') }

    try {
      const now = new Date().toISOString()
      const result = await client.from('settings')
        .update({
          textract_opt_in: optedIn,
          updated_at: now
        })
        .eq('id', settings.id)

      if (result.error) { throw result.error }

      dispatch(setSettings({
        settings: merge({}, settings, {
          textract_opt_in: optedIn,
          updated_at: now
        })
      }))
    } catch (e) {
      console.error(e)
      captureException(e)
      toast.error('Unable to save your preference.  We will ask again later.')
    }
  }, [settings, client, dispatch, captureException])

  const confirm = useCallback<() => Promise<boolean>>(async () => {

    if (optedInToOCR) { return true }
    // If the user has already opted out, don't ask again
    const dontShowAgain = settings?.textract_opt_in === false
    if (dontShowAgain) { return false }

    const confirmed = await show({
      title: 'Opt In to Automatic Document Scanning',
      message: 
        <div className="disclaimer">
          <p>
            I understand that document scanning is an experimental feature and that I may need to
            manually verify the results of scanning.  I understand that HealthShare tech makes no guarantees
            about the accuracy of the scanned data.
          </p>
          <p>
            Healthshare Tech does not use your scanned data for any purpose other than to populate your CHM forms.
            See our <a href="https://app.termly.io/document/privacy-policy/bf079259-ff12-4a46-9167-dbb4415f71c1" target='_blank' rel="noreferrer">Privacy Policy</a> for
            more information.
          </p>
        </div>,
      actions: [
        {
          title: 'Opt In',
          isConfirm: true,
        },
        {
          title: "Opt Out",
          isCancel: true,
        },
      ],
    })

    setPreference(confirmed)
    return confirmed
  }, [optedInToOCR, setPreference, settings?.textract_opt_in, show])
  
  return [
    optedInToOCR,
    confirm
  ] as const
}
