import * as bootstrap from "bootstrap";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import usePortal from "react-useportal";

interface ConfirmParams {
  title?: string
  message: string | React.ReactNode
  
  actions?: ConfirmAction[]
  
  onConfirm?: () => void
  onCancel?: () => void
}

type ConfirmResult = 'confirmed' | 'cancelled'

interface ConfirmAction {
  title: string
  style?: 'primary' | 'secondary' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link' |
    'outline-primary' | 'outline-secondary' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-light' | 'outline-dark'
  onClick?: () => void
  isConfirm?: boolean
  isCancel?: boolean
}

interface ConfirmDialogProviderContext {
  show: (params: ConfirmParams) => Promise<boolean>;
  lastResult: ConfirmResult | undefined;
}

const context = createContext<ConfirmDialogProviderContext>({
  show: () => {throw new Error('ConfirmDialogProvider not initialized')},
  lastResult: undefined,
});

export function ConfirmDialogProvider({children}: {children: React.ReactNode}) {
  const { Portal } = usePortal()
  
  const [shown, setShown] = useState<ConfirmParams | undefined>()
  const [result, setResult] = useState<ConfirmResult | undefined>()
  const [resolve, setResolve] = useState<{ resolve: (result: boolean) => void, reject: (err: Error) => void }>()
  
  const uniqueId = useMemo<number>(() => Math.floor(Math.random() * 10_000), [])
  const domId = `modal-confirm-${uniqueId}`

  const show = useCallback((params: ConfirmParams) => {
    setResult(undefined)
    setResolve(undefined)
    setShown({
      ...params,
    })
    const modalElem = document.getElementById(domId)
    const modal = new bootstrap.Modal(modalElem!, {
      backdrop: 'static',
      keyboard: false,
    })
    modal?.show()
    
    return new Promise<boolean>((resolve, reject) => {
      setResolve({
        resolve,
        reject
      })
    })
  }, [domId])
  
  const hide = useCallback((result: ConfirmResult) => {
    const modalElem = document.getElementById(domId)
    const modal = bootstrap.Modal.getInstance(modalElem!)
    modal?.hide()
    
    setResult(result)
    resolve?.resolve(result === 'confirmed')
    setShown(undefined)
  }, [domId, resolve])
  
  // Hook into dom events in case the user clicks outside the modal
  useEffect(() => {
    const onHidden = () => {
      hide('cancelled') 
    }
    
    const modalElem = document.getElementById(domId)
    modalElem?.addEventListener('hidden.bs.modal', onHidden)

    return () => {
      const modalElem = document.getElementById(domId)
      modalElem?.removeEventListener('hidden.bs.modal', onHidden)
      const modal = bootstrap.Modal.getInstance(modalElem!)
      modal?.hide()
    }
  }, [domId, hide])
  
  const actions = shown?.actions || [
    {
      title: 'Cancel',
      isCancel: true,
    },
    {
      title: 'Confirm',
      isConfirm: true,
    }
  ]
  
  return <context.Provider value={{
    show,
    lastResult: result,
  }}>
    {children}
    <Portal>
      <div className="modal modal-lg fade" id={domId} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5">{shown?.title}</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {shown?.message}
            </div>
            <div className="modal-footer">
              {actions.map((action, i) => {
                const style = action.style ||
                  (action.isConfirm ? 'primary' : action.isCancel ? 'secondary' : 'primary')
                
                return <button key={i} type="button" className={`btn btn-${style}`}
                  data-bs-dismiss={action.isCancel || action.isConfirm ? 'modal' : undefined}
                  onClick={() => {
                    action.onClick?.()

                    if (action.isConfirm) {
                      hide('confirmed')
                    } else if(action.isCancel) {
                      hide('cancelled')
                    }
                  }}>{action.title}</button>
              })}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  </context.Provider>
  
  
}

export function useConfirm() {
  const { show, lastResult } = useContext(context) as ConfirmDialogProviderContext
  
  return [
    show,
    lastResult,
  ] as const
}
