import { useDispatch } from "react-redux"
import { useClient } from "../providers/supabase"
import { useAsyncAction } from "./useAsyncAction"
import { useUser } from "./useUser"
import { ProfileModel, setProfile } from "../reduxToolkit/membershipSlice"
import merge from "lodash/merge"
import { useAppSelector } from "./reduxToolkit"
import { selectUsersAndDependentsForMembership } from "../../lib/queries/selectUsersAndDependentsForMembership"
import { onSyncDependents } from "../reduxToolkit/dependentsSlice"

export function useUpdateProfile() {
  const user = useUser()
  const client = useClient()
  const dispatch = useDispatch()
  const profile = useAppSelector((s) => s.membership.profile)
  const membershipId = useAppSelector((s) => s.membership.membershipId)
  
  return useAsyncAction(async (unsavedProfile: Partial<ProfileModel>) => {
    // Ensure the profile has your userId
    const newProfile = {
      ...unsavedProfile,
      id: user.id,
      updated_at: new Date().toISOString()
    }
    const response = await client.from('profiles')
      .upsert(newProfile)
      .select('*')
      .single()

    if (response.error) {
      throw response.error
    }

    dispatch(setProfile({
      profile: merge({}, profile, newProfile, response.data)
    }))
    
    // Now that we've created the profile, we need to keep the local dependents list in sync.
    const result = await selectUsersAndDependentsForMembership(client, { membershipId })
    dispatch(onSyncDependents({
      ...result
    }))

    return response
  }, [client, user.id, membershipId])
}
