import { useMemo } from "react";
import max from "lodash/max";
import { ExpenseModel } from "../reduxToolkit/expensesSlice";
import { useFuseSearch } from "./useFuseSearch";
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone";

interface ExpenseDocument extends ExpenseModel {
  longDate?: string | null
}

const searchKeys: Array<keyof ExpenseDocument> = ['provider', 'date', 'listedAmount', 'paidAmount', 'patient_name', 'longDate']

export function useExpenseSearch(
  expenses: ExpenseModel[],
) {
  const updatedAt = max(expenses?.map((e) => e.updated_at)) || 0

  const docs: Array<ExpenseDocument> | undefined = useMemo(() => {
    return expenses?.map((e) => {
      return {
        ...e,
        longDate: e.date ? formatDateInTimeZone(e.date, { format: 'MMMM dd, yyyy' }) : null
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAt])

  return useFuseSearch(
      docs || [],
      {
        findAllMatches: true,
        includeScore: true,
        keys: searchKeys,
      },
      [
        // Reindex the fuse search whenever the expenses change (i.e. the latest updated at changes)
        updatedAt,
      ]
    )
}
