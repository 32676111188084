import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateSubmission } from "../submissionsSlice";
import uniq from "lodash/uniq";

interface MarkSubmissionAsRepaidPayload {
  submission_ids: string[],
}

export const markSubmissionAsRepaid = createAsyncThunk('submissions/markSubmissionAsRepaid',
  async (payload: MarkSubmissionAsRepaidPayload, {dispatch}) => {
    const { submission_ids } = payload
    const now = new Date().toISOString()

    for (const submission_id of uniq(submission_ids)) {
      dispatch(
        updateSubmission({
          id: submission_id,
          updated_at: now,
          repaid_at: now,
        })
      )
    }
  })
