import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/reduxToolkit";
import { useClient } from "../providers/supabase";
import { SyncActor } from "../../lib/sync/syncActor";
import { store } from "../reduxToolkit/store";
import { useSentry } from "../providers/sentry";

/**
 * This listener, when mounted, fires up the SyncActor to run in the background.
 */
export function SyncActorListener() {
  const client = useClient()
  const dispatch = useAppDispatch()
  const {captureException, addBreadcrumb} = useSentry()

  // We use membershipId here because the SyncActor should be restarted whenever the membershipId changes
  const membershipId = useAppSelector(state => state.membership?.membershipId)

  useEffect(() => {
    if (!membershipId) {
      // No need to sync until we've signed in
      return
    }

    const actor = new SyncActor(client, store, dispatch)
    const errorHandler = (error: Error) => {
      captureException(error, {
        tags: {
          category: 'syncActor',
          background: true
        }
      })
      console.error('SyncActor error', error)
    }
    actor.on('error', errorHandler)
    const breadcrumbHandler =
      (message: string) => {
        console.info('SyncActor info', message)
        addBreadcrumb({
          message,
          category: 'syncActor',
          level: 'info',
        })
      }
    actor.on('info', breadcrumbHandler)

    actor.start()

    return () => {
      actor.stop()
      actor.off('error', errorHandler)
      actor.off('info', breadcrumbHandler)
    }
  }, [client, dispatch, captureException, membershipId, addBreadcrumb])

  return null;
}
