import { present } from "../../../../../../../lib/util/present";
import { useAppSelector } from "../../../../../../hooks/reduxToolkit";
import { useUpdateProfile } from "../../../../../../hooks/useUpdateProfile";
import { useAsyncAction } from "../../../../../../hooks/useAsyncAction";
import { ProfileModel } from "../../../../../../reduxToolkit/membershipSlice";
import { SubmissionData2024CHM, SubmissionData2024CHMAddon } from "../../../../../../reduxToolkit/submissionsSlice";
import { useUpdateDependents } from "../../../../../../hooks/useUpdateDependents";
import { DependentRow } from "../../../../../../../types/supabase";

/**
 * Backfills profile data based on submission data, when the user commits the submission and generates documents.
 */
export function useCommitSubmission(
  {
    data,
    submission
  }: {
    data: SubmissionData2024CHM | SubmissionData2024CHMAddon,
    submission: { id: string }
}) {
  const profile = useAppSelector((s) => s.membership.profile)
  const dependents = useAppSelector((s) => s.dependents.lastSync?.dependents)
  const [_, updateProfile] = useUpdateProfile()
  const [__, updateDependent] = useUpdateDependents()
  
  return useAsyncAction(async () => {
    // Update profile fields from the submission data if missing
    if ('sharingRequestFormData' in data && data.sharingRequestFormData && profile) {
      const profileUpdate: Partial<ProfileModel> = {}
      if (!present(profile.phone) && present(data.sharingRequestFormData.phone)) {
        profileUpdate.phone = data.sharingRequestFormData.phone
      }
      if (!present(profile.full_name) && present(data.sharingRequestFormData.memberName)) {
        profileUpdate.full_name = data.sharingRequestFormData.memberName
      }
      
      const patientName = data.sharingRequestFormData.patientName
      const patientDOB = data.sharingRequestFormData.patientDOB
      if (profile.full_name == patientName && profile.date_of_birth == patientDOB) {
        // If this incident is for the user, update the profile with the signature data
        if (!present(profile.signature_data) && present(data.sharingRequestFormData.signatureData)) {
          profileUpdate.signature_data = data.sharingRequestFormData.signatureData
        }
      }
      
      if (Object.keys(profileUpdate).length > 0) {
        await updateProfile(profileUpdate)
      }
    }
    
    if ('medicalInformationReleaseData' in data && data.medicalInformationReleaseData) {
      // is this a submission for a dependent?
      const name = data.medicalInformationReleaseData.patientName
      const dob = data.medicalInformationReleaseData.patientDOB
      const dependent = dependents?.find((d) => d.full_name == name && d.date_of_birth == dob)
      if (dependent) {
        // If so, preserve the signature data for next time
        const dependentUpdate: Partial<DependentRow> = {}
        if (present(data.medicalInformationReleaseData.signatureData)) {
          dependentUpdate.signature_data = data.medicalInformationReleaseData.signatureData
        }
        if (present(data.medicalInformationReleaseData.authorizedRepresentativeRelationship)) {
          dependentUpdate.authorized_representative_relationship = data.medicalInformationReleaseData.authorizedRepresentativeRelationship
        }
        if (present(data.medicalInformationReleaseData.printedNameOfAuthorizedRepresentative)) {
          dependentUpdate.authorized_representative_name = data.medicalInformationReleaseData.printedNameOfAuthorizedRepresentative
        }
        if (present(data.medicalInformationReleaseData.ssnLast4)) {
          dependentUpdate.ssn_last_4 = data.medicalInformationReleaseData.ssnLast4
        }

        if (Object.keys(dependentUpdate).length > 0) {
          updateDependent({
            id: dependent.id,
            ...dependentUpdate
          })
        }
      } else if (profile?.full_name  == name && profile?.date_of_birth == dob) {
        // No it's for the main user.
        const profileUpdate: Partial<ProfileModel> = {}
        if (present(data.medicalInformationReleaseData.ssnLast4)) {
          profileUpdate.ssn_last_4 = data.medicalInformationReleaseData.ssnLast4
        }

        if (Object.keys(profileUpdate).length > 0) {
          await updateProfile(profileUpdate)
        }
      }
    }
  })
}
