import { Session } from "@supabase/supabase-js"
import React, { useState } from "react"
import { useAsyncAction } from "../../hooks/useAsyncAction"
import { useClient } from "../../providers/supabase"

interface SignInFormProps {
  onLoginSuccess?: (data: {session: Session}) => any
}

interface SignInFormState {
  email: string
  password: string
}

export function SignInWithPasswordForm({
  onLoginSuccess
}: SignInFormProps) {
  const client = useClient()
  const [state, setState] = useState<SignInFormState>({
    email: '',
    password: '',
  })

  const [{loading: submitting, error}, submit] = useAsyncAction(async (e: React.FormEvent) => {
    e.preventDefault()
    if (!state.email) { throw new Error(`Email is required`) }
    if (!state.password) { throw new Error(`Password is required`) }

    const resp = await client.auth.signInWithPassword({
      email: state.email,
      password: state.password,
    })
    if (resp.error) { throw resp.error }

    if (onLoginSuccess) { onLoginSuccess({ session: resp.data.session! }) }
    return resp.data
  }, [onLoginSuccess, state.email, state.password])

  const canSubmit = state.email && state.password

  return <form onSubmit={submit}>
      <div className="mb-3">
        <span className='form-label'>Email</span>
        <input name="email" type="text"
          className="form-control"
          required
          disabled={submitting}
          value={state.email}
          onChange={(e) => { setState({...state, email: e.target.value }) }}></input>
      </div>

      <div className="mb-3">
        <span className='form-label'>Password</span>
        <input type='password' className='form-control'
          required
          value={state.password}
          onChange={(e) =>  setState({...state, password: e.target.value})}></input>
      </div>

      <div className="mb-3 d-flex">
      {submitting ?
        <button className='btn btn-primary disabled'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </button> :
        <button type='submit' className='btn btn-primary' disabled={!canSubmit}>Submit</button>}
      </div>

      <div className="mb-3">
        {error &&
          <p className="text-danger">
            {error.message}
          </p>}
      </div>

  </form>
}
