import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxToolkit";
import { present } from "../../lib/util/present";

import './notifications.scss'

export default function Notifications() {
  const isAccountDeletionRequested = useAppSelector((s) => present(s.membership?.profile?.account_deletion_requested_at))
  
  return <div className="notifications">
      {isAccountDeletionRequested &&
        <Link to={'/deleteAccount'} className="a-block">
          <div className="bg-danger text-white text-center">
            Account Deletion Pending
          </div>
        </Link>}
      <Toaster />
    </div>
}
