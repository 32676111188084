import formatISO from "date-fns/formatISO"
import React, { useEffect } from "react"
import { DependentInsert, DependentRelationship, DependentRow, isDeleted } from "../../../types/supabase"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit"
import { useAsyncAction } from "../../hooks/useAsyncAction"
import { selectUsersAndDependentsForMembership } from "../../../lib/queries/selectUsersAndDependentsForMembership"
import { useClient } from "../../providers/supabase"
import { onSyncDependents } from "../../reduxToolkit/dependentsSlice"
import { Tooltip } from "../tooltip"
import { DropdownSelect } from "../dropdownSelect"

import './dependentsForm.scss'


interface DependentsFormProps {

}

const now = new Date()

// eslint-disable-next-line no-empty-pattern
export function DependentsForm({
}: DependentsFormProps) {
  /* Queries */
  const membershipId = useAppSelector((s) => s.membership.membershipId)
  const {dependents, profiles} = useAppSelector((s) => s.dependents?.lastSync || {})
  const dispatch = useAppDispatch()
  const client = useClient()

  const [selectDependentsState, selectDependents] = useAsyncAction(async () => {
    const result = await selectUsersAndDependentsForMembership(client, { membershipId })
    dispatch(onSyncDependents({
      ...result
    }))

  }, [client, membershipId])
  if (selectDependentsState.error) { throw selectDependentsState.error }

  // perform an initial sync
  useEffect(() => {
    selectDependents()
  }, [selectDependents])

  /* Local state and actions */
  const [removing, setRemoving] = React.useState<string[]>([])
  const [toAdd, setToAdd] = React.useState<Partial<DependentRow>>({
    relationship: 'child'
  })
  const [removeDependentState, removeDependent] = useAsyncAction(async (id: string) => {
    setRemoving((r) => [...r, id])
    const dependent = dependents.find((d) => d.id === id)
    try {
      const result = await client.from('dependents')
        .update({ deleted_at: formatISO(now) })
        .eq('id', id)

      if (result.error) { throw result.error }

      // Pop the just-deleted dependent back into the "adding" field
      if (dependent) {
        setToAdd(dependent)
      }
      // reload dependents from server
      await selectDependents()
    } finally {
      // splice the dependent out of the "removing" list
      setRemoving((r) =>
        r.filter((i) => i !== id))
    }
  }, [client, dependents])
  if (removeDependentState.error) { throw removeDependentState.error }

  const [addDependentState, addDependent] = useAsyncAction(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!(e.target as HTMLFormElement).checkValidity()) { return }
    if (!dependentRowComplete(toAdd)) {
      throw new Error('Dependent row is incomplete')
    }

    const result = await client.from('dependents')
      .upsert({
        ...toAdd,
        membership_id: membershipId,
        deleted_at: null,
      })
    if (result.error) { throw result.error }

    // reload dependents from server
    await selectDependents()
    setToAdd({})
  }, [toAdd])
  if (addDependentState.error) { throw addDependentState.error }

  return <div className="dependents-form row">
    <div className="col-12 col-lg-6">
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: '40%' }}>Name</th>
            <th style={{ width: '30%' }}>
              <span className="d-none d-md-inline">Date of Birth</span>
              <span className="d-md-none">DOB</span>
            </th>
            <th style={{ width: '20%' }}>
              <span className="d-flex">
              Relationship
              <Tooltip placement="top"
                tooltip="The dependent's relationship to you, the Authorized Representative who signs on their behalf.">
              </Tooltip>
              </span>
            </th>
            <th style={{ width: '10%'}}></th>
          </tr>
        </thead>
        <tbody>
          {!dependents &&
            <div className="loading-bar"></div>}

          {profiles?.map((user) => {
            return <tr className="table-light" key={user.id}>
              <td>{user.full_name}</td>
              <td>{user.date_of_birth}</td>
              <td>self</td>
              <td></td>
            </tr>
          })}

          {dependents?.map((dependent) => {
            const isRemoving = removing.includes(dependent.id)
            if (isDeleted(dependent)) {
              return null
            }

            return <tr key={dependent.id}>
              <td>{dependent.full_name}</td>
              <td>{dependent.date_of_birth}</td>
              <td>{dependent.relationship}</td>
              <td>
                <button className={`btn btn-link ${isRemoving && 'disabled'}`}
                    onClick={() => removeDependent(dependent.id)}>
                  {isRemoving ?
                    <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div> :
                    <i className="material-icons">close</i>
                  }
                </button>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>

    <div className="col-12 col-lg-6">
      <h4>Add another dependent</h4>
    <form onSubmit={addDependent}>
      <div className="input-group">
        <span className='input-group-text'>Name</span>

        <input type="text" className="form-control" id="fullName"
                required
                value={toAdd.full_name || ''}
                onChange={(e) => { setToAdd({...toAdd, full_name: e.target.value }) }} />
      </div>

      <div className="input-group">
        <span className='input-group-text'>Date of Birth</span>
        <input name="datepic" placeholder="DateRange" type="date" id="dob"
              className="form-control"
              required
              value={toAdd.date_of_birth || ''}
              onChange={(e) => { setToAdd({...toAdd, date_of_birth: e.target.value }) }}></input>
      </div>

      <div className='input-group'>
        <span className='input-group-text'>Relationship</span>
        <DropdownSelect
          className="form-select"
          options={[
            { 'id': 'child', label: 'Child' },
            { 'id': 'spouse', label: 'Spouse' }
          ] as Array<{ id: DependentRelationship, label: string }>}
          renderOption={({id, label}) => label}
          value={toAdd.relationship}
          required
          onChange={(e) => {
            if (e.value && 'id' in e.value) {
              setToAdd({...toAdd, relationship: e.value.id})
            } else {
              setToAdd({...toAdd, relationship: undefined})
            }
          }}>
        </DropdownSelect>
      </div>
      <button type='submit'
          className={`btn btn-primary mt-2`}>
        {
          addDependentState.loading ?
            <span className="spinner-border" role="status">
              <span className="visually-hidden">Submitting...</span>
            </span> :
            `+ Add`
        }
      </button>
    </form>
    </div>
  </div>
}

function dependentRowComplete(row: Partial<DependentInsert>): row is Pick<DependentInsert, 'full_name' | 'date_of_birth'> {
  return !!(
    row.full_name && row.date_of_birth
  )
}
