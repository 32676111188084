import type { Session } from '@supabase/supabase-js';
import { SignInWithEmail } from './signIn/signInWithEmail';
import { Routes, Route, Navigate, RouterProvider, Outlet, useLocation } from 'react-router';
import { createBrowserRouter, Link } from 'react-router-dom';
import Notifications from '../components/notifications';
import { Help } from '../routes/help';
import { ForgotPassword } from './signIn/forgotPassword';
import { SignInWithPassword } from './signIn/signInWithPassword';
import { VerifyOtp } from './signIn/verifyOtp';
import { SignInContextProvider } from './signIn/context';
import { ErrorFallback } from '../components/error-boundary';
import { PromoRedemption } from './signIn/promoRedemption';
import { SignInRedirectWithReturnTo } from './signIn/redirectWithReturnTo';

import './signIn.scss';
import { useEffect } from 'react';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorFallback />,
    children: [
      { index: true, element: <Navigate to="/signIn/email" /> },
      { path: 'signIn', element: <Navigate to="/signIn/email" /> },
      { path: 'signIn/email', element: <SignInWithEmail /> },
      { path: 'signIn/password', element: <SignInWithPassword /> },
      { path: 'verifyOtp', element: <VerifyOtp /> },
      { path: 'forgotPassword', element: <ForgotPassword /> },
      { path: 'help', element: <Help /> },
      { path: 'promo', element: <PromoRedemption /> },
      { path: '*', element: <SignInRedirectWithReturnTo /> }
    ]
  }
]);

function Layout() {
  useEffect(() => {
    // On load, If the queryParam has a returnTo, save it to local storage
    const urlParams = new URLSearchParams(window.location.search);
    const returnTo = urlParams.get('returnTo');
    if (returnTo) {
      localStorage.setItem('returnTo', returnTo);
    }
  }, [window.location.search])
  
  return (
    <div className="App container sign-in">
        <SignInNav />
        <Outlet />
        <Notifications />
    </div>
  );
}

export function SignIn({
  onLoginSuccess
}: {
  onLoginSuccess: (data: {session: Session}, navigateTo?: string) => any
}) {
  return (
    <SignInContextProvider onLoginSuccess={onLoginSuccess}>
      <RouterProvider router={router} />
    </SignInContextProvider>
  );
}

function SignInNav() {
  const location = useLocation()
  
  const hideNav = [
    '/promo'
  ].includes(location.pathname)
  
  return <nav className="navbar navbar-expand-lg top-nav">
  <div className="container-fluid">
    <div className='navbar-brand d-none d-md-block'>
      <h2>HealthShare</h2>
    </div>

    {!hideNav && <>
      <div className='nav-item'>
        <Link to="/" className="nav-link">
          <i className='material-icons'>login</i>
          Sign In
        </Link>
      </div>

      <div className='nav-item mt-auto'>
        {/* <Link to="help" className='nav-link'>
          <i className="material-icons">help</i>
          <span className='d-none d-md-inline'>Help</span>
        </Link> */}
      </div>
      </>}
  </div>
</nav>
}
