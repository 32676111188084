import { useState } from "react";
import { SearchTab } from "./help/searchTab";
import { AskTab } from "./help/askTab";
import { useFeature } from "../providers/featureFlags";

import './help.scss'
import { useNavigate } from "react-router-dom";


export function Help() {
  const navigate = useNavigate()
  
  const kbTabShown = useFeature('search_knowledgebase')

  return <div className="help">
    <div className="row mt-4">
      <div className="col">
        <h1>Help</h1>
        <ul className="nav nav-tabs" style={!kbTabShown ? { display: 'none' } : {}}>
          <li className="nav-item">
            <button id="search-tab"
                className={`nav-link ${kbTabShown && 'active'}`}
                data-bs-toggle="tab" data-bs-target="#search-pane"
                type="button" role="tab" aria-controls="search-pane" aria-selected={kbTabShown}
                >
              <span className="d-none d-md-inline">Search the Knowledgebase</span>
              <span className="d-md-none">Search</span>
            </button>
          </li>

          <li className="nav-item">
            <button id="ask-tab"
                className={`nav-link ${!kbTabShown && 'active'}`}
                data-bs-toggle="tab" data-bs-target="#ask-pane"
                type="button" role="tab" aria-controls="ask-pane" aria-selected={!kbTabShown}
                >
              <span className="d-none d-md-inline">Ask a Question</span>
              <span className="d-md-none">Ask</span>
            </button>
          </li>
        </ul>
        
        <div className="tab-content">
          <div className={`tab-pane fade ${kbTabShown && 'show active'}`} id="search-pane" role="tabpanel" aria-labelledby="search-tab">
            <SearchTab />
          </div>
          <div className={`tab-pane fade ${!kbTabShown && 'show active'}`} id="ask-pane" role="tabpanel" aria-labelledby="ask-tab">
            {/* <AskTab /> */}
            <p>
              Email us at&nbsp;
              <a href="mailto:support@healthsharetech.freshdesk.com">
                support@healthsharetech.freshdesk.com
              </a>
            </p>
            <p className="mt-4">
              Missed something the first time?
              &nbsp;
              <a className="btn btn-info"
                onClick={() => {
                  localStorage.setItem('startTour', 'true')
                  setTimeout(() => {
                    navigate('/')
                  }, 1)
                }}
                >Take the tour again</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
}
