import { useCallback, useMemo, useState } from "react"
import Fuse from 'fuse.js'
import React from "react"
import { present } from "../../lib/util/present"

export interface FuseSearchOptions<TData> extends Omit<Fuse.IFuseOptions<TData>, 'threshold'> {

  /** The initial Fuse search threshold to apply */
  initialThreshold?: number

  /** If present, will fall back to searching with this threshold if no results */
  fallbackThreshold?: number
}

export function useFuseSearch<TData>(
  data: TData[],
  options?: FuseSearchOptions<TData>,
  deps?: React.DependencyList
) {
  // Rebuild the search index only when the deps change
  const fuse = useMemo(
    () => {
      return new Fuse(data, {
        threshold: options?.fallbackThreshold || options?.initialThreshold || 0.4,
        ...options,
        includeScore: true
      })
    },
    deps || []
  )

  const [query, setQuery] = useState<string>()
  const [results, setResults] = useState<Fuse.FuseResult<TData>[]>()

  // Optimization: Only execute search at the end of a series of rapid fire changes
  const debounceRef = React.useRef<ReturnType<typeof setTimeout>>()
  const executeSearch = useCallback((newQuery: string, opts?: Fuse.FuseSearchOptions) => {
    setQuery(newQuery)
    // Cancel any pending searches
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }

    if (present(newQuery)) {
      // Don't search immediately, wait until the user stops typing
      debounceRef.current = setTimeout(() => {
        const results = fuse.search(newQuery, opts)
        setResults(results)
      }, 400)

    } else {
      debounceRef.current = undefined
      setResults(undefined)
    }
  }, [fuse, debounceRef])

  let initialThreshold = options?.initialThreshold || 0.4
  let fallbackThreshold = options?.fallbackThreshold || 0.4
  let returnedResults = results?.filter((r) => r.score && r.score <= initialThreshold)
  if (results && returnedResults && returnedResults.length == 0) {
    // No results at the initial threshold, try again with the fallback threshold
    returnedResults = results.filter((r) => r.score && r.score <= fallbackThreshold)
  }

  return [
    returnedResults?.map((r) => r.item) as TData[],
    executeSearch,
    {
      query,
      fuse,
      results
    }
  ] as const
}
