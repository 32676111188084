import { Semaphore } from 'async-toolbox';
import { ChonkyActions, defineFileAction, GenericFileActionHandler, MapFileActionsToData } from 'chonky';
import JSZip from 'jszip';
import path from 'path-browserify';
import { AppSupabaseClient, isFile } from '../../../types/supabase';
import { ExtensionToMimeType } from '../../../lib/extensionToMimeType';
import { downloadAll } from '../../../lib/supabase/storage/downloadAll';
import { listAll } from '../../../lib/supabase/storage/listAll';
import { downloadFile } from '../../../lib/util/downloadFile';
import { openFile } from '../../../lib/util/openFile';

export type CustomActionUnion =
  typeof DownloadAllAsZip |
  typeof DownloadSelection

export const DownloadAllAsZip = defineFileAction({
    id: 'download_all_as_zip',
    button: {
        name: 'Download as Zip Archive',
        toolbar: true,
        group: 'Actions',
        icon: 'archive'
    },
} as const);

export async function handleDownloadAllAsZip(
  data: MapFileActionsToData<typeof DownloadAllAsZip>,
  deps: {
    bucket: string,
    root: string,
    folder: string,
    client: AppSupabaseClient
  }
): Promise<void> {
  const {bucket, client} = deps
  const folder = path.join(deps.root, deps.folder).replace(/^\//, '')

  const files = (await listAll(
    bucket,
    folder,
    client.storage
  )).filter(isFile)

  const zip = new JSZip()
  await downloadAll(
    bucket,
    files.map((f) => f.name),
    client.storage,
    (file) => {
      const fileNameInZip = path.relative(folder, file.name)
      zip.file(fileNameInZip, file.data)
    }
  )

  const zipBlob = await zip.generateAsync({ type: 'blob' })
  downloadFile(path.basename(folder) + '.zip', zipBlob)
}

export const DownloadSelection = defineFileAction({
  id: 'download_selection',
  requiresSelection: true,
  fileFilter: (f) => !f?.isDir,
  button: {
    name: 'Download',
    contextMenu: true,
    icon: 'download'
  }
} as const)


export async function handleDownloadSelection(
  data: MapFileActionsToData<typeof DownloadSelection>,
  deps: {
    bucket: string,
    root: string,
    client: AppSupabaseClient
  }
): Promise<void> {
  const {bucket, client} = deps

  const objectNames =
    data.state.selectedFilesForAction
      .filter((f) => !f.isDir)
      .map((f) =>
        path.join(deps.root, f.id).replace(/^\//, ''))

  await downloadAll(
    bucket,
    objectNames,
    client.storage,
    (file) => {
      downloadFile(path.basename(file.name), file.data)
    }
  )
}

export async function handleOpenSelection(
  data: MapFileActionsToData<typeof ChonkyActions.OpenSelection>,
  deps: {
    bucket: string,
    root: string,
    setFolder: (folder: string) => void
    client: AppSupabaseClient
  }
) {
  const {bucket, client, setFolder} = deps

  if (data.state.selectedFilesForAction.length <= 0) {
    return
  }
  if (data.state.selectedFilesForAction.length == 1 && data.state.selectedFilesForAction[0].isDir) {
    setFolder(data.state.selectedFilesForAction[0].id)
    return
  }

  const objectNames =
    data.state.selectedFilesForAction
      .filter((f) => !f.isDir)
      .map((f) =>
        path.join(deps.root, f.id).replace(/^\//, ''))

  await downloadAll(
    bucket,
    objectNames,
    client.storage,
    (file) => {
      // Supabase stores all our files as text/plain, so we need to change the mime type
      const blob = new Blob([file.data], { type: ExtensionToMimeType[path.extname(file.name)] })
      openFile(blob)
    }
  )
}

export async function handleOpenFiles(
  data: MapFileActionsToData<typeof ChonkyActions.OpenFiles>,
  deps: {
    bucket: string,
    root: string,
    setFolder: (folder: string) => void
    client: AppSupabaseClient
  }
) {
  const {bucket, client, setFolder} = deps

  const file = data.payload.targetFile
  if (!file) { return }

  if (file.isDir) {
    return setFolder(file.id)
  }

  const objectName = path.join(
    deps.root,
    file.id
  ).replace(/^\//, '')

  const resp = await client.storage.from(bucket).download(objectName)
  if (resp.error) { throw resp.error }

  // Supabase stores all our files as text/plain, so we need to change the mime type
  const blob = new Blob([resp.data], { type: ExtensionToMimeType[path.extname(objectName)] })
  openFile(blob)
}
