import { AppSupabaseClient, SettingsRow } from "../../types/supabase"


export async function getOrCreateSettingsForUser(
  client: AppSupabaseClient,
  params: {
    userId: string
  }
): Promise<SettingsRow> {
  const {userId} = params

  const resp = await client.from('settings')
    .select('*')
    .eq('user_id', userId)
    .limit(1)

  if (resp.error) { throw resp.error }

  const row = resp.data[0]
  if (row) {
    return row
  }

  const now = new Date().toISOString()
  const resp2 = await client.from('settings')
    .insert({
      user_id: userId,
      created_at: now,
      updated_at: now
    })
    .single()

  if (resp2.error) { throw resp2.error }

  return resp2.data
}
