import { requireAuth, RequireAuthProvided } from '../wrappers/requireAuth'
import { useNavigate } from 'react-router'
import { DependentsForm } from '../components/forms/dependentsForm'

function EditDependents(_props: RequireAuthProvided) {
  const navigate = useNavigate()

  return <div className='row mt-4'>
    <div className='col-12'>
      <h1>Add Dependents</h1>
      <p>
        If you have any other members on your plan, you can add them here to associate
        them to expenses.
      </p>

      <div className="row">
        <div className="col-12">
          <DependentsForm />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <button className="btn btn-link" onClick={() => navigate(-1)}>
            I'm done adding dependents
            <i className="material-icons">next</i>
          </button>
        </div>
      </div>
    </div>
  </div>
}

export default requireAuth(EditDependents)
