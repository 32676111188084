import React, { useEffect, useRef } from 'react'
import * as PDFJS from 'pdfjs-dist';
import { waitUntil } from 'async-toolbox';
import { present } from '../../../lib/util/present';
import { assert } from '../../../lib/util/assert';

export function PdfPreview({ file }: { file: File }) {

  // Ensure canvas element is disposed of when the file changes

  const ref = useRef<HTMLCanvasElement>(null)
  const lastRenderedDocument = useRef<File>()
  const [hide, setHide] = React.useState<boolean>(true)

  useEffect(() => {
    const asyncEffect = async () => {
      if (lastRenderedDocument.current === file) { return }

      const oldCanvas = ref.current
      if (present(oldCanvas)) {
        setHide(true)
        await waitUntil(() => ref.current !== oldCanvas, { timeout: 200 })
      }
      setHide(false)
      await waitUntil(() => present(ref.current), { timeout: 200 })

      const canvasContext = ref.current?.getContext('2d')
      assert(canvasContext)
      const pdfBytes = await file.arrayBuffer()
      const pdfDoc = await PDFJS.getDocument(pdfBytes).promise

      console.log('render page!', file.name)
      const page = await pdfDoc.getPage(1)
      page.render({
        canvasContext,
        viewport: page.getViewport({ scale: 0.4 })
      })
      lastRenderedDocument.current = file
    }
    
    asyncEffect()
  }, [file])

  return <div className="preview preview__pdf">
      {!hide && <canvas id={[file.name, file.lastModified].join('/')} ref={ref} />}

    </div>
}
