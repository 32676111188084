import { SubmissionData2024CHM } from "../../../app/reduxToolkit/submissionsSlice";
import { fillPdf } from "../../pdf/fillPdf";
import { writeSignature } from "../../pdf/writeSignature";
import { calculateAgeAsString } from "../../util/calculateAge";
import { present } from "../../util/present";
import { splitDateParts } from "../../util/splitDateParts";

const fields = [
  'Member number',
  'Primary member name',
  'Phone number',
  'Email',
  'Patient name',
  'Patient DOB month',
  'Patient DOB day',
  'Patient DOB year',
  'Age',
  'Illness andor symptoms',
  'Symptoms date month',
  'Symptoms date day',
  'Symptoms date year',
  'signs and symptoms',
  'treatment and testing',
  '2 Letter of explanation',
  'signature',
  'signature_date_month',
  'signature_date_day',
  'signature_date_year',
  'credits_yes',
  'credits_no',
  'signs_and_symptoms_yes',
  'treatment_and_testing_yes'
] as const

type SharingRequestFormField = typeof fields[number]
type SharingRequestFormData = {
  [k in SharingRequestFormField]?: string | boolean | null | undefined
}

export async function fillSharingRequestForm2024(
  pdfBytes: ArrayBuffer,
  data: SubmissionData2024CHM['sharingRequestFormData']
): Promise<ArrayBuffer> {
  
  const {
    year: dobYear,
    month: dobMonth,
    day: dobDay
  } = splitDateParts(data.patientDOB)
  
  const {
    year: illnessYear,
    month: illnessMonth,
    day: illnessDay
  } = splitDateParts(data.illnessDate)
  
  const {
    year: signatureYear,
    month: signatureMonth,
    day: signatureDay
  } = splitDateParts(data.signatureDate)

  const formData: SharingRequestFormData = {
    "Member number": data.memberNumber,
    "Primary member name": data.memberName,
    "Phone number": data.phone,
    "Email": data.email,
    "Patient name": data.patientName,
    "Patient DOB month": dobMonth,
    "Patient DOB day": dobDay,
    "Patient DOB year": dobYear,
    "Age": present(data.patientDOB) ? calculateAgeAsString(new Date(data.patientDOB)) : null,
    "Illness andor symptoms": data.illness,
    "Symptoms date month": illnessMonth,
    "Symptoms date day": illnessDay,
    "Symptoms date year": illnessYear,
    
    'signs_and_symptoms_yes': data.preExistingSigns ? true : null,
    "signs and symptoms": data.preExistingSigns ? data.preExistingSignsText : null,
    'treatment_and_testing_yes': data.preExistingTreatment ? true : null,
    "treatment and testing": data.preExistingTreatment ? data.preExistingTreatmentText : null,
    
    "2 Letter of explanation": data.letterOfExplanation,

    "signature_date_month": signatureMonth,
    "signature_date_day": signatureDay,
    "signature_date_year": signatureYear,
    "credits_yes": data.credits ? true : null,
    "credits_no": data.credits === false ? true : null
  }
  
  pdfBytes = await fillPdf(pdfBytes, formData)
  
  if (present(data.signatureData)) {
    pdfBytes = await writeSignature(pdfBytes, {
      formField: "signature",
      signature: data.signatureData
    })
  }
  
  return pdfBytes
}
