import { AppSupabaseClient, DependentRow, ProfileRow } from "../../types/supabase"


type SelectUsersAndDependentsForMembershipResult =
  {
    dependents: DependentRow[],
    profiles: ProfileRow[]
  }

export async function selectUsersAndDependentsForMembership(
  client: AppSupabaseClient,
  params: {
    membershipId: string
  }
): Promise<SelectUsersAndDependentsForMembershipResult> {
  const dependentsResp = await client.from('dependents')
    .select(`*`)
    .eq('membership_id', params.membershipId)

  if (dependentsResp.error) { throw dependentsResp.error }

  const uidsResp = await client.from('membership_roles')
    .select('user_id')
    .eq('membership_id', params.membershipId)

  if (uidsResp.error) { throw uidsResp.error }
  
  const profiles = []
  if (uidsResp.data.length !== 0) {
    const profilesResp = await client.from('profiles')
      .select('*')
      .eq('id', uidsResp.data.map((x) => x.user_id))
    if (profilesResp.error) { throw profilesResp.error }
    profiles.push(...profilesResp.data)
  }

  return {
    dependents: dependentsResp.data as DependentRow[] || [],
    profiles
  }
}
