
export function splitDateParts(date: string | null | undefined) {
  if (!date) {
    return { year: null, month: null, day: null }
  }
  
  const parts = date.split('-')
  // trim leading zeros
  parts[1] = parts[1]?.replace(/^0+/, '')
  parts[2] = parts[2]?.replace(/^0+/, '')
  // strip century
  parts[0] = parts[0]?.slice(-2)

  return {
    year: parts[0],
    month: parts[1],
    day: parts[2]
  }
}
