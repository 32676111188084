import { useAppSelector } from "../../hooks/reduxToolkit"
import { EditExpense } from "../editExpense"

type EditExpenseActionProps = {
  expenseId: string
  onCompleted: () => void
}

export function EditExpenseAction({ expenseId, onCompleted, ...props }: EditExpenseActionProps) {
  const expense = useAppSelector((s) => s.expenses.expenses.find((e) => e.id === expenseId))
  if (!expense) { return null }

  return <EditExpense
    {...props}
    expense={expense}
    validateOnMount

    onUpdated={(...args) => {
      onCompleted()
    }} />
}
