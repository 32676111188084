import React, { useState } from "react"
import { useAsyncAction } from "../../hooks/useAsyncAction"
import { useClient } from "../../providers/supabase"

interface ChangePasswordFormProps {
  onChangePasswordSuccess?: () => void
}

interface ChangePasswordFormState {
  newPassword?: string
  newPasswordConfirmation?: string
}

export function ChangePasswordForm({
  onChangePasswordSuccess
}: ChangePasswordFormProps) {
  const client = useClient()
  const [state, setState] = useState<ChangePasswordFormState>({})

  const [{loading: submitting, error}, submit] = useAsyncAction(async () => {
    if (!state.newPassword) { throw new Error(`New Password is required`) }
    if (state.newPassword !== state.newPasswordConfirmation) { throw new Error(`Confirmation does not match`) }

    const resp = await client.auth.updateUser({
      password: state.newPassword
    })
    if (resp.error) { throw resp.error }

    if (onChangePasswordSuccess) { onChangePasswordSuccess() }
    return resp.data
  }, [onChangePasswordSuccess, state.newPassword, state.newPasswordConfirmation])

  const canSubmit = state.newPassword && state.newPasswordConfirmation

  const confirmationIsInvalid = state.newPassword && state.newPasswordConfirmation &&
    (state.newPasswordConfirmation.length >= state.newPassword.length) &&
    (state.newPassword !== state.newPasswordConfirmation)

  return <form onSubmit={(e) => { e.preventDefault(); submit() }}>
    <div className="mb-3">
      <span className='form-label'>New Password</span>
      <input name="password" type="password"
        className="form-control"
        required
        disabled={submitting}
        value={state.newPassword || ''}
        onChange={(e) => { setState({...state, newPassword: e.target.value }) }}></input>
    </div>

    <div className='mb-3'>
      <span className='form-label'>Confirm New Password</span>
      <input type='password' className={`form-control ${confirmationIsInvalid && 'is-invalid'}`}
        required
        value={state.newPasswordConfirmation || ''}
        onChange={(e) =>  setState({...state, newPasswordConfirmation: e.target.value})}></input>

      <div id="validationServer04Feedback" className="invalid-feedback">
        This must match your new password
      </div>
    </div>

    <div className="mb-3">
      {submitting ?
        <button className='btn btn-primary disabled'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </button> :
        <button type='submit' className='btn btn-primary' disabled={!canSubmit}>Submit</button>}
    </div>


    <div className="mb-3">
      {error &&
        <p className="text-danger">
          {error.message}
        </p>}
    </div>
  </form>
}
