import { present } from "async-toolbox"
import { useState } from "react"
import { useClient, useSession } from "../providers/supabase"
import { useAsyncEffect } from "./useAsyncEffect"
import { OrganizationRow } from "../../types/supabase"
import { selectOrganizationForUser } from "../../lib/queries/selectOrganizationForUser"

export function useQueryOrganizationForUser() {
  const session = useSession()
  const client = useClient()
  
  const email = session?.user?.email
  const userId = session?.user?.id

  const [organization, setOrganization] = useState<OrganizationRow | null>(null)
  const {loading, error, refreshing} = useAsyncEffect(async () => {
    if (!present(email) && !present(userId)) {
      throw new Error(`User is not signed in`)
    }
    
    const organization = await selectOrganizationForUser(client, { email, id: userId })

    setOrganization(organization)
  }, [email, userId, client])

  return [organization, { loading: loading || refreshing, error }] as const
}
