import { useState, useCallback } from "react"
import { BlobRow } from "../../types/supabase"
import { createBlob } from "../../lib/supabase/createBlob"
import { useClient } from "../providers/supabase"
import { useAppSelector } from "./reduxToolkit"


export interface OnUploadedCallbackParams {
  blob: BlobRow, file: File
}

export type UploadingState<TResult> = {
  file: File,
  blob: BlobRow | null,
  error: Error | null,
  result: TResult | null,
  complete?: boolean,
}

export type OnUploadCallback<T> = (params: OnUploadedCallbackParams) => Promise<T> | T

export function useBlobUpload<TResult>(onUploaded?: OnUploadCallback<TResult>) {
  const membership_id = useAppSelector((s) => s.membership.membershipId)
  const client = useClient()

  const [uploadingState, setUploadingState] = useState<UploadingState<TResult>[]>([])
  const setUploadingStateForFile = useCallback((file: File, newFileState: Partial<UploadingState<TResult>>) => {
    setUploadingState((prev) => {
      const index = prev.findIndex((s) => s.file === file)
      if (index === -1) {
        return prev
      }

      const newState = [...prev]
      newState[index] = {
        ...newState[index],
        ...newFileState
      }
      return newState
    })
  }, [])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadingState((prev) =>
      prev.concat(acceptedFiles.map((file) => ({ file, blob: null, error: null, result: null }))))

    acceptedFiles.forEach((file) => {
      const doUpload = async () => {
        const blob = await createBlob(
          file,
          {
            membership_id
          },
          {
            client
          })

        setUploadingStateForFile(file, { blob })
        if (onUploaded) {
          const result = await onUploaded({blob, file})
          setUploadingStateForFile(file, { result, complete: true })
        } else {
          setUploadingStateForFile(file, { result: true as TResult, complete: true })
        }
      }

      doUpload().catch((error) => {
        console.log('error uploading file:', error)
        setUploadingStateForFile(file, { error })
      })
    })
  }, [membership_id, client, setUploadingStateForFile, onUploaded])

  return [
    uploadingState,
    onDrop
  ] as const
}
