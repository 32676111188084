import { AppSupabaseClient, Tables } from "../../types/supabase";
import { NotNull } from "../../types/util";
import type { RowSyncResponse, SyncableTable } from "./action";


export type Update<Table extends SyncableTable> = {
  type: 'update',
  table: Table,
  record: NotNull<Tables[Table]['Update'], 'id' | 'updated_at'>
}

export async function syncUpdate<Table extends SyncableTable>(
  client: AppSupabaseClient,
  action: Update<Table>
): Promise<RowSyncResponse<Table>> {
  const resp = await client.from(action.table)
    .update(action.record as any)
    .eq('id', action.record.id)
    .lte('updated_at', action.record.updated_at)
    .select('*');

  if (resp.error) {
    return {
      result: 'error',
      error: resp.error
    };
  }

  // Our update was applied - return the data from the server
  if (resp.data && resp.data[0]) {
    return {
      result: 'applied',
      data: resp.data[0] as any
    };
  }
  // The row update was rejected due to updated_at on the server being newer.
  // query the current state from the server
  const query = await client.from(action.table)
    .select('*')
    .eq('id', action.record.id);
  if (query.error) {
    return {
      result: 'error',
      error: query.error
    };
  }
  return {
    result: 'rejected',
    data: (query.data && query.data[0]) || null as any
  };
}
