import { isSubmissionData2023, isSubmissionData2024 } from "../../../reduxToolkit/submissionsSlice";
import { RequireSubmissionProvided } from "../../../wrappers/requireSubmission";
import { SubmitCHMAddOn2023 } from "./submitCHMAddOn/submitCHMAddOn2023";
import { SubmitCHMAddOn2024 } from "./submitCHMAddOn/submitChmAddOn2024";

interface SubmitCHMAddOnProps extends RequireSubmissionProvided {
}


export function SubmitCHMAddOn({ submission }: SubmitCHMAddOnProps) {
  const data = submission.data
  if (data) {
    // resume pending submission
    if (isSubmissionData2023(data)) {
      return <SubmitCHMAddOn2023 submission={submission} />
    } else if (isSubmissionData2024(data)) {
      return <SubmitCHMAddOn2024 submission={submission} />
    }
    
    throw new Error(`Unknown submission data: ${data}`)
  }

  if (submission.incident.start_date <= '2023-12-31') {
    return <SubmitCHMAddOn2023 submission={submission} />
  } else {
    return <SubmitCHMAddOn2024 submission={submission} />
  }
}
