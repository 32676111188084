import { present } from "../../../../../../lib/util/present";
import { useCallback, useMemo, useState } from "react"
import { SignatureCanvas } from "../../../../../components/signatureCanvas"
import { SubmissionData2024CHM } from "../../../../../reduxToolkit/submissionsSlice"
import type { FormStepProps } from "../submitToChm2024"
import { Tooltip } from "../../../../../components/tooltip";
import { cloneDeep } from "lodash";
import { ValidationError, validateSharingRequestFormData2024 } from "../validation";
import { TextInputGroup } from "./components/textInputGroup";
import { FloatingActionButtons } from "./components/floatingActionButtons";
import { calculateAgeAsString } from "../../../../../../lib/util/calculateAge";

export function SharingRequestForm2024({ data: upstreamData, updateData, advance, home }: FormStepProps) {
  const originalData = useMemo(() => {
    return cloneDeep(upstreamData)
  }, [])
  const [data, setData] = useState(upstreamData.sharingRequestFormData)
  
  const errors = useMemo<ValidationError[]>(() => {
    return validateSharingRequestFormData2024(data)
  }, [data])

  const changeData = useCallback(<TKey extends keyof SubmissionData2024CHM['sharingRequestFormData']>(key: TKey, value: SubmissionData2024CHM['sharingRequestFormData'][TKey]) => {
    setData((data) => {
      if (value === data[key]) { return data }

      const newData = {
        ...data,
        [key]: value
      }
      updateData({
        ...originalData,
        sharingRequestFormData: newData
      })
      return newData
    })
  }, [updateData, originalData])

  const dob = present(data.patientDOB) ? new Date(data.patientDOB) : null
  const ageStr = calculateAgeAsString(dob)

  const patientAndIllnessErrorCount = errors.filter((e) => ['memberName', 'phone', 'email', 'patientName', 'patientDOB', 'illness', 'illnessDate'].includes(e.field)).length
  const letterOfExplanationErrorCount= errors.filter((e) => e.field === 'letterOfExplanation').length
  const consentErrorCount = errors.filter((e) => ['signatureData', 'signatureDate'].includes(e.field)).length

  return <div className="submitToChm2024-SharingRequestForm">
    <FloatingActionButtons home={home} advance={advance} errors={errors} />

    <form className={`${errors.length > 0 && 'needs-validation has-errors'}`}
        onSubmit={((evt) => { evt?.preventDefault(); advance() })}>
      <div className="accordion" id='sharing-request-form'>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
                data-bs-toggle='collapse' data-bs-target='#patient-and-illness'
                aria-expanded="true" aria-controls="patient-and-illness">
              1. Patient and illness information {patientAndIllnessErrorCount > 0 && <span className="badge bg-danger">{patientAndIllnessErrorCount}</span>}
            </button>
          </h2>

          <div className="accordion-collapse collapse show" id='patient-and-illness' data-bs-parent='sharing-request-form'>
            <div className="accordion-body">
              <div className="input-group">
                <label className="input-group-text">
                  Member Number:
                </label>
                <div className={`form-control disabled`}>
                <Tooltip tooltip="Your CHM member number is set in your profile.  If this is wrong please contact us to correct it.">
                  {data.memberNumber}
                </Tooltip>
                </div>
              </div>
              
              <TextInputGroup
                field='memberName'
                label='Member Name:'
                value={data.memberName}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'memberName')} />
              
              <TextInputGroup
                field='phone'
                label='Phone:'
                value={data.phone}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'phone')} />
                
              <TextInputGroup
                field='email'
                label='Email:'
                value={data.email}
                inputType="email"
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'email')} />

              <div className="input-group input-group-checkbox input-group__long-text">
                <label className="input-group-text">I’d like any available credits applied to my Personal Responsibility:</label>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio'
                    name='credits' id='credits_yes' value='yes'
                    checked={data.credits === true}
                    onChange={
                      useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                        const value = evt.target.value === 'yes' ? true : null
                        changeData('credits', value)
                      }, [changeData])
                    }
                    ></input>
                  <label className="form-check-label" htmlFor="credits_yes">Yes</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio'
                    name='credits' id='credits_no' value='no'
                    checked={data.credits === false}
                    onChange={
                      useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                        const value = evt.target.value === 'no' ? false : null
                        changeData('credits', value)
                      }, [changeData])
                    }
                    ></input>
                  <label className="form-check-label" htmlFor="credits_no">No</label>
                </div>
              
              </div>
              
              <TextInputGroup
                field='patientName'
                label='Patient Name:'
                value={data.patientName}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'patientName')} />

              <TextInputGroup
                  field='patientDOB'
                  label='Patient DOB:'
                  value={data.patientDOB}
                  inputType="date"
                  changeData={changeData}
                  validationError={errors.find((e) => e.field === 'patientDOB')}>
                <label className="input-group-text d-none d-md-inline-block">Age: {ageStr}</label>
              </TextInputGroup>
              
              <TextInputGroup
                field='illness'
                label='Illness or Symptoms:'
                value={data.illness}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'illness')} />
              
              
              <TextInputGroup
                field='illnessDate'
                label='Date Symptoms started:'
                value={data.illnessDate}
                inputType="date"
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'illnessDate')} />
              
              <div className="pre-existing-conditions">
                <h4>Pre-existing conditions</h4>
                <p>If applicable, I've had the following:</p>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='checkbox'
                    name='pre_existing_signs' id='pre_existing_signs' value='yes'
                    checked={data.preExistingSigns === true}
                    onChange={
                      useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                        const value = evt.target.checked
                        changeData('preExistingSigns', value)
                      }, [changeData])
                    }
                    ></input>
                  <label className="form-check-label" htmlFor="pre_existing_signs">Signs/Symptoms and date:</label>
                </div>
                <textarea className={`form-control ${!data.preExistingSigns && 'd-none'}`} rows={2} maxLength={120} 
                  value={data.preExistingSignsText || undefined}
                  onChange={
                    useCallback((evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const value = present(evt.target.value) ? evt.target.value : null
                      changeData('preExistingSignsText', value)
                    }, [changeData])
                  }/>

                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='checkbox'
                    name='pre_existing_treatment' id='pre_existing_treatment' value='yes'
                    checked={data.preExistingTreatment === true}
                    onChange={
                      useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                        const value = evt.target.checked
                        changeData('preExistingTreatment', value)
                      }, [changeData])
                    }
                    ></input>
                  <label className="form-check-label" htmlFor="pre_existing_treatment">Treatment/Testing and date:</label>
                </div>
                <textarea className={`form-control ${!data.preExistingTreatment && 'd-none'}`} rows={2} maxLength={120} 
                  value={data.preExistingTreatmentText || undefined}
                  onChange={
                    useCallback((evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const value = present(evt.target.value) ? evt.target.value : null
                      changeData('preExistingTreatmentText', value)
                    }, [changeData])
                  }/>
                
              </div>
            </div>
          </div>
        </div>
        
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
                data-bs-toggle='collapse' data-bs-target='#letter-of-explanation'
                aria-expanded="true" aria-controls="letter-of-explanation">
              2. Letter of Explanation {letterOfExplanationErrorCount > 0 && <span className="badge bg-danger">{letterOfExplanationErrorCount}</span>}
            </button>
          </h2>
          
          <div className="accordion-collapse collapse show" id='letter-of-explanation' data-bs-parent='letter-of-explanation'>
            <div className="accordion-body">
              
              <textarea className={`form-control ${errors.find((e) => e.field === 'letterOfExplanation') && 'is-invalid'}`}
                id="letterOfExplanation"
                placeholder="Start writing here."
                rows={20} 
                value={data.letterOfExplanation || undefined}
                onChange={
                  useCallback((evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const value = present(evt.target.value) ? evt.target.value : null
                    changeData('letterOfExplanation', value)
                  }, [changeData])
                }
                onBlur={
                  useCallback((evt: React.FocusEvent<HTMLTextAreaElement>) => {
                    const value = present(evt.target.value) ? evt.target.value : null
                    changeData('letterOfExplanation', value)
                  }, [changeData])
                }
                />
                
                
                <div className="invalid-feedback">{errors.find((e) => e.field === 'letterOfExplanation')?.message}</div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
                data-bs-toggle='collapse' data-bs-target='#consent'
                aria-expanded="true" aria-controls="consent">
              3. Consent {consentErrorCount > 0 && <span className="badge bg-danger">{consentErrorCount}</span>}
            </button>
          </h2>
          
          <div className="accordion-collapse collapse show" id='consent' data-bs-parent='consent'>
            <div className="accordion-body">
              <p>
              I understand that CHM members participate out of a desire to share one another’s burdens, and it would be an abuse of their
  trust if I use the money I receive for a sharing request for some purpose other than payment of that medical bill. If I have prepaid
  or made payments, I will consider funds received from CHM as reimbursement. I understand that failure to provide accurate
  information or failure to use the money for the submitted bills will be a violation of Christian Healthcare Ministries Guidelines and
  a fraud upon the ministry (<a href="https://CHMinistries.org/chm-guidelines" target="_blank" rel="noreferrer">CHMinistries.org/chm-guidelines</a>) [Section II(E)(10)(e)]. By signing below, I attest that the participating
  adult members included in my membership are Christians who attend worship regularly as health permits, actively follow the
  teachings of the New Testament in its entirety, and live a Christian lifestyle consistent with CHM’s Statements of Beliefs (expressed
  in CHM’s Guidelines). I also attest that all information provided herein is true to the best of my knowledge.
              </p>
              <p>Signed:</p>
              <SignatureCanvas
                id="signatureData"
                data={useMemo(() => data?.signatureData, [])}
                onChange={useCallback((sigData: string | undefined) => {
                  changeData('signatureData', sigData)
                }, [changeData])}/>

              <TextInputGroup
                field='signatureDate'
                label='Date:'
                value={data.signatureDate}
                inputType="date"
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'signatureDate')} />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
}
