import { useSession } from "../providers/supabase";
import { useAppSelector } from "./reduxToolkit";


export function useUser() {
  const session = useSession()
  const localUserId = useAppSelector((s) => s.membership.userId)

  // If online, use the session.  Otherwise, use the local user ID.
  return session?.user || { id: localUserId }
}