import { useState, useCallback } from "react"
import {v4 as uuid4} from 'uuid'
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit"
import { OnUploadedCallbackParams } from "../../hooks/useBlobUpload"
import { BlobUploadDropzone } from "../blobUploadDropzone"
import { createAttachment } from "../../reduxToolkit/attachmentsSlice"

import './attachReceiptAction.scss'


export function AttachReceiptAction({ expenseId, onCompleted, ...props }: { expenseId: string, onCompleted: () => void }) {
  const dispatch = useAppDispatch()
  const expense = useAppSelector((s) => s.expenses.expenses.find((e) => e.id === expenseId))

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onUpload = useCallback(async (params: OnUploadedCallbackParams) => {
    if (!expense) { return }

    const {blob} = params
    dispatch(createAttachment({
      id: uuid4(),
      record_id: expense.id,
      table_name: 'expenses',
      blob_key: blob.key,
      membership_id: expense.membership_id,
      updated_at: expense.updated_at,
      created_at: expense.created_at,
    }))

    onCompleted()
  }, [expense, dispatch, onCompleted])

  if (!expense) { return null }

  return <div className="attach-receipt-action">
      <BlobUploadDropzone onUpload={onUpload}>
          <i className='material-icons'>file_download</i>
          Click to Upload an Itemized Bill
        </BlobUploadDropzone>
    </div>
}
