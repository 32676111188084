import type { DependentRow } from "../../../types/supabase";
import type { Patient } from "../dependentsSlice";
import { IncidentModel } from "../incidentsSlice";
import { RootState } from "../store";

export function selectPatients(s: RootState): Patient[] {
  if (!s.dependents?.patients) {
    // TODO: get user from profile
    return []
  }

  return s.dependents.patients
}

export function selectDependentForIncident(incident: Pick<IncidentModel, 'patient_name' | 'patient_dob'> | null | undefined) {
  return (s: RootState): DependentRow | null => {
    if (!incident) { return null }
    
    return s.dependents?.lastSync?.dependents?.find((d) => 
      d.full_name === incident.patient_name && d.date_of_birth === incident.patient_dob) || null
  }
}
