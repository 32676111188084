import { useCallback } from "react"
import { present } from "../../../../../../../lib/util/present";

export function FormSelectInputGroup<TKey extends string, TValue extends string>({
  field,
  label,
  value,
  changeData,
  validationError,
  className,
  children
}: React.PropsWithChildren<{
  field: TKey,
  label?: string | null | undefined,
  value: TValue | null | undefined | '',
  changeData: (field: TKey, value: TValue | null | undefined) => void,
  validationError?: { message: string },
  className?: string,
}>) {
  return <div className={`input-group ${className}`} id={field}>
  {label && <label className="input-group-text">{label}</label>}
  <select className={`form-select ${validationError && 'is-invalid'}`}
    value={value || ''}
    onChange={
      useCallback((evt: React.ChangeEvent<HTMLSelectElement>) => {
        let value: TValue | null = evt.target.value as TValue;
        if (!present(value)) { value = null }
        changeData(field, value);
      }, [field, changeData])
    }>
    {children}
  </select>
  
  <div className="invalid-feedback">{validationError?.message}</div>
</div>
}
