import { present } from "async-toolbox"
import { v4 as uuid4 } from "uuid";
import { useState } from "react"
import { NotNull } from "../../../types/util"
import { useAppSelector, useAppDispatch } from "../../hooks/reduxToolkit"
import { assert } from "../../../lib/util/assert"
import { IncidentInsertPayload, addIncident } from "../../reduxToolkit/incidentsSlice"
import { selectPatients } from "../../reduxToolkit/selectors/dependents"
import { PatientSelect, addIdToPatient } from "../formComponents/patientSelect"


interface CreateIncidentProps {
  submitText?: string

  onSubmit?: (incident: IncidentInsertPayload) => void
}

export function CreateIncident({
  submitText,
  onSubmit
}: CreateIncidentProps) {
  const patients = useAppSelector(selectPatients).map(addIdToPatient)
  const { userId, membershipId } = useAppSelector((s) => s.membership)
  assert(userId, 'Must be logged in to create an incident')
  assert(membershipId, 'Must be logged in to create an incident')

  const dispatch = useAppDispatch()

  const [form, setForm] = useState<Partial<IncidentInsertPayload>>({
    start_date: new Date().toISOString().split('T')[0],
    patient_name: patients[0]?.full_name,
    patient_dob: patients[0]?.date_of_birth,
  })

  const doInsert = async (e: React.FormEvent) => {
    e.preventDefault()

    assertIsCompleteIncidentForm(form)

    const now = new Date().toISOString()
    const incident: IncidentInsertPayload = {
      id: uuid4(),
      created_at: now,
      updated_at: now,
      created_by_user_id: userId,
      membership_id: membershipId,
      start_date: form.start_date || new Date().toISOString().split('T')[0],
      ...form,
      description: form.description,
    }

    const addIncidentAction = dispatch(addIncident(incident))
    onSubmit?.(addIncidentAction.payload)
  }

  const selectedPatient = present(form.patient_name) && present(form.patient_dob) && addIdToPatient({
    full_name: form.patient_name,
    date_of_birth: form.patient_dob
  })

  const canSubmit = isCompleteIncidentForm(form)

  return <form className="create-incident" onSubmit={doInsert}>
      <div className="form-group">
        <label className='form-label'>Description</label>
        <textarea className='form-control'
          rows={3}
          required
          value={form.description || ''}
          onChange={(e) =>  setForm({...form, description: e.target.value})} />
      </div>

      <div className='input-group mt-1'>
        <span className='input-group-text'>Patient</span>
        {patients && <PatientSelect className="form-select"
          options={patients}
          value={selectedPatient}
          onChange={({value}) => {
            if (value && 'id' in value) {
              setForm({
                ...form,
                patient_name: value.full_name,
                patient_dob: value.date_of_birth
              })
            }
          }} />}
      </div>

      <div className="col-12 d-flex mt-2">
        <button type='submit' disabled={!canSubmit}
            className={`btn btn-primary ${!canSubmit && 'disabled'}`}>
            {submitText || 'Create Incident'}
        </button>
      </div>
    </form>
}

const requiredFields = [
  'patient_name',
  'patient_dob',
  'description'
] as const
type RequiredFields = typeof requiredFields[number]
type IncidentForm = Partial<IncidentInsertPayload>
type CompleteIncidentForm = NotNull<IncidentForm, RequiredFields>

function isCompleteIncidentForm(form: IncidentForm): form is CompleteIncidentForm {
  return requiredFields.every((f) => present(form[f]))
}

function assertIsCompleteIncidentForm(form: IncidentForm): asserts form is CompleteIncidentForm {
  const missingFields = requiredFields.filter((f) => !present(form[f]))
  if (missingFields.length > 0) {
    throw new Error(`field ${missingFields.join(', ')} is required`)
  }
}
