import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router';
import { QuickAddExpense } from '../components/quickAddExpense'
import { useAppSelector } from '../hooks/reduxToolkit';
import { selectToDos } from '../reduxToolkit/selectors/todos';
import { byDueDateAscendingNullsFirst } from '../../lib/util/sort';
import { useTodoCompletion } from '../hooks/todos/useTodoCompletion';
import { formatDateInTimeZone } from '../../lib/formatDateInTimeZone';
import { Link } from 'react-router-dom';
import { endOfYear, startOfDay } from 'date-fns';
import { Tooltip } from '../components/tooltip';
import { useTour } from './homepage/tour';
import { useReturnTo } from '../hooks/useReturnTo';

import './homepage.scss'

export function Homepage() {
  const navigate = useNavigate()
  
  useTour()
  useReturnTo()

  return <div className='homepage'>
    <div className="row">
      <div className="col-12 homepage__header">
        <h1>HealthShare Reimbursement Tracker</h1>
        <p>
          Automatically convert your itemized bills to documents ready to submit.
        </p>
      </div>
      <div className="col-12 col-xl-8">
        <QuickAddExpense
          allowNewIncident={true}
          onInserted={(expense) => {
          if (expense.incident_id) {
            navigate(`/incidents/${expense.incident_id}`, { state: { expense_id: expense.id } })
          } else {
            navigate(`/expenses`, { state: { expense_id: expense.id } })
          }
        }} />
      </div>

      <div className='col-12'>
        <HomepageNextSteps />
      </div>
    </div>
  </div>
}

function HomepageNextSteps() {
  const [onClick, _, modal] = useTodoCompletion()
  const today = useMemo(() => startOfDay(new Date(Date.now())).toISOString(), [])
  const eoy = useMemo(() => endOfYear(new Date(Date.now())).toISOString(), [])

  let todos = useAppSelector(selectToDos)
  const totalTodos = todos.length
  todos = todos.sort(byDueDateAscendingNullsFirst)
    .slice(0, 3)

  if (!todos.length) {
    return null
  }

  return <div className='homepage__next-steps'>
    <h3>Next Steps</h3>
    <div className='homepage__next-steps__todo-list'>
      <ol>
        {todos.map((t) => {
          const isPast = t.dueDate && t.dueDate < today
          const showYear = t.dueDate && t.dueDate > eoy
          
          return <li className='homepage__next-steps__todo-list-item'
              onClick={() => onClick(t)}>
            <button className='btn btn-link'>
              {t.title}
            </button>

            {t.dueDate && <>
                <br className='d-md-none'/>&nbsp;{isPast ?
                  <span className='text-danger'><Tooltip tooltip={`This was due on ${formatDateInTimeZone(t.dueDate, { format: 'MMMM d, yyyy' })}`}>past due!</Tooltip></span> :
                  <span>&nbsp;before {formatDateInTimeZone(t.dueDate, { format: showYear ? 'MMMM d, yyyy' : 'MMMM d' })}</span>}
              </>}
          </li>
        })}

      </ol>
      {totalTodos > todos.length &&
        <Link to="/todos" className='btn btn-link float-end'>more...</Link>}
    </div>

    {modal}
  </div>
}
