import { useCallback, useState } from 'react'
import { requireAuth } from '../wrappers/requireAuth'
import { useUser } from "../hooks/useUser"
import { useAsyncAction } from '../hooks/useAsyncAction'
import { useClient } from '../providers/supabase'
import { useAppDispatch, useAppSelector } from '../hooks/reduxToolkit'
import { setSettings } from '../reduxToolkit/membershipSlice'
import { merge } from "lodash";
import { useLoadSettingsFromServer } from '../hooks/useLoadSettingsFromServer'
import { SettingsRow, SettingsUpdate } from '../../types/supabase'
import { TextractOptInCheckbox } from '../components/forms/textractOptInCheckbox'

function EditSettings() {
  const user = useUser()
  if (!user) { throw new Error(`Not signed in!`) }

  const client = useClient()
  const settings = useAppSelector((s) => s.membership?.settings)
  const dispatch = useAppDispatch()

  useLoadSettingsFromServer()

  const [{error, loading}, updateSettings] = useAsyncAction(async (update: SettingsUpdate) => {
    const now = new Date().toISOString()
    const newRow = {
      ...update,
      updated_at: now
    }
    const result = await client.from('settings')
      .update(newRow)
      .eq('user_id', user.id)

    if (result.error) { throw result.error }

    dispatch(setSettings({
      settings: merge({}, settings, newRow)
    }))
  }, [client])

  return <div className='row mt-4'>
    <div className='col-12'>
      <h1>Edit your Settings</h1>
      
      <SettingsForm settings={settings} loading={loading} onUpdate={updateSettings} />

      {error && <div className='alert alert-danger' role='alert'>
        {error.message}
      </div>}
    </div>
  </div>
}

export default requireAuth(EditSettings)

interface SettingsFormProps {
  settings: SettingsRow | null,
  loading?: boolean,
  onUpdate: (settings: SettingsUpdate) => void
}

function SettingsForm({settings, loading, onUpdate}: SettingsFormProps) {
  const [unsavedSettings, setUnsavedSettings] = useState<SettingsUpdate>(settings || {})
  
  const update = useCallback((update: SettingsUpdate) => {
    setUnsavedSettings((s) => merge({}, s, update))
    onUpdate(update)
  }, [onUpdate])

  return <form className="settings-form">
    <div className='form-group'>
      <TextractOptInCheckbox
        value={unsavedSettings.textract_opt_in || null}
        disabled={loading && (unsavedSettings.textract_opt_in !== settings?.textract_opt_in)}
        onChange={(value) => {
          update({textract_opt_in: value})
        }} />
    </div>
  </form>
}
