import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { DependentRow, isNotDeleted, MembershipRoleRow, MembershipRow, ProfileRow } from '../../types/supabase'
import { PURGE } from 'redux-persist';
import { DeepNullable } from '../../types/util'
import { redactName } from '../../lib/util/redact'
import { isFilledProfile } from './membershipSlice';

export type Patient = { full_name: string, date_of_birth: string }

export type DependentsSliceState = {
  uninitialized?: false

  // These are intentionally marked as non-nullable because the initializer
  // ensures that these are set, otherwise it takes you to onboarding.  So
  // the rest of the app doesn't need to worry if these are null or not
  /**
   * The set of people whom you can select as the recipient of an expense
   */
  patients: Patient[]

  lastSync: {
    dependents: DependentRow[]
    profiles: ProfileRow[]
  }
}

export interface SyncedMembershipSliceState {
  membership: MembershipRow,
  role: MembershipRoleRow
}

/**
 * The result of a Supabase query for the user's current membership info
 */
export type OnSyncDependentsPayload = {
  dependents: DependentRow[],
  profiles: ProfileRow[]
}

const initialState = {
  uninitialized: true
} as unknown as DependentsSliceState


export const dependentsSlice = createSlice({
  name: 'dependents',
  initialState,
  reducers: {
    /**
     * Dispatched by the initializer when the state is loaded from the Supabase server
     */
    onSyncDependents(state, action: PayloadAction<OnSyncDependentsPayload>) {
      const {dependents, profiles} = action.payload

      // reset the state
      return {
        patients: [...profiles.filter(isFilledProfile), ...dependents.filter(isNotDeleted)],
        lastSync: {
          dependents,
          profiles
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    })

    return builder
  }
})

// Action creators are generated for each case reducer function
export const { onSyncDependents } = dependentsSlice.actions

export default dependentsSlice.reducer

export function redactDependents(state: DependentsSliceState): DeepNullable<DependentsSliceState> {
  return {
    uninitialized: state.uninitialized,
    patients: state.patients?.map((patient) => ({
      full_name: redactName(patient.full_name),
      date_of_birth: 'REDACTED'
    })),
    lastSync: null
  }
}
