import React from "react";
import type { Session } from '@supabase/supabase-js';

const SignInContext = React.createContext<{
  onLoginSuccess: (data: {session: Session}, navigateTo?: string) => any
}>({
  onLoginSuccess: () => {}
});

export function SignInContextProvider({
  children,
  onLoginSuccess
}: {
  children: React.ReactNode
  onLoginSuccess: (data: {session: Session}, navigateTo?: string) => any
}) {
  return (
    <SignInContext.Provider value={{ onLoginSuccess }}>
      {children}
    </SignInContext.Provider>
  )
}

export function useSignInContext() {
  return React.useContext(SignInContext)
}
