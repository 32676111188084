import { useAppDispatch, useAppSelector } from "../hooks/reduxToolkit";
import { useAsyncAction } from "../hooks/useAsyncAction";
import { useClient, useSession } from "../providers/supabase";
import { useUser } from "../hooks/useUser";
import { useLoadProfileFromServer } from "../hooks/useLoadProfileFromServer";
import { isFilledProfile, setProfile } from "../reduxToolkit/membershipSlice";
import toast from 'react-hot-toast'
import { present } from "../../lib/util/present";
import { Link } from "react-router-dom";

import './deleteAccount.scss'


export function DeleteAccount() {
  const client = useClient()
  const dispatch = useAppDispatch()
  const {id} = useUser()
  const session = useSession()
  const profile = useAppSelector(s => s.membership.profile)
  
  useLoadProfileFromServer()
  
  const [status, onSubmit] = useAsyncAction(async (e: React.FormEvent) => {
    e.preventDefault()
    if (!window.confirm('Are you sure you want to delete your account?')) {
      return false
    } 
    
    const now = new Date().toISOString()
    const ticketResp = await client.functions.invoke('create-ticket', {
      body: {
        email: session?.user?.email,
        name: profile?.full_name,
        description: `Please delete my account with user ID '${id}' along with all associated data.`,
      }
    })
    if (ticketResp.error) { throw ticketResp.error }
    
    if (profile) {
      const updateResult = await client.from('profiles')
        .update({
          updated_at: now,
          account_deletion_requested_at: now,
        })
        .eq('id', profile.id)
      if (updateResult.error) { throw updateResult.error }
      
      const updatedProfile = await client.from('profiles')
        .select('*')
        .eq('id', profile.id)
        .single()
      if (updatedProfile.error) { throw updatedProfile.error }
      
      if (updatedProfile.data && isFilledProfile(updatedProfile.data)) {
        dispatch(setProfile({ profile: updatedProfile.data }))
      }
    }

    toast.success('Your account deletion request has been received.  Please give us up to 5 business days to process your request.')
    return true
  }, [client, session, profile])
  
  if (present(profile?.account_deletion_requested_at)) {
    return <div className="delete-account">
      <div className="row mt-4 justify-content-center">
        <div className="col-12 col-md-10">
          <h1>Delete Account</h1>
          
          <p>
            Your account deletion request has been received.
            Please give us up to 5 business days to process your request.
          </p>
          
          <p>
            If you have changed your mind, you may cancel your account deletion request by asking a question from
            the Help page, or by emailing us
            at <a href="mailto:support@healthsharetech.freshdesk.com">support@healthsharetech.freshdesk.com</a>
          </p>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
        </div>
        <div className="col-12 col-md-4">
          <Link to="/help" className={`btn btn-info help-ask__submit ${status.loading && 'disabled'}`}>
            Go to Help
          </Link>
        </div>
      </div>
    </div>
  }

  return <div className="delete-account">
    <div className="row mt-4 justify-content-center">
      <div className="col-12 col-md-10">
        <h1>Delete Account</h1>
        <p>
          A copy of all of your data is stored securely in our cloud based server.  If you would like us to delete
          your account and all of your data, you may make that request here and we will process that request within
          5 business days.  You will receive an email confirming your account deletion when it is complete.
        </p>
        <p>
          If you change your mind and would like to reactivate your account, please contact us by navigating to the
          help tab and sending a message to our support team.
        </p>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          {status.error && <>
            <div className="alert alert-danger mt-3">
              Something went wrong! Please try again.
            </div>
            If you continue getting this error, please email us
            at <a href="mailto:support@healthsharetech.freshdesk.com">support@healthsharetech.freshdesk.com</a>
          </>}
          {!status.error && status.data &&
            <p>
              Your account deletion request has been received.
              Please give us up to 5 business days to process your request.
            </p>}
        </div>
        <div className="col-12 col-md-4">
          <button className={`btn btn-info help-ask__submit ${status.loading && 'disabled'}`}
              onClick={onSubmit}
              disabled={status.loading}>
            Delete Account
          </button>
        </div>
      </div>
    </div>
  </div>
}
