import { PDFCheckBox, PDFDocument, PDFRadioGroup, PDFTextField } from "pdf-lib";
import fs from 'fs/promises'

export async function fillPdf(
  pdfBytes: string | Uint8Array | ArrayBuffer,
  formFields: Record<string, string | boolean | null | undefined>
): Promise<Uint8Array> {
  const pdfDoc = await PDFDocument.load(pdfBytes)

  const form = pdfDoc.getForm()
  
  // const fields = form.getFields().map((f) => f.getName())
  // fs.writeFile('filelds.json', JSON.stringify(fields))

  for(const [name, value] of Object.entries(formFields)) {
    if (value === null || value === undefined) {
      continue
    }
    const field = form.getField(name)
    if (field instanceof PDFTextField) {
      field.setText(value.toString())
    } else if (field instanceof PDFRadioGroup) {
      field.select(value.toString())
    } else if (field instanceof PDFCheckBox) {
      if (value) {
        field.check()
      } else if (value === false) {
        field.uncheck()
      }
    } else {
      throw new Error(`Unknown field type ${field.constructor}`)
    }
  }

  return await pdfDoc.save()
}

