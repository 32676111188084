import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useIsOnline } from "../providers/supabase"

/**
 * This listener shows a toast message when the user is disconnected from the server.
 */
export function OnlineStatusListener() {
  const isOnline = useIsOnline()

  const [disconnectErrorShown, setDisconnectErrorShown] = useState(false)
  useEffect(() => {
    let timeout: any

    if (!isOnline && !disconnectErrorShown) {
      timeout = setTimeout(() => {
        // toast.error('You have been disconnected from the server')
        setDisconnectErrorShown(true)
      }, 500)
    }
    if (isOnline) {
      timeout = setTimeout(() => {
        if (disconnectErrorShown) {
          // send a new notification showing we're back online
          // toast.success('You have been reconnected to the server')
        }

        setDisconnectErrorShown(false)
      }, 500)
    }

    return () => { clearTimeout(timeout) }
  }, [isOnline, disconnectErrorShown])

  return null;
}
