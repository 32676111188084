import { v4 as uuidv4 } from 'uuid';
import format from "date-fns/format"
import { useEffect, useMemo, useRef, useState } from "react"
import { ExpenseInsert } from "../../../types/supabase"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit"
import { present } from "../../../lib/util/present"
import { selectPatients } from "../../reduxToolkit/selectors/dependents"
import { PatientSelect, addIdToPatient } from "../formComponents/patientSelect"
import { assert } from "../../../lib/util/assert"
import { addExpense, ExpenseInsertPayload } from "../../reduxToolkit/expensesSlice"
import { parseDollarAmount } from '../../../lib/util/parse';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual'
import { IncidentInsertPayload, addIncident, updateIncident } from '../../reduxToolkit/incidentsSlice';
import { IncidentInputGroup, IncidentInputGroupValue } from '../formComponents/incidentInputGroup';

import './newExpenseRowForm.scss'
import { useFeature } from '../../providers/featureFlags';
import { Tooltip } from '../tooltip';

export type NewExpenseOnInsertedFn = (expense: ExpenseInsertPayload, incident?: IncidentInsertPayload | null) => void

const formFields = [
  'date',
  'patient_name',
  'patient_dob',
  'listedAmount',
  'paidAmount',
  'provider',
  'incident_id',
] as const

type FormFieldKey = typeof formFields[number]

export type NewExpenseRowFormProps = {
  onInserted?: NewExpenseOnInsertedFn

  prefilledData?: Partial<Pick<ExpenseInsert, FormFieldKey>>
  disabledFields?: Array<keyof ExpenseInsert>
  /**
   * Provide the incident ID where this expense row should go.
   * Cannot be used with `allowNewIncident`
   */
  incidentId?: string
  allowNewIncident?: boolean
}

export function NewExpenseRowForm({
  onInserted,
  incidentId: requiredIncidentId,
  allowNewIncident,
  prefilledData,
  disabledFields
}: NewExpenseRowFormProps) {
  const dispatch = useAppDispatch()
  const {membershipId, userId} = useAppSelector((s) => s.membership)
  const patients = useAppSelector(selectPatients).map(addIdToPatient)
  const allProviders = useAppSelector((s) => uniq(s.expenses.expenses.map((e) => e.provider).filter(present)))
  const requiredIncident = useAppSelector((s) => present(requiredIncidentId) && s.incidents.incidents.find((i) => i.id === requiredIncidentId))

  const paidWithHraEnabled = useFeature('paid_with_hra')

  const defaultData = useMemo<Partial<ExpenseInsert>>(() => {
    return {
      date: format(Date.now(), 'yyyy-MM-dd'),
      patient_name: patients[0]?.full_name,
      patient_dob: patients[0]?.date_of_birth,
      incident_id: requiredIncidentId || undefined,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [row, setRow] = useState<Partial<ExpenseInsert>>({
    ...defaultData,
    ...prefilledData
  })

  const formRef = useRef<HTMLFormElement>(null)
  const [wasValidated, setWasValidated] = useState(false)

  // Update the prefilled state if the prefilledData prop changes
  const prevPrefilledData = useRef<Partial<ExpenseInsert>>(row)
  useEffect(() => {
    if (!prefilledData) { return }
    if (isEqual(prefilledData, prevPrefilledData.current)) { return }

    setRow((r) => {
      const newRow = {...r}
      for (const [keyStr, value] of Object.entries(prefilledData)) {
        const key = keyStr as keyof typeof prefilledData

        // Change the form value only if the user hasn't set it
        // i.e. the current value is the default value
        if (typeof value != 'undefined' && value !== null && r[key] === defaultData[key]) {
          console.debug('setting', key, 'to', value, 'from', defaultData[key])
          newRow[key] = value as any
        }
      }
      return newRow
    })
    prevPrefilledData.current = prefilledData
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledData])

  const [selectedIncident, setSelectedIncident] = useState<IncidentInputGroupValue | null | undefined>(null)

  const doInsert = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!formRef?.current?.reportValidity()) {
      setWasValidated(true)
      return
    }

    if (!row.patient_dob || !row.patient_name || !row.date) { throw new Error('Form not filled') }

    let incidentId: string | null | undefined = requiredIncidentId || (selectedIncident && selectedIncident.id)
    let addIncidentAction: ReturnType<typeof addIncident> | undefined = undefined
    const now = new Date().toISOString()

    if (selectedIncident && !present(incidentId)) {
      const description = selectedIncident.description
      assert(description)

      incidentId = uuidv4()

      addIncidentAction = dispatch(addIncident({
        ...selectedIncident,
        id: incidentId,
        updated_at: now,
        created_at: now,

        membership_id: membershipId,
        created_by_user_id: userId,
        start_date: row.date,
        patient_dob: row.patient_dob,
        patient_name: row.patient_name,
        description,
      }))
    } else {
      const incident = requiredIncident || selectedIncident
      const incidentDate = incident && 'start_date' in incident && incident.start_date
      if (incidentDate && row.date < incidentDate) {
        // This expense is before the incident, so we need to update the incident's start date
        dispatch(updateIncident({
          id: incident.id,
          updated_at: now,
          start_date: row.date
        }))
      }
    }

    const expenseId = uuidv4()
    const addExpenseAction = dispatch(addExpense({
      id: expenseId,
      updated_at: now,
      created_at: now,
      membership_id: membershipId,
      created_by_user_id: userId,
      incident_id: incidentId || null,
      date: row.date,
      patient_dob: row.patient_dob,
      patient_name: row.patient_name,
      listedAmount: row.listedAmount,
      paidAmount: row.paidAmount,
      provider: row.provider,
      paid_with_hra: row.paid_with_hra,
    }))

    if (onInserted) {
      onInserted(addExpenseAction.payload, addIncidentAction?.payload ?? null)
    }
  }

  const selectedPatient = present(row.patient_name) && present(row.patient_dob) &&
      patients.find((p) => p.full_name === row.patient_name && p.date_of_birth === row.patient_dob)

  return <form
      ref={formRef}
      className={`new-expense-row-form ${wasValidated && 'was-validated'}`}>
    <div className="input-group has-validation">
      <label className='input-group-text'>Date</label>
      <input name="date" placeholder="DateRange" type="date"
        className="form-control"
        required
        disabled={disabledFields?.includes('date')}
        value={row.date || ''}
        onChange={(e) => { setRow({...row, date: e.target.value }) }}></input>
    </div>
    <div className='input-group'>
      <label className='input-group-text'>Provider</label>
      <datalist id="providers">
          {allProviders.map((p) => <option key={p} value={p} />)}
      </datalist>

      <input name="provider" type='text' className='form-control'
        disabled={disabledFields?.includes('provider')}
        value={row.provider|| ''}
        list='providers'
        onChange={(e) =>  setRow({...row, provider: e.target.value})}></input>

    </div>
    <div className='input-group has-validation'>
      <label className='input-group-text'>Patient</label>
      {patients && <PatientSelect className="form-select"
        required
        options={patients}
        value={selectedPatient}
        disabled={disabledFields?.includes('patient_name') && disabledFields?.includes('patient_dob')}
        onChange={({value}) => {
          if (!value) {
            setRow({
              ...row,
              patient_name: undefined,
              patient_dob: undefined,
              incident_id: undefined
            })
          } else if ('id' in value) {
            setRow({
              ...row,
              patient_name: value.full_name,
              patient_dob: value.date_of_birth,
              incident_id: null
            })
          }
        }} />}


      <div className="invalid-feedback">
        Please choose which of your dependents was the patient.
      </div>
    </div>
    <div className='input-group'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>
          Pre-Discount Amt
        </span>
        <Tooltip  className='d-none d-md-inline ms-auto' tooltip="The listed amount before any discounts, such as the self-pay discount or financial aid" />
        <Tooltip className='d-inline d-md-none'
            tooltip="The listed amount before any discounts, such as the self-pay discount or financial aid">
          Pre-Discount
        </Tooltip>
          
      </label>
      <input name="listedAmount" type='text' className='form-control'
        value={`$${row.listedAmount || ''}`}
        pattern='\$\d*\.?\d*|\.\d+'
        disabled={disabledFields?.includes('listedAmount')}
        onChange={(e) => {
          const listedAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, listedAmount})
        }}></input>
    </div>
    <div className='input-group has-validation'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>Amount You Paid</span>
        <Tooltip className='d-none d-md-inline ms-auto' tooltip="The amount you paid, or will pay, for this expense.  You will be reimbursed this amount." />
        <Tooltip className='d-inline d-md-none'
            tooltip="The amount you paid, or will pay, for this expense.  You will be reimbursed this amount.">
          Paid
        </Tooltip>
      </label>
      <input name="paidAmount" type='text' className='form-control'
        value={`$${row.paidAmount || ''}`}
        pattern='\$\d+\.?\d*|\.\d+'
        disabled={disabledFields?.includes('paidAmount')}
        onChange={(e) => {
          const paidAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, paidAmount})
        }}></input>

      <div className="invalid-feedback">
        Please enter the amount you paid.
      </div>
    </div>
    
    {paidWithHraEnabled &&
      <div className='input-group'>
        <div className='new-expense-row-form__form-check-wrapper'>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio'
              name='paid_with' id='paid_with_hra' value='hra'
              checked={row.paid_with_hra === true}
              onChange={(e) => {
                if (e.target.checked) {
                  setRow({...row, paid_with_hra: true})
                }
              }}></input>
            <label className="form-check-label" htmlFor="paid_with_hra">HRA card</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio'
              name='paid_with' id='paid_with_personal' value='personal'
              checked={row.paid_with_hra === false}
              onChange={(e) => {
                if (e.target.checked) {
                  setRow({...row, paid_with_hra: false})
                }
              }}></input>
            <label className="form-check-label" htmlFor="paid_with_personal">Personal</label>
          </div>
        </div>
      </div>}

    <div className="col-12 mt-2">
      <IncidentInputGroup
        allowNewIncident={allowNewIncident}
        requiredIncidentId={requiredIncidentId}
        selectedPatient={selectedPatient}
        value={selectedIncident}
        onChange={setSelectedIncident} />
    </div>

    <div className="col-12 d-flex mt-2">
    <button type='submit'
        className={`btn btn-primary`}
        onClick={doInsert}>
        Add
    </button>
    </div>
  </form>
}

