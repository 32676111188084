import { useEffect, useState } from "react"
import { HashRouter } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../hooks/reduxToolkit"
import { SplashScreen } from "../screens/splashScreen"
import { persistor, store } from "../reduxToolkit/store"
import { PersistGate } from "redux-persist/integration/react"
import { useClient, useIsOnline, useSession } from "../providers/supabase"
import Onboarding from "../screens/onboarding"
import { SignIn } from "../screens/signIn"
import { OnlineRequired } from "../screens/onlineRequired"
import { PostgrestError } from "@supabase/supabase-js"
import { loadReduxStateFromServer } from "../../lib/queries/loadReduxStateFromServer"
import { onSyncMembership } from "../reduxToolkit/membershipSlice"
import { onSyncDependents } from "../reduxToolkit/dependentsSlice"
import { PURGE } from "redux-persist"

type StartupFlowState =
  'LoadStateFromStorage' |
  'ReloadStateFromServer' |
  'SignInForm' |
  'ShowOnlineRequiredPage' |
  'ShowOnboarding' |
  'Complete'

interface StartupFlowInitializerProps {
  onComplete: () => void
}

/**
 * This initializer handles the Startup sequence for the app.
 * See the Startup Flow Chart in the docs for more details.
 */
export function StartupFlowInitializer({
    children,
    onComplete
}: React.PropsWithChildren<StartupFlowInitializerProps>) {
  const isOnline = useIsOnline()
  const session = useSession()
  const client = useClient()
  const localOnboardingCompletedAt = useAppSelector(state => state.membership?.onboardingCompletedAt || 0)

  const onlineUserId = session?.user?.id

  const [flowState, setFlowState] = useState<StartupFlowState>('LoadStateFromStorage')
  const [error, setError] = useState<Error | PostgrestError>()

  console.log('StartupFlowInitializer', {isOnline, userId: session?.user?.id}, {flowState, isOnline, localOnboardingCompletedAt})

  if (error) { throw error }

  switch(flowState) {
    case 'LoadStateFromStorage':
      return <PersistGate
        loading={<SplashScreen />}
        persistor={persistor}
        onBeforeLift={() => {
          const doIt = async () => {
            // This callback is executed before the redux hooks get updated, so we can't use the hooks here.
            const state = store.getState()
            console.log('onBeforeLift', {isOnline, userId: onlineUserId }, 'state.membership.userId', state.membership?.userId, 'state.membership.onboardingCompletedAt', state.membership?.onboardingCompletedAt)
            if (isOnline) {
              // If we're online, check the online user ID
              if (onlineUserId) {
                if (state.membership?.userId && state.membership.userId !== onlineUserId) {
                  // We're signed in as a different user, so we need to purge & reload the state
                  await persistor.purge()
                  await persistor.flush()
                }
                setFlowState('ReloadStateFromServer')
              } else {
                setFlowState('SignInForm')
              }
            } else {
              // If we're not online, check local onboarding state
              const offlineUserId = state.membership?.userId
              if (offlineUserId) {
                if (state.membership.onboardingCompletedAt > 0) {
                  setFlowState('Complete')
                  onComplete()
                } else {
                  setFlowState('ShowOnlineRequiredPage')
                }
              } else {
                setFlowState('ShowOnlineRequiredPage')
              }
            }
          }

          doIt().catch((err) => setError(err))
        }}>
          <SplashScreen />
      </PersistGate>
    case 'ReloadStateFromServer':
      return <ReloadStateFromServer
        onStateLoaded={({hasCompletedOnboarding}) => {
          if (hasCompletedOnboarding) {
            setFlowState('Complete')
            onComplete()
          } else if (isOnline) {
            setFlowState('ShowOnboarding')
          } else {
            setFlowState('ShowOnlineRequiredPage')
          }
        }}
        onError={(err) => {
          if (/NetworkError/.test(err.message)) {
            // If a network error occurs, check the local state for the "Onboarding Complete"

            if (localOnboardingCompletedAt > 0) {
              setFlowState('Complete')
              onComplete()
            } else {
              setFlowState('ShowOnlineRequiredPage')
            }
          } else {
            setError(err)
          }
        }} />

    case 'SignInForm':
      return <SignIn onLoginSuccess={(data, navigateTo) => {
        const doIt = async () => {
          const state = store.getState()
          if (state?.membership?.userId && data.session.user && data.session.user.id !== state.membership.userId) {
            // We signed in as a different user, so we need to clear our stored state
            await persistor.purge()
            await persistor.flush()
          }

          setFlowState('ReloadStateFromServer')
          // Clear out any hash params
          const url = new URL(window.location.href)
          url.pathname = navigateTo || ''
          url.hash = ''
          url.search = ''
          window.history.pushState({}, '', url)
        }

        doIt()
          .catch((err) => setError(err))
      }} />

    case 'ShowOnlineRequiredPage':
      return <OnlineRequired onConnectionEstablished={() => {
        // double-check our session
        client.auth.getSession()
          .then(({data, error}) => {
            if (error) { throw error }
            if (data?.session?.user?.id) {
              setFlowState('ReloadStateFromServer')
            } else {
              setFlowState('SignInForm')
            }
          })
          .catch((err) => setError(err))
      }} />

    case 'ShowOnboarding':
      return <HashRouter>
        <Onboarding onComplete={() => {
          setFlowState('Complete')
          // Clear out any hash params
          const url = new URL(window.location.href)
          url.pathname = ''
          url.hash = ''
          url.search = ''
          window.history.pushState({}, '', url)
          onComplete()
        }} />
      </HashRouter>

    case 'Complete':
      return <>{children}</>

    default:
      raiseUnknownState(flowState)
  }
}

/**
 * Implements the view for the ReloadStateFromServer state.
 * This state is only entered if the user is signed in and online.
 */
function ReloadStateFromServer({
  onStateLoaded,
  onError
} : {
  onStateLoaded: (state: { hasCompletedOnboarding: boolean }) => void
  onError: (error: Error) => void
}) {
  const dispatch = useAppDispatch()
  const user = useSession()?.user
  const client = useClient()

  if (!user) { throw new Error('User is not signed in') }

  useEffect(() => {
    loadReduxStateFromServer(client, { userId: user.id })
      .then(
        (result) => {
          if (result.membership) {
            dispatch(onSyncMembership({
              membership: result.membership,
              role: result.role,
              profile: result.profile,
              settings: result.settings,
              userId: user.id
            }))
          }
          if (result.dependents) {
            dispatch(onSyncDependents({
              dependents: result.dependents,
              profiles: result.dependentProfiles
            }))
          }
          onStateLoaded(result)
        },
        (error) => onError(error)
      )
  }, [dispatch, user, client, onStateLoaded, onError])

  return <SplashScreen />
}


function raiseUnknownState(state: never): never {
  throw new Error(`Unknown flow state: ${state}`)
}
