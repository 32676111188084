import type { ExpenseModel } from "../expensesSlice";
import type { IncidentModel } from "../incidentsSlice";
import { RootState } from "../store";
import { SubmissionModel } from "../submissionsSlice";

export type IncidentById =
  IncidentModel & {
    expenses: ExpenseModel[],
    submissions: SubmissionModel[]
  }

export function selectIncidentById(id: string): (state: RootState) => IncidentById | null {
  return (state) => {
    const incident = state.incidents.incidents.find((i) => i.id === id)
    if (!incident) {
      return null
    }
    const expenses = state.expenses.expenses.filter((e) => e.incident_id === id)
    const submissions = state.submissions.submissions.filter((s) => s.incident_id === id)
    return {...incident, expenses, submissions}
  }
}
