
export function generateBase36Key(
  options?: {
    length?: number;
  },
  crypto = window.crypto
) {
  const bytes = new Uint8Array(options?.length || 16);
  crypto.getRandomValues(bytes);
  const base36Value = Array.from(bytes).map(b => b.toString(36)).join('');
  return base36Value;
}
