import { AppSupabaseClient, Tables } from "../../types/supabase";
import type { RowSyncResponse, SyncableTable } from "./action";

export type Delete<Table extends SyncableTable> = {
  type: 'delete',
  table: Table,
  record: { id: string, updated_at: string, deleted_at?: string | null }
}

export async function syncDelete<Table extends SyncableTable>(
  client: AppSupabaseClient,
  action: Delete<Table>
): Promise<RowSyncResponse<Table>> {
  const now = action.record.deleted_at || new Date().toISOString();
  const resp = await client.from<SyncableTable, Tables[SyncableTable]>(action.table)
    .update({ deleted_at: now })
    .eq('id', action.record.id)
    .lte('updated_at', action.record.updated_at)
    .select('*');

  if (resp.error) {
    return {
      result: 'error',
      error: resp.error
    };
  }

  // Our delete was applied - the data on the server should be null
  if (resp.data && resp.data[0]) {
    return {
      result: 'applied',
      data: null
    };
  }
  // The row delete was rejected due to updated_at on the server being newer
  // query the current state from the server
  const query = await client.from(action.table)
    .select('*')
    .eq('id', action.record.id);
  if (query.error) {
    return {
      result: 'error',
      error: query.error
    };
  }
  return {
    result: 'rejected',
    data: (query.data && query.data[0]) || null as any
  };
}
