import { present } from "async-toolbox"
import { markSubmissionAsRepaid } from "../../app/reduxToolkit/actions/markSubmissionAsRepaid"
import { RootState } from "../../app/reduxToolkit/store"
import { SubmissionModel, SubmittedSubmissionModel, isSubmitted, submittedToText } from "../../app/reduxToolkit/submissionsSlice"
import { selectExpensesNeedingRepaymentForSubmission } from "../../app/reduxToolkit/selectors/selectExpensesNeedingRepaymentForSubmission"
import { VirtualToDo } from "./types"
import { endOfYear, formatISO, startOfYear } from "date-fns"
import { parseDateInTimeZone } from "../formatDateInTimeZone"
import { selectIncidentsByUnit } from "../../app/reduxToolkit/selectors/incidentsByUnit"
import { formatCurrency } from "../formatCurrency"

export function * createToDosForSubmission(s: RootState, submission: SubmissionModel): Generator<VirtualToDo> {
  if (!isSubmitted(submission)) { return }
  
  let checks: Array<(s: RootState, submission: SubmittedSubmissionModel) => VirtualToDo | void> = [
    checkHraRepayment
  ]

  switch(submission.submission_type) {
    case 'HRA':
      break;
    case 'CHM':
    case 'CHM-addon':
      checks.push(
        checkPersonalResponsibility
      )
      break;
  }

  for (const check of checks) {
    const todo = check(s, submission)
    if (todo) {
      yield todo
    }
  }
}

function checkHraRepayment(s: RootState, submission: SubmittedSubmissionModel): VirtualToDo | void {
  if (present(submission.repaid_at)) { return }
  
  const expensesNeedingRepayment = selectExpensesNeedingRepaymentForSubmission(submission)(s)
  if (!expensesNeedingRepayment || expensesNeedingRepayment.length === 0) { return }

  const key = `submission/${submission.id}/repay`
  const totalCents = expensesNeedingRepayment.reduce((sum, expense) => {
    if (!expense) { return sum }
    return sum + Math.round((parseFloat(expense.paidAmount) * 100))
  }, 0)
  const total = totalCents / 100

  // due at the end of the year
  const dueDate = formatISO(endOfYear(parseDateInTimeZone(submission.submitted_at)), { representation: 'date' })

  let why: string | undefined
  if (submission.submission_type === 'HRA') {
    why = 'Since you submitted these expenses to the HRA, and later submitted the same expenses to CHM, you need to repay the HRA for this amount.'
  } else if ([ 'CHM', 'CHM-addon' ].includes(submission.submission_type)) {
    if (expensesNeedingRepayment.some((e) => e.paid_with_hra)) {
      why = 'Since you paid for these expenses with the HRA card, and later submitted these expenses to CHM, you need to repay the HRA for this amount.'
    }
  }
  
  return {
    key,
    title: `Repay ${formatCurrency(total)} to HRA`,
    dueDate,
    record_id: submission.id,
    record_type: 'submission',
    todo_type: 'repay',
    action: {
      type: 'dispatch',
      confirm: {
        title: `Did you write a check for ${formatCurrency(total)} to your HRA?`,
        body: why
      },
      action: markSubmissionAsRepaid({ submission_ids: [submission.id] })
    }
  }
}

function checkPersonalResponsibility(s: RootState, submission: SubmittedSubmissionModel): VirtualToDo | void {
  // Is there a submitted "personal responsibility" to mark that we did this already?
  const personalResponsibilitySubmission = s.submissions.submissions
    .find(s => s.submission_type === 'CHM-personal-responsibility' &&
      s.parent_submission_id === submission.id)

  if (personalResponsibilitySubmission && isSubmitted(personalResponsibilitySubmission)) {
    return
  }

  const incident = s.incidents?.incidents?.find(i => i.id === submission.incident_id)
  if (!incident) { return }

  // Find the CHM "unit" for whom this incident was submitted
  const incidentsByUnit = selectIncidentsByUnit(s)
  const thisUnitKey = Object.keys(incidentsByUnit).find((unitKey) => {
    const incidents = incidentsByUnit[unitKey]
    return incidents?.some((i) => i.id === incident.id)
  })
  if (!thisUnitKey) { return }

  const incidentIDsInThisUnit = incidentsByUnit[thisUnitKey]?.map(i => i.id) || []

  // Have we previously submitted over $1,000 of CHM expenses this year for this unit?
  const startOfThisYear = formatISO(startOfYear(parseDateInTimeZone(submission.submitted_at)), { representation: 'date' })
  const allPriorChmSubmissions = s.submissions?.submissions?.filter(s => {
      return ['CHM', 'CHM-addon'].includes(s.submission_type) &&
        isSubmitted(s) &&
        s.submitted_at < submission.submitted_at &&
        s.submitted_at >= startOfThisYear &&
        incidentIDsInThisUnit.includes(s.incident_id)
    }) || []
  const expensesInPriorSubmissions = allPriorChmSubmissions.flatMap(s => s.expense_ids || [])
  const paidTotalCents = expensesInPriorSubmissions.reduce((sum, expenseId) => {
    const expense = s.expenses.expenses.find(e => e.id === expenseId)
    if (!expense || !expense.paidAmount) { return sum }
    return sum + Math.round((parseFloat(expense.paidAmount) * 100))
  }, 0)
  const paidTotal = paidTotalCents / 100

  if (paidTotal < 1000) {
    // Create the TODO
    const key = `submission/${submission.id}/personal-responsibility`

    const totalInThisSubmissionCents = submission.expense_ids.reduce<number>((sum, expenseId) => {
      const expense = s.expenses.expenses.find(e => e.id === expenseId)
      if (!expense || !expense.paidAmount) { return sum }
      return sum + Math.round((parseFloat(expense.paidAmount) * 100))
    }, 0)
    const totalInThisSubmission = totalInThisSubmissionCents / 100
    const maxPossible = 1000 - paidTotal
    const amountToReimburse = Math.min(totalInThisSubmission, maxPossible)

    return {
      key,
      title: `Get reimbursed ${formatCurrency(amountToReimburse)} for CHM personal responsibility`,
      dueDate: formatISO(endOfYear(parseDateInTimeZone(submission.submitted_at)), { representation: 'date' }),
      record_id: submission.id,
      record_type: 'submission',
      todo_type: 'personal-responsibility',
      action: {
        type: 'navigate',
        route: `/incidents/${submission.incident_id}/submit/${submission.id}/personal-responsibility`,
      }
    }
  }
}
