import { useCallback } from "react"

export function CheckboxYesNoInputGroup<TKey extends string>({
  field,
  label,
  value,
  changeData,
  validationError,
  className,
  children
}: React.PropsWithChildren<{
  field: TKey,
  label: string,
  value: boolean | null | undefined,
  changeData: (field: TKey, value: boolean | null | undefined) => void,
  validationError?: { message: string },
  className?: string,
}>) {
  return <div className={`checkbox-yes-no-input-group ${className}`} id={field}>
    <label className="me-2">{label}</label>
    <div className={`form-check form-check-inline ${validationError && 'is-invalid'}`}>
      <input className='form-check-input' type='checkbox'
        name={`${field}_yes`} id={`${field}_yes`} value='yes'
        checked={value === true}
        onChange={
          useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
            const value = evt.target.checked
            changeData(field, value)
          }, [field, changeData])
        }
        ></input>
        <label className="form-check-label" htmlFor={`${field}_yes`}>yes</label>
    </div>
    <div className={`form-check form-check-inline ${validationError && 'is-invalid'}`}>
      <input className='form-check-input' type='checkbox'
        name={`${field}_no`} id={`${field}_no`} value='no'
        checked={value === false}
        onChange={
          useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
            const value = !evt.target.checked
            changeData(field, value)
          }, [field, changeData])
        }
        ></input>
        <label className="form-check-label" htmlFor={`${field}_no`}>no</label>
    </div>

    <div className="invalid-feedback">{validationError?.message}</div>

    {children}
  </div>
}
