import differenceInMonths from "date-fns/differenceInMonths"
import differenceInYears from "date-fns/differenceInYears"

export function calculateAgeAsString(dob: Date | null | undefined) {
  const age = dob ? differenceInYears(new Date(), dob) : null
  let ageStr: string
  if (age === null) {
    ageStr = ''
  } else if (age < 1) {
    const diff = differenceInMonths(new Date(), dob!).toString() + 'm'
    if (diff.length >= 3) {
      ageStr = '1'
    } else {
      ageStr = diff
    }
  } else {
    ageStr = age.toString().slice(-2)
  }
  
  return ageStr
}
