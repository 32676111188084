import { SubmissionData2024CHM, SubmissionData2024CHMAddon } from "../../app/reduxToolkit/submissionsSlice";
import { downloadPdf } from "../pdf/downloadPdf";
import { present } from "../util/present";
import { fillMaternityVerificationForm2024 } from "./2024/fillMaternityVerificationForm2024";
import { fillMedicalBillWorksheet2024 } from "./2024/fillMedicalBillWorksheet2024";
import { fillMedicalInformationRelease2024 } from "./2024/fillMedicalInformationRelease2024";
import { fillSharingRequestForm2024 } from "./2024/fillSharingRequestform2024";


const documentMapping = {
  'SharingRequestForm.pdf': '/documents/2024/Sharing Request Pack 2023 v1_sharing-request-form.pdf',
  'MedicalInformationRelease.pdf': '/documents/2024/Sharing Request Pack 2023 v1_medical-information-release.pdf',
  'MedicalBillWorksheet.pdf': '/documents/2024/Sharing Request Pack 2023 v1_medical-bill-worksheet.pdf',
  'MaternityVerificationForm.pdf': '/documents/2024/Sharing Request Pack 2023 v1_maternity-verification-form.pdf',
}

interface GenerateDocuments2024Result {
  sharingRequestForm: File
  medicalInformationRelease: File
  medicalBillWorksheets: File[]
  maternityVerificationForm?: File | null | undefined
}

export async function generateDocuments2024(
  data: SubmissionData2024CHM
): Promise<GenerateDocuments2024Result> {
  let [
    sharingRequestForm,
    medicalInformationRelease,
    medicalBillWorksheet,
    maternityVerificationForm
  ] = await Promise.all([
    downloadPdf(documentMapping['SharingRequestForm.pdf']),
    downloadPdf(documentMapping['MedicalInformationRelease.pdf']),
    downloadPdf(documentMapping['MedicalBillWorksheet.pdf']),
    data?.maternityVerificationFormData?.isMaternityIncident ?
      downloadPdf(documentMapping['MaternityVerificationForm.pdf']) :
      null,
  ].filter(present))
  
  if (data.sharingRequestFormData) {
    sharingRequestForm = await fillSharingRequestForm2024(sharingRequestForm, data.sharingRequestFormData)
  }
  if (data.medicalInformationReleaseData) {
    medicalInformationRelease = await fillMedicalInformationRelease2024(medicalInformationRelease, data.medicalInformationReleaseData)
  }
  const medicalBillWorksheets: ArrayBuffer[] = []
  if (data.medicalBillWorksheetData) {
    // slice the expenses into groups of 8
    const expenseRows = data.medicalBillWorksheetData.expenseRows
    for (let i = 0; i < expenseRows.length; i += 8) {
      const slice = expenseRows.slice(i, i + 8)
      medicalBillWorksheets.push(
        await fillMedicalBillWorksheet2024(medicalBillWorksheet, {
        ...data.medicalBillWorksheetData,
        expenseRows: slice
      }))
    }
  }
  
  if (maternityVerificationForm && data?.maternityVerificationFormData?.isMaternityIncident) {
    maternityVerificationForm = await fillMaternityVerificationForm2024(maternityVerificationForm, data.maternityVerificationFormData)
  }
  
  return {
    sharingRequestForm: new File([sharingRequestForm], 'SharingRequestForm.pdf', { type: 'application/pdf' }),
    medicalInformationRelease: new File([medicalInformationRelease], 'MedicalInformationRelease.pdf', { type: 'application/pdf' }),
    medicalBillWorksheets: medicalBillWorksheets.map((pdfBytes, i) =>
      new File([pdfBytes],
        i === 0 ?
          `MedicalBillWorksheet.pdf` :
          `MedicalBillWorksheet-${i + 1}.pdf`,
        { type: 'application/pdf' })),

    maternityVerificationForm: maternityVerificationForm ?
      new File([maternityVerificationForm], 'MaternityVerificationForm.pdf', { type: 'application/pdf' }) :
      null
  }
  
}

export interface GenerateAddon2024Result {
  medicalBillWorksheets: File[]
}

export async function generateAddon2024(
  data: SubmissionData2024CHMAddon
): Promise<GenerateAddon2024Result> {
  
  const medicalBillWorksheet = await downloadPdf(documentMapping['MedicalBillWorksheet.pdf'])
  
  const medicalBillWorksheets: ArrayBuffer[] = []
  if (data.medicalBillWorksheetData) {
    // slice the expenses into groups of 8
    const expenseRows = data.medicalBillWorksheetData.expenseRows
    for (let i = 0; i < expenseRows.length; i += 8) {
      const slice = expenseRows.slice(i, i + 8)
      medicalBillWorksheets.push(
        await fillMedicalBillWorksheet2024(medicalBillWorksheet, {
        ...data.medicalBillWorksheetData,
        expenseRows: slice
      }))
    }
  }
  
  return {
    medicalBillWorksheets: medicalBillWorksheets.map((pdfBytes, i) =>
      new File([pdfBytes],
        i === 0 ?
          `MedicalBillWorksheet.pdf` :
          `MedicalBillWorksheet-${i + 1}.pdf`,
        { type: 'application/pdf' })),
  }
}
