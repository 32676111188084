import {createBrowserRouter, RouterProvider, ScrollRestoration, Outlet} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react';

import { Homepage } from './app/routes/homepage';
import { TopNav } from './app/components/topNav'
import { Expenses } from './app/routes/expenses';
import EditProfile from './app/routes/editProfile';
import IncidentsShow from './app/routes/incidents/show';
import ChangePassword from './app/routes/changePassword';
import { NotFound } from './app/screens/notFound';
import { Files } from './app/routes/files';
import EditDependents from './app/routes/editDependents';
import Notifications from './app/components/notifications';
import { Help } from './app/routes/help';
import IncidentsSubmit from './app/routes/incidents/submit';
import SubmitHome from './app/routes/incidents/submit/home';
import SubmitShow from './app/routes/incidents/submit/show';
import { BulkAddExpense } from './app/routes/bulkAddExpense';
import BulkAddExpenseToIncident from './app/routes/incidents/bulkAddExpenseToIncident';
import { Todos } from './app/routes/todos';
import SubmitPersonalResponsibility from './app/routes/incidents/submit/submitPersonalResponsibility';
import { DeleteAccount } from './app/routes/deleteAccount';
import EditSettings from './app/routes/editSettings';
import { ErrorFallback } from './app/components/error-boundary';
import { useMemo } from 'react';

const router = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorFallback />,
    children: [
      { 
        index: true,
        element: <Homepage />,
      },
      {
        path: 'changePassword',
        element: <ChangePassword />,
      },
      {
        path: 'profile',
        element: <EditProfile />,
      },
      {
        path: 'settings',
        element: <EditSettings />,
      },
      {
        path: 'dependents',
        element: <EditDependents />,
      },
      {
        path: 'expenses',
        element: <Expenses />,
      },
      {
        path: 'bulkAddExpense',
        element: <BulkAddExpense />,
      },
      {
        path: 'incidents/:id',
        element: <IncidentsShow />,
      },
      {
        path: 'incidents/:incidentId/submit',
        element: <IncidentsSubmit />,
        children: [
          { index: true, element: <SubmitHome /> },
          { path: ':id', element: <SubmitShow /> },
          { path: ':submissionId/personal-responsibility', element: <SubmitPersonalResponsibility /> },
        ]
      },
      {
        path: 'incidents/:incidentId/bulkAddExpense',
        element: <BulkAddExpenseToIncident />,
      },
      {
        path: 'todos',
        element: <Todos />,
      },
      {
        path: 'files/*',
        element: <Files />,
      },
      {
        path: 'help',
        element: <Help />,
      },
      {
        path: 'deleteAccount',
        element: <DeleteAccount />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  }
])

function Layout() {
  const isInsidePWA = useMemo(() => {
    const isInsidePWA = window.matchMedia('(display-mode: standalone)').matches
    return isInsidePWA
  }, [])
  
  return (
    <div className={`App container ${isInsidePWA && 'is-inside-pwa'}`}>
      <TopNav />
      <Outlet />
      <Notifications />
      <div className='mt-4' />
      <ScrollRestoration />
    </div>
  );
}

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
