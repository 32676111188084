import { IncidentModel } from "../incidentsSlice";
import { RootState } from "../store";
import { selectPatientsByUnit } from "./patientsByUnit";

export function selectIncidentsByUnit(s: RootState): { [unitId: string]: IncidentModel[] } {
  const incidentsByUnit: { [unitId: string]: IncidentModel[] } = {}

  const patientsByUnit = selectPatientsByUnit(s)
  Object.keys(patientsByUnit).forEach((unitId) => {
    const patients = patientsByUnit[unitId]
    incidentsByUnit[unitId] = s.incidents.incidents.filter((i) =>
      // Add this incident to the unit if any of the patients in the unit match the incident
      patients?.some((p) =>
        p.date_of_birth === i.patient_dob &&
        p.full_name === i.patient_name)
    )
  })

  return incidentsByUnit
}
