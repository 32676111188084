import { useEffect, useRef, useState } from "react"
import { useValidatePromoCode } from "../../hooks/useValidatePromoCode"
import { present } from "../../../lib/util/present"
import { useNavigate } from "react-router-dom"

export function OnboardingRedeemCode() {
  const [promoCode, setPromoCode] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [organizationSize, setOrganizationSize] = useState('50')
  const [hasHra, setHasHra] = useState<boolean>()
  const navigate = useNavigate()
  
  useEffect(() => {
    // Try to load a saved promo code from local storage
    const savedCode = localStorage.getItem('promoCode')
    if (present(savedCode)) {
      setPromoCode(savedCode.toUpperCase())
    }
    localStorage.removeItem('promoCode')
  }, [])
  
  const [isValid, { error, redeeming }, redeemCode] = useValidatePromoCode(promoCode)
  
  const formRef = useRef<HTMLFormElement>(null)

  return <div className="row">
    <div className="col-12 col-xl-8 offset-xl-2">
      <h1>Redeem a Trial Code</h1>
      <p>
        If you have a trial code, enter it here to get started.
      </p>
      <p>
        <input type="text"
          className="form-control"
          placeholder="Enter your code here"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
          />
      </p>
      {isValid === true &&
        <p>
          <strong className="text-success">Your code is valid!</strong>
          <br/>
          This code grants you a free month-long trial of the HealthShare app.
          <br/>
          Please fill out the following information to redeem your code.

          <form className="mt-3"
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                if (formRef.current && !formRef.current?.checkValidity()) {
                  return false
                }

                redeemCode({
                  organizationName,
                  organizationSize,
                  hasHra
                }).then(() => {
                  // Now that we've inserted a promo code redemption for this user, the onboarding home should work.
                  navigate('/')
                })

                return false
              }}>
            <div className="form-group has-validation">
              <label htmlFor="organizationName">Organization Name</label>
              <input type="organizationName" required className="form-control" id="organizationName"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)} />

              <div className="invalid-feedback">
                Please enter your organization's name.
              </div>
            </div>
            
            <div className="form-group has-validation mt-2">
              <label htmlFor="organizationSize">How many employees does your organization have using CHM?</label>
              <select className="form-control" id="organizationSize" required
                  value={organizationSize}
                  onChange={(e) => setOrganizationSize(e.target.value)}>
                <option value="50">Less than 50</option>
                <option value="100">50 to 100</option>
                <option value="500">100 to 500</option>
                <option value="501">More than 500</option>
              </select>
              
              <div className="invalid-feedback">
                Please select the size of your organization.
              </div>
            </div>
            <div className="form-group has-validation mt-2">
              <label htmlFor="HRA">Do you also have an HRA for your employees?</label>
              <div className="form-check">
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio'
                    required
                    name='has_hra' id='has_hra_yes' value='yes'
                    checked={hasHra === true}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHasHra(true)
                      }
                    }}></input>
                  <label className="form-check-label" htmlFor="has_hra_yes">Yes</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio'
                    required
                    name='has_hra' id='has_hra_no' value='no'
                    checked={hasHra === false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHasHra(false)
                      }
                    }}></input>
                  <label className="form-check-label" htmlFor="has_hra_no">No</label>
                </div>
              </div>
              
              <div className="invalid-feedback">
                Please select whether you have an HRA for your employees.
              </div>
            </div>

            <button type="submit" className={`btn btn-primary mt-2 ${redeeming && 'disabled'}`}>Redeem Code</button>
            {error && <div className="text-danger">
                Something went wrong: <br/>
                {error.message}
              </div>}
          </form>
        </p>}
      {present(promoCode) && promoCode.length >= 6 && !isValid &&
        <p>
          <strong className="text-danger">Invalid promo code</strong>
          <br/>
          Please check the code and try again.
        </p>}
    </div>
  </div>
}
