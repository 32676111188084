import { ProfileForm } from "../../components/forms/profileForm";
import { useAppSelector } from "../../hooks/reduxToolkit";
import { useLoadProfileFromServer } from "../../hooks/useLoadProfileFromServer";
import { RequireAuthProvided } from "../../wrappers/requireAuth";
import { useUpdateProfile } from "../../hooks/useUpdateProfile";

export interface OnboardingFillProfileProps extends RequireAuthProvided {
  onProfileFilled?: () => Promise<void> | void
}

export function OnboardingFillProfile({user, onProfileFilled}: OnboardingFillProfileProps) {
  const profile = useAppSelector((s) => s.membership.profile)

  useLoadProfileFromServer()

  const [updateProfileState, updateProfile] = useUpdateProfile()

  return <div className="row mt-4">
    <div className="col-12 col-xl-8 offset-xl-2">
      <h1>Fill Your Profile</h1>
      <p>
        We only use this information to fill out reimbursement forms on your behalf.
      </p>
      {updateProfileState.error && <div className="alert alert-danger" role="alert">
        {updateProfileState.error.message}
      </div>}
      <ProfileForm initial={profile}
        hideSignature
        onSubmit={async (profile) => {
          await updateProfile(profile)

          if (onProfileFilled) {
            await onProfileFilled()
          }
        }} />
    </div>
  </div>
}
