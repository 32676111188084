import { isDeleted, isNotDeleted } from "../../types/supabase"

export function replaceOrInsert<T extends { id: string, updated_at: string, deleted_at?: string | null }>(arr: T[], replacement: T): void {
  const i = arr.findIndex((t) => t.id === replacement.id)

  if (i < 0) {
    if (isNotDeleted(replacement)) {
      console.debug('replaceOrInsert: insert', replacement.id)
      // Add the record that we're missing
      arr.push(replacement)
    }
    return
  }

  if (arr[i].updated_at <= replacement.updated_at) {

    if (isDeleted(replacement)) {
      // Remove the deleted record
      console.debug('replaceOrInsert: delete', replacement.id)
      arr.splice(i, 1)
    } else {
      // Replace the record with the updated one
      console.debug('replaceOrInsert: update', replacement.id)
      arr.splice(i, 1, replacement)
    }
  }
}
