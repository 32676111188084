import { bySubmittedAtAsc } from "../../../lib/util/sort";
import { SubmittedExpense, assertIsSubmittedExpense } from "../expensesSlice";
import { RootState } from "../store";
import { SubmissionModel, isSubmitted } from "../submissionsSlice";

/**
 * This selector takes a submitted submission and determines whether any of that submission's
 * submitted expenses need to be repaid.
 *
 * This happens when some expenses are submitted to the HRA and then later the user submits the same
 * expenses to CHM.  The user needs to repay the HRA for the expenses that were already submitted.
 */
export function selectExpensesNeedingRepaymentForSubmission(
  submission: SubmissionModel
): (s: RootState) => SubmittedExpense[]
{
  if (!isSubmitted(submission)) { return () => [] }
  if (!submission.expense_ids || submission.expense_ids.length === 0) { return () => [] }

  return (s: RootState) => {
    const allSubmissionsForIncident = s.submissions.submissions.filter((s) => s.incident_id === submission.incident_id)
    const sortedSubmittedSubmissions = allSubmissionsForIncident.filter(isSubmitted).sort(bySubmittedAtAsc)

    const laterSubmissions = sortedSubmittedSubmissions.slice(
      sortedSubmittedSubmissions.findIndex((x) => x.id === submission.id) + 1)

    const expenseIDsNeedingRepayment = submission.expense_ids?.filter((expense_id: string) => {
      // If this submission is an HRA submission,
      // the expense needs repayment if a later submitted submission also includes this expense ID
      if (submission.submission_type === 'HRA') {
        return laterSubmissions.find((s) => s.expense_ids?.includes(expense_id))
      }
      
      // If this submission is a CHM or CHM-Addon,
      // the expense needs repayment if it was paid with the HRA card
      if (submission.submission_type === 'CHM' || submission.submission_type === 'CHM-addon') {
        const expense = s.expenses.expenses.find((e) => e.id === expense_id)
        if (!expense) { return false }
        return expense.paid_with_hra
      }
      
      return false
    })

    const needingRepayment: SubmittedExpense[] = []
    for (const id of expenseIDsNeedingRepayment) {
      const expense = s.expenses.expenses.find((e) => e.id === id)
      if (expense) {
        assertIsSubmittedExpense(expense)
        needingRepayment.push(expense)
      }
    }
    return needingRepayment
  }
}
