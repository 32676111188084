import React from 'react'
import { NotFound } from '../screens/notFound'
import { useParams } from 'react-router'
import { useAppSelector } from '../hooks/reduxToolkit'
import { SubmissionById, selectSubmissionById } from '../reduxToolkit/selectors/submissionById'
import { present } from '../../lib/util/present'

export interface RequireSubmissionProvided {
  submission: SubmissionById
}

export function requireSubmission<TProps extends React.PropsWithChildren<object>>(
  Component: React.ComponentType<RequireSubmissionProvided & TProps>
): React.ComponentType<TProps> {

  return function SubmissionRequired(props: TProps) {
    let params = useParams();
    const incidentId = params['incidentId']

    const submissionId = params['submissionId'] || params['id']
    if (!submissionId) { return <NotFound /> }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const submission = useAppSelector(selectSubmissionById(submissionId))
    if (!submission) { return <NotFound /> }

    // If this submission is nested under the wrong incident in the route tree
    if (present(incidentId) && submission.incident_id !== incidentId) {
      return <NotFound />
    }

    return <Component submission={submission} {...props} />
  }
}
