import { SubmissionData2024CHM } from "../../../app/reduxToolkit/submissionsSlice";
import { fillPdf } from "../../pdf/fillPdf";
import { writeSignature } from "../../pdf/writeSignature";
import { calculateAgeAsString } from "../../util/calculateAge";
import { present } from "../../util/present";
import { splitDateParts } from "../../util/splitDateParts";
import { stripLineBreaks } from "../../util/stripLineBreaks";

const fields = [
  "Patient Name",
  "Member Number",
  "patient_dob_month_2",
  "patient_dob_day_2",
  "patient_dob_year_2",
  "Last four of SSN",
  "Address",
  "Phone Number",
  "consent_yes",
  "consent_no",
  "Signature of patient or authorized representative",
  "Print name of patient",
  "Authorized representatives relationship to patient",
  "Print name of authorized representative",
  "todays_date_month",
  "todays_date_day",
  "todays_date_year"
] as const

type MedicalInformationReleaseField = typeof fields[number]
type MedicalInformationReleaseData = {
  [k in MedicalInformationReleaseField]?: string | boolean | null | undefined
}

export async function fillMedicalInformationRelease2024(
  pdfBytes: ArrayBuffer,
  data: SubmissionData2024CHM['medicalInformationReleaseData']
): Promise<ArrayBuffer> {
  
  const {
    day: patientDobDay,
    month: patientDobMonth,
    year: patientDobYear
  } = splitDateParts(data.patientDOB)
  
  const {
    day: todaysDateDay,
    month: todaysDateMonth,
    year: todaysDateYear
  } = splitDateParts(data.date)
  
  const formData: MedicalInformationReleaseData = {
    "Member Number": data.memberNumber,
    "Patient Name": data.patientName,
    patient_dob_day_2: patientDobDay,
    patient_dob_month_2: patientDobMonth,
    patient_dob_year_2: patientDobYear,
    "Last four of SSN": data.ssnLast4,
    "Address": data.address ? stripLineBreaks(data.address) : null,
    "Phone Number": data.phoneNumber,
    
    consent_yes: data.consent === 'consent' ? data.initialsData : null,
    consent_no: data.consent === 'do-not-consent' ? data.initialsData : null,
    
    "Print name of patient": data.printedNameOfPatient,
    "Authorized representatives relationship to patient": data.authorizedRepresentativeRelationship,
    "Print name of authorized representative": data.printedNameOfAuthorizedRepresentative,
    "todays_date_month": todaysDateMonth,
    "todays_date_day": todaysDateDay,
    "todays_date_year": todaysDateYear
  }

  pdfBytes = await fillPdf(pdfBytes, formData);
  
  if (present(data.signatureData)) {
    pdfBytes = await writeSignature(pdfBytes, {
      formField: "Signature of patient or authorized representative",
      signature: data.signatureData
    })
  }
  
  return pdfBytes
}
