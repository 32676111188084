import { isSubmittedExpense } from "../reduxToolkit/expensesSlice";
import { RootState } from "../reduxToolkit/store";
import { PendingSubmissionModel, UnsavedSubmissionModel, isSubmitted } from "../reduxToolkit/submissionsSlice";
import { useAppSelector } from "./reduxToolkit";

export function usePendingExpenses(submission: PendingSubmissionModel | UnsavedSubmissionModel) {
  return useAppSelector(selectPendingExpenses(submission))
}

export function selectPendingExpenses(submission: PendingSubmissionModel | UnsavedSubmissionModel) {
  return (s: RootState) => {
    if (!submission) { return [] }

    let pendingExpenses = s.expenses.expenses
      .filter((e) =>
        e.incident_id === submission.incident_id)
      .filter((e) => !isSubmittedExpense(e))

    if (submission.submission_type === 'CHM') {
      // If we're submitting to CHM (not an add-on), we need to roll up all expenses that were last submitted
      // to one of these prior submissions, as now we'll get them reimbursed via CHM and repay the previous
      // HRA submissions.
      const priorSubmissionIDs = s.submissions.submissions
        .filter((s) => s.incident_id === submission.incident_id)
        .filter(isSubmitted)
        .map((s) => s.id)
      if (priorSubmissionIDs.length > 0) {
        pendingExpenses.push(...s.expenses.expenses
          .filter(isSubmittedExpense)
          .filter((e) => priorSubmissionIDs.includes(e.submission_id)))
      }
    } else if (submission.submission_type === 'HRA') {
      // If we're submitting to the HRA, we need to exclude expenses that were already submitted to the HRA via the card.
      pendingExpenses = pendingExpenses.filter((e) => !e.paid_with_hra)
    }

    return pendingExpenses
  }
}
