import { selectPendingExpenses } from "../../app/hooks/usePendingExpenses"
import { getOrBuildPendingSubmissionModel } from "../../app/hooks/usePendingSubmission"
import { isCompleteExpense } from "../../app/reduxToolkit/expensesSlice"
import { IncidentModel } from "../../app/reduxToolkit/incidentsSlice"
import { RootState } from "../../app/reduxToolkit/store"
import { TodoDependency, VirtualToDo } from "./types"
import { present } from "../util/present"
import { calculateDueDate } from "../rules/2023"
import { formatCurrency } from "../formatCurrency"

export function * createToDosForIncident(s: RootState, incident: IncidentModel): Generator<VirtualToDo> {
  const key = `incident/${incident.id}/submit`
  const incidentExpenses = s.expenses.expenses.filter((e) => e.incident_id === incident.id)
  const incidentSubmissions = s.submissions.submissions.filter((s) => s.incident_id === incident.id)

  // If there are any expenses, this incident needs a TODO.
  if (incidentExpenses.length > 0) {
    const pendingSubmission = getOrBuildPendingSubmissionModel({
      incident,
      incidentSubmissions,
      incidentExpenses
    })
    if (!pendingSubmission) { return null }

    const pendingExpenses = selectPendingExpenses(pendingSubmission)(s)
    const complete = pendingExpenses.length === 0
    if (!complete) {
      const incompleteExpenses = pendingExpenses.filter((e) => !isCompleteExpense(e))
      const totalCents = pendingExpenses.filter(isCompleteExpense).reduce((sum, expense) => {
        return sum + Math.round((parseFloat(expense.paidAmount) * 100))
      }, 0)
      const total = totalCents / 100

      if (total > 0) {
        const earliestExpenseDate = pendingExpenses.map((e) => e.date).filter(present).sort()[0]
        const dueDate = calculateDueDate(earliestExpenseDate, {
          submissionType: pendingSubmission.submission_type
        })

        yield {
          key,
          title: `Submit '${incident.description}' to get ${formatCurrency(total)} reimbursement`,
          dueDate,
          dependsOn: [
            ...incompleteExpenses.map<TodoDependency>((e) => (
              {
                key: `expense/${e.id}/fill`,
                record_id: e.id,
                record_type: 'expense'
              }
            )),
            ...pendingExpenses.map<TodoDependency | null>((e) => {
              // do we need "receipt" or "itemized bill"?
              let todoType: string | null = null
              // If the submission is going to CHM or CHM-Addon, we need an itemized bill.
              if (pendingSubmission.submission_type === 'CHM' || pendingSubmission.submission_type === 'CHM-addon') {
                todoType = 'itemized-bill'
              }
              // If the submission is going to the HRA, AND this expense was a personal expense, we need a receipt.
              else if (pendingSubmission.submission_type === 'HRA' && !e.paid_with_hra) {
                todoType = 'receipt'
              }
              // Otherwise we don't need a TODO here.
              else { return null }
              
              return {
                key: `expense/${e.id}/${todoType}`,
                record_id: e.id,
                record_type: 'expense'
              }
            }).filter(present)
          ],
          record_id: incident.id,
          record_type: 'incident',
          todo_type: 'submit',
          action: {
            type: 'navigate',
            route: `/incidents/${incident.id}`
          }
        }
      }
    }
  }
}
