import { AppSupabaseClient, BlobInsert, BlobRow } from '../../types/supabase';
import { generateBase36Key } from '../util/generateRandomKey';
import { hashFileSha1 } from '../util/hashFileSha1';

export async function createBlob(
  file: File,
  metadata: {
    membership_id: string
  },
  dependencies: {
    client: AppSupabaseClient
  }
): Promise<BlobRow> {

  const key = generateBase36Key()
  const blob: BlobInsert = {
    key,
    membership_id: metadata.membership_id,
    byte_size: file.size,
    content_type: file.type,
    file_name: file.name,
    file_full_path: `/${metadata.membership_id}/blobs/${key}`,
    sha1: await hashFileSha1(file),
  }

  // If we can insert the blob, then we can upload the file.
  const { error: insertError, data: rows } = await dependencies.client
    .from('blobs')
    .insert(blob)
    .select('*')
  if (insertError) {
    throw insertError
  }
  const insertedBlob = Array.isArray(rows) ? rows[0] : rows

  const { error: uploadError } = await dependencies.client.storage
    .from('wcc-hra-calculator-private')
    .upload(blob.file_full_path, file)
  if (uploadError) {
    // Attempt to delete the blob if possible.
    dependencies.client.from('blobs').delete().eq('key', insertedBlob?.key)

    throw uploadError
  }

  return insertedBlob
}
