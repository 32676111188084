import { formatDateInTimeZone } from "../../../lib/formatDateInTimeZone"
import { Patient } from "../../reduxToolkit/dependentsSlice"
import { DropdownSelect, DropdownSelectProps } from "../dropdownSelect"
import { useNavigate } from "react-router"

export type PatientWithId = Patient & { id: string }

export function addIdToPatient(p: Patient): PatientWithId {
  return {
    ...p,
    id: `${p.full_name}_${p.date_of_birth}`
  }
}

export interface PatientSelectProps extends Omit<DropdownSelectProps<PatientWithId>, 'renderOption'> {
  renderOption?: (patient: PatientWithId) => React.ReactNode
}

export function PatientSelect({
  options,
  renderOption,
  additionalOptions,
  onChange,
  ...props
}: PatientSelectProps) {

  const navigate = useNavigate()

  // Counts if any dependents have the same name, ex. John Smith & John Smith Jr.
  // Used to determine if we should also show the patient DOB
  const patientNameCount = options.reduce((h, p) => {
    h[p.full_name] = (h[p.full_name] || 0) + 1
    return h
  }, {} as { [name: string]: number })

  if (!renderOption) {
    renderOption = (p) => {
      const showDOB = patientNameCount[p.full_name] > 1
      return `${p.full_name}${showDOB ? ` - ${formatDateInTimeZone(p.date_of_birth, { format: "MMM ''yy" })}` : ''}`
    }
  }

  return <DropdownSelect
      {...props}
      options={options}
      renderOption={renderOption}
      additionalOptions={additionalOptions ||
          [
            {
              key: '!add',
              label: <><i className='material-icons'>add</i>&nbsp;New Dependent</>,
            }
          ]}
      onChange={(e) => {
        if (e.value && 'key' in e.value) {
          switch (e.value.key) {
            case '!add':
              e.preventDefault();
              navigate('/dependents')
              return
          }
        }
        onChange?.(e)
      }}>
  </DropdownSelect>
}
