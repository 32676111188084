import { useEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from 'uuid';
import { ExpenseInsert, ExpenseRow } from "../../../types/supabase"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit"
import { present } from "../../../lib/util/present"
import { selectPatients } from "../../reduxToolkit/selectors/dependents"
import { PatientSelect, addIdToPatient } from "../formComponents/patientSelect"
import { NotNull } from "../../../types/util"
import { ExpenseModel, ExpenseUpdatePayload, isTextractJobFailed, isTextractPending, updateExpense } from "../../reduxToolkit/expensesSlice"
import { parseDollarAmount } from '../../../lib/util/parse';
import uniq from 'lodash/uniq';
import { assert } from "../../../lib/util/assert"
import { IncidentInsertPayload, addIncident, updateIncident } from "../../reduxToolkit/incidentsSlice";
import { IncidentInputGroup, IncidentInputGroupValue } from "../formComponents/incidentInputGroup";
import { Tooltip } from "../tooltip";

import './editExpenseRowForm.scss'
import { useFeature } from "../../providers/featureFlags";

export type EditExpenseOnUpdatedFn = (expense: ExpenseUpdatePayload, incident?: IncidentInsertPayload | null) => void

export type EditExpenseRowFormProps = {
  expense: ExpenseModel

  disabledFields?: Array<keyof ExpenseModel>

  onUpdated?: EditExpenseOnUpdatedFn

  validateOnMount?: boolean
}

export function EditExpenseRowForm({
  onUpdated,
  expense,
  disabledFields,
  validateOnMount
}: EditExpenseRowFormProps) {
  const dispatch = useAppDispatch()
  const userId = useAppSelector((s) => s.membership.userId)
  const patients = useAppSelector(selectPatients).map(addIdToPatient)
  const allProviders = useAppSelector((s) => uniq(s.expenses.expenses.map((e) => e.provider).filter(present)))
  
  const paidWithHraEnabled = useFeature('paid_with_hra')

  const [row, setRow] = useState<ExpenseModel>({ ...expense })
  const [wasValidated, setWasValidated] = useState(false)

  const formRef = useRef<HTMLFormElement>(null)
  useEffect(() => {
    if (!validateOnMount) { return }
    if (!formRef.current) { return }
    formRef.current.reportValidity()
    setWasValidated(true)
  }, [validateOnMount])

  // Update the row if the underlying expense changes, e.g. due to Textract completing
  useEffect(() => {
    setRow((r) => {
      const newRow: ExpenseModel = {...r}
      for (const [keyStr, value] of Object.entries(expense)) {
        const key = keyStr as keyof ExpenseModel

        // Change the form value only if the user hasn't set it
        // i.e. the current value is "undefined"
        if (
          (value != null && typeof value != 'undefined') &&
            (newRow[key] == null || typeof newRow[key] == 'undefined')
        ) {
          console.debug('setting', key, 'to', value, 'from', newRow[key as keyof ExpenseModel]);
          (newRow as any)[key] = value
        }
      }
      return newRow
    })
    // The expense version is described by its ID and updated-at timestamp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expense.updated_at])
  
  const initialIncident = useAppSelector((s) => s.incidents.incidents.find((i) => i.id === row.incident_id))
  const [selectedIncident, setSelectedIncident] = useState<IncidentInputGroupValue | null | undefined>(initialIncident)

  const doUpdate = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!formRef.current?.reportValidity()) {
      setWasValidated(true)
      return
    }
    if (!isExpenseFilled(row)) { throw new Error('Form not filled') }

    let incidentId: string | null | undefined = selectedIncident && selectedIncident.id
    let addIncidentAction: ReturnType<typeof addIncident> | undefined = undefined
    const now = new Date().toISOString()

    const newIncident = selectedIncident && !present(selectedIncident.id)
    if (newIncident) {
      assert(selectedIncident.description)

      incidentId = uuidv4()

      addIncidentAction = dispatch(addIncident({
        id: incidentId,
        updated_at: now,
        created_at: now,

        membership_id: expense.membership_id,
        created_by_user_id: userId,
        start_date: row.date,
        patient_dob: row.patient_dob,
        patient_name: row.patient_name,
        description: selectedIncident.description,
      }))
    } else {
      const incidentDate = selectedIncident && 'start_date' in selectedIncident && selectedIncident.start_date
      if (incidentDate && row.date < incidentDate) {
        // This expense is before the incident, so we need to update the incident's start date
        dispatch(updateIncident({
          id: selectedIncident.id,
          updated_at: now,
          start_date: row.date
        }))
      }
    }

    const updateExpenseAction = dispatch(updateExpense({
      ...row,
      incident_id: incidentId,
      updated_at: now
    }))

    if (onUpdated) {
      onUpdated(updateExpenseAction.payload, addIncidentAction?.payload ?? null)
    }
  }

  const selectedPatient = present(row.patient_name) && present(row.patient_dob) &&
    patients.find((p) => p.full_name === row.patient_name && p.date_of_birth === row.patient_dob)

  return <form
    ref={formRef}
    className={`edit-expense-form ${wasValidated && 'was-validated'}`}>
    <div className="input-group has-validation">
      <span className='input-group-text'>Date</span>
      <input name="datepic" placeholder="DateRange" type="date"
        className={`form-control`}
        required
        disabled={disabledFields?.includes('date')}
        value={row.date || ''}
        onChange={(e) => { setRow({...row, date: e.target.value }) }}></input>
    </div>
    <div className='input-group has-validation'>
      <span className='input-group-text'>Provider</span>

      <datalist id="providers">
          {allProviders.map((p) => <option key={p} value={p} />)}
      </datalist>

      <input type='text'
        className={`form-control`}
        required
        disabled={disabledFields?.includes('provider')}
        value={row.provider|| ''}
        list='providers'
        onChange={(e) =>  setRow({...row, provider: e.target.value})}></input>
    </div>
    <div className='input-group has-validation'>
      <span className='input-group-text'>Patient</span>
      {patients && <PatientSelect
        className={`form-select`}
        options={patients}
        value={selectedPatient}
        disabled={disabledFields?.includes('patient_name') && disabledFields?.includes('patient_dob')}
        required
        onChange={({value}) => {
          if (!value) {
            setRow({
              ...row,
              patient_name: undefined,
              patient_dob: undefined,
              incident_id: undefined
            })
          } else if ('id' in value) {
            setRow({
              ...row,
              patient_name: value.full_name,
              patient_dob: value.date_of_birth,
              incident_id: null
            })
          }
        }} />}
    </div>
    <div className='input-group has-validation'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>Pre-Discount Amt</span>
        <span className='d-inline d-md-none'>Pre-Discount</span>
      </label>
      <input type='text'
        className={`form-control`}
        value={`$${row.listedAmount || ''}`}
        pattern='\$\d+\.?\d*|\.\d+'
        required
        disabled={disabledFields?.includes('listedAmount')}
        onChange={(e) => {
          const listedAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, listedAmount})
        }}></input>
    </div>
    <div className='input-group has-validation'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>Amount You Paid</span>
        <span className='d-inline d-md-none'>Paid</span>
      </label>
      <input type='text'
        className={`form-control`}
        value={`$${row.paidAmount || ''}`}
        pattern='\$\d+\.?\d*|\.\d+'
        required
        disabled={disabledFields?.includes('paidAmount')}
        onChange={(e) => {
          const paidAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, paidAmount})
        }}></input>
    </div>
    {paidWithHraEnabled &&
      <div className='input-group has-validation'>
        <div className='edit-expense-form__form-check-wrapper'>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio'
              name='paid_with' id='paid_with_hra' value='hra'
              checked={row.paid_with_hra === true}
              onChange={(e) => {
                if (e.target.checked) {
                  setRow({...row, paid_with_hra: true})
                }
              }}></input>
            <label className="form-check-label" htmlFor="paid_with_hra">HRA card</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio'
              name='paid_with' id='paid_with_personal' value='personal'
              checked={row.paid_with_hra === false}
              onChange={(e) => {
                if (e.target.checked) {
                  setRow({...row, paid_with_hra: false})
                }
              }}></input>
            <label className="form-check-label" htmlFor="paid_with_personal">Personal</label>
          </div>
        </div>

        <div className="invalid-feedback">
          Indicate whether you paid this expense with your HRA credit card
          or personal funds.
        </div>
      </div>}

    <div className="col-12 mt-2">
      <IncidentInputGroup
        allowNewIncident={true}
        required
        selectedPatient={selectedPatient}
        value={selectedIncident}
        onChange={setSelectedIncident} />
    </div>

    <div className="col-12 d-flex mt-2">
      <button type='submit'
          className={`btn btn-primary`}
          onClick={doUpdate}>
          Update
      </button>
      
      <div className="ms-auto">
        {isTextractPending(expense) ?
          isTextractJobFailed(expense) ?
            <Tooltip tooltip={"Unable to automatically extract expense information"}>
              <i className="material-icons text-danger">warning</i>
            </Tooltip> :
            <Tooltip tooltip="Scanning expense to extract information.  This can sometimes take up to 10 minutes.  You can enter the information manually and it wont be overwritten.">
              <i className="material-icons flicker">sensors</i>
            </Tooltip> :
          null
        }
      </div>
    </div>
  </form>
}

function isExpenseFilled(
  row: Partial<ExpenseInsert>
): row is NotNull<ExpenseInsert, 'date' | 'provider' | 'listedAmount' | 'paidAmount' | 'patient_name' | 'patient_dob'> {

  if(row.date && row.provider && row.listedAmount && row.paidAmount &&
      row.patient_name && row.patient_dob) {
    return true
  }
  return false
}
