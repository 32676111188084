import { present } from "async-toolbox"
import { cloneDeep } from "lodash"
import { useMemo, useState, useCallback } from "react"
import { SubmissionData2024CHM } from "../../../../../reduxToolkit/submissionsSlice"
import { FormStepProps } from "../submitToChm2024"
import { Tooltip } from "../../../../../components/tooltip"
import { ValidationError, validateMedicalInformationReleaseData2024 } from "../validation"
import { TextInputGroup } from "./components/textInputGroup"
import { FloatingActionButtons } from "./components/floatingActionButtons"
import { SignatureCanvas } from "../../../../../components/signatureCanvas"


export function MedicalInformationRelease2024({ data: upstreamData, updateData, advance, home }: FormStepProps) {
  
  const originalData = useMemo(() => {
    return cloneDeep(upstreamData)
  }, [])
  const [data, setData] = useState(originalData.medicalInformationReleaseData)

  const errors = useMemo<ValidationError[]>(() => {
    return validateMedicalInformationReleaseData2024(data)
  }, [data])

  const changeData = useCallback(<TKey extends keyof SubmissionData2024CHM['medicalInformationReleaseData']>(key: TKey, value: SubmissionData2024CHM['medicalInformationReleaseData'][TKey]) => {
    setData((data) => {
      if (value === data[key]) { return data }

      const newData = {
        ...data,
        [key]: value
      }
      updateData({
        ...originalData,
        medicalInformationReleaseData: newData
      })
      return newData
    })
  }, [updateData, originalData])
  
  const patientAndIllnessErrorCount = errors.filter((error) => ['patientName', 'patientDOB', 'ssnLast4', 'address', 'phoneNumber'].includes(error.field)).length
  const consentErrorCount = errors.filter((error) => ['consent'].includes(error.field)).length
  const importantNotesErrorCount = errors.filter((error) => ['signatureData', 'printedNameOfPatient', 'date'].includes(error.field)).length

  return <div className="submitToChm2024-MedicalInformationRelease">
    <FloatingActionButtons home={home} advance={advance} errors={errors} />
    
    <form onSubmit={((evt) => { evt?.preventDefault(); advance() })}>
      <div className="accordion" id='medical-information-release'>    
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button"
                data-bs-toggle='collapse' data-bs-target='#patient-and-illness'
                aria-expanded="false" aria-controls="patient-and-illness">
              1. Patient and illness information {patientAndIllnessErrorCount > 0 && <span className="badge bg-danger">{patientAndIllnessErrorCount}</span>}
            </button>
          </h2>
          
          <div className="accordion-collapse collapse" id='patient-and-illness' data-bs-parent='medical-information-release'>
            <div className="accordion-body">
              <TextInputGroup
                field="patientName"
                label="Patient Name:"
                value={data.patientName}
                changeData={changeData}
                validationError={errors.find((error) => error.field === 'patientName')} />

              <div className="input-group">
                <label className="input-group-text">Member Number:</label>
                <div className={`form-control disabled`}>
                <Tooltip tooltip="Your CHM member number is set in your profile.  If this is wrong please contact us to correct it.">
                  {data.memberNumber}
                </Tooltip>
                </div>
              </div>

              <TextInputGroup
                field="patientDOB"
                label="Patient DOB:"
                value={data.patientDOB}
                inputType="date"
                changeData={changeData}
                validationError={errors.find((error) => error.field === 'patientDOB')} />
              
              <TextInputGroup
                field="ssnLast4"
                label="Last 4 of SSN:"
                value={data.ssnLast4}
                changeData={changeData}
                validationError={errors.find((error) => error.field === 'ssnLast4')} />
              
              <TextInputGroup
                field="address"
                label="Address:"
                value={data.address}
                changeData={changeData}
                validationError={errors.find((error) => error.field === 'address')} />
              
              <TextInputGroup
                field="phoneNumber"
                label="Phone:"
                value={data.phoneNumber}
                changeData={changeData}
                validationError={errors.find((error) => error.field === 'phoneNumber')} />
            </div>
          </div>
        </div>
        
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
                data-bs-toggle='collapse' data-bs-target='#consent-to-release'
                aria-expanded="true" aria-controls="consent-to-release">
              2. Consent to Release {consentErrorCount > 0 && <span className="badge bg-danger">{consentErrorCount}</span>}
            </button>
          </h2>
          
          <div className="accordion-collapse collapse show" id='consent-to-release' data-bs-parent='medical-information-release'>
            <div className="accordion-body">
              <p>
                I understand that Christian Healthcare Ministries is a non-profit medical cost sharing organization that coordinates assistance for
                its members’ eligible medical bills. <b>Christian Healthcare Ministries is not an insurance company, nor is it offered through an
                insurance company.</b>
              </p>
              <p>
                I hereby authorize any medical practitioner, hospital, health facility, insurance company, or any other person or entity that has
                medical records or knowledge of the medical records of the undersigned and/or the dependents listed herein to disclose my
                protected health information to Christian Healthcare Ministries for the purpose of facilitating the eligibility and sharing process
                by Christian Healthcare Ministries and also negotiating medical bills on the undersigned’s or dependent’s behalf.
              </p>
              <p>
                I further authorize Christian Healthcare Ministries to discuss any health information related to my records described in this
                authorization with healthcare providers, healthcare facilities, health plans, or any other agency involved in my healthcare or
                payment for healthcare.
              </p>
              <p>
                <b>Please initial one of the options below:</b>
              </p>
              
              <div className="input-group consent-initials" id={'consent'}>
                <input type="text" className="form-control" key="consent" 
                  value={data.consent === 'consent' ? (data.initialsData || '') : ''}
                  onFocus={
                    useCallback((evt: React.FocusEvent<HTMLInputElement>) => {
                      changeData('consent', 'consent')
                    }, [changeData])
                  }
                  onChange={
                    useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                      const value = present(evt.target.value) ? evt.target.value : null
                      changeData('initialsData', value)
                    }, [changeData])
                  }/>
                <label className="input-group-text">I Consent</label>
              </div>

              <div className="input-group consent-initials">
                <input type="text" className="form-control" key="do-not-consent"
                  value={data.consent === 'do-not-consent' ? (data.initialsData || '') : ''}
                  onFocus={
                    useCallback((evt: React.FocusEvent<HTMLInputElement>) => {
                      changeData('consent', 'do-not-consent')
                    }, [changeData])
                  }
                  onChange={
                    useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                      const value = present(evt.target.value) ? evt.target.value : null
                      changeData('initialsData', value)
                    }, [changeData])
                  }/>
                <label className="input-group-text">I Do Not Consent</label>
              </div>
              
              {data.consent === 'consent' && <p className="consent-disclaimer">
                I consent that all medical records be disclosed (complete health record plus records regarding all bills, billing
                codes, diagnosis codes, and other billing information). This includes information on communicable diseases
                (including HIV/AIDS), alcohol/drug abuse treatment, and mental health records and treatment.
              </p>}
              {data.consent === 'do-not-consent' && <p className="consent-disclaimer">
                I do not consent that my medical records be disclosed. <br/>
                <b>Important:</b> CHM and your healthcare providers must
                have your consent to legally discuss discounts on your behalf.
              </p>}

            </div>
          </div>
        </div>
        
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
                data-bs-toggle='collapse' data-bs-target='#important-notes'
                aria-expanded="true" aria-controls="important-notes">
              3. Important notes {importantNotesErrorCount > 0 && <span className="badge bg-danger">{importantNotesErrorCount}</span>}
            </button>
          </h2>
          
          
          <div className="accordion-collapse collapse show" id='consent-to-release' data-bs-parent='medical-information-release'>
            <div className="accordion-body">
              <p>
                By signing below, I understand that:
              </p>
              <p>
                <ul>
                  <li>
                    this authorization shall expire upon the expiration of one (1) year, or until revoked by me in writing, whichever comes first.
                  </li>
                  <li>
                    treatment and eligibility for sharing is not conditioned on my failure to execute this authorization.
                  </li>
                  <li>
                    this authorization is voluntary and that I may revoke the authorization in writing addressed to
                    127 Hazelwood Ave, Barberton, OH 44203.
                  </li>
                  <li>
                    this authorization may not be revoked where Christian Healthcare Ministries has already reasonably acted in reliance
                    upon this authorization.
                  </li>
                  <li>
                    the information used or disclosed pursuant to this authorization may be subject to re-disclosure by the recipient
                    and no longer protected by federal or state law.
                  </li>
                  <li>
                    a copy of this form, including a facsimile, may be used in place of the original.
                  </li>
                </ul>
              </p>
              
              <span>
                Signature of patient or authorized representative:
              </span>
              <SignatureCanvas
                id="signatureData"
                data={useMemo(() => data?.signatureData, [])}
                onChange={useCallback((sigData: string | undefined) => {
                  changeData('signatureData', sigData)
                }, [changeData])}/>
                
                <small><i>Must be signed by patient if patient is 18 years of age or older</i></small><br/>
                <small><i>Authorized representative’s signature is required if patient is under the age of 18 or is incapable of signing for themselves.</i></small>
                
              <TextInputGroup
                field='printedNameOfPatient'
                label="Patient:"
                value={data.printedNameOfPatient}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'printedNameOfPatient')} />

              <TextInputGroup
                field='authorizedRepresentativeRelationship'
                label="Relationship to patient:"
                value={data.authorizedRepresentativeRelationship}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'authorizedRepresentativeRelationship')} />

              <TextInputGroup
                field='date'
                label="Today's date:"
                value={data.date}
                inputType="date"
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'date')} />
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
}
