import React, { useEffect, useRef, useState } from 'react'
import { BlobRow } from '../../types/supabase'
import { ChonkyActions, FileArray, FileBrowser, FileBrowserHandle, FileContextMenu, FileData, FileNavbar, FileToolbar, FileList } from 'chonky'
import path from 'path-browserify'
import { DownloadAllAsZip, DownloadSelection } from './supabaseFileHandler/actions'
import { useBlobFileActionHandler } from './blobFileBrowser/useBlobFileActionHandler'
import { present } from '../../lib/util/present'
import { byUpdatedAtAscending } from '../../lib/util/sort'

interface BlobFileBrowserProps {
  files: {
    [folderPath: string]: BlobRow[]
  }

  rootFolderName?: string

  /**
   * If provided, controls the folder that is being shown.
   * You should use "onFolderChange" to update this value.
  */
  folder?: string

  settings?: {
    hideNavigation?: boolean
    initialView?: keyof typeof viewActions
  }

  onFolderChange?: (folder: string) => void
}

const viewActions = {
  'list': ChonkyActions.EnableListView,
  'grid': ChonkyActions.EnableGridView,
  'compact': ChonkyActions.EnableCompactView,
} as const

export function BlobFileBrowser(props: BlobFileBrowserProps) {
  const { settings, rootFolderName } = props
  const initialView = settings?.initialView || 'grid'

  const fileBrowserHandle = useRef<FileBrowserHandle>(null)

  const [stateFolder, setFolder] = useState(props.folder || '/')
  const folder = props.folder || stateFolder
  const [view, setView] = useState<keyof typeof viewActions>(initialView)

  // Initialize the view on mount
  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (initialized) { return }
    if (!fileBrowserHandle.current) { return }

    fileBrowserHandle.current.requestFileAction(viewActions[initialView], {})
    fileBrowserHandle.current.requestFileAction(ChonkyActions.ToggleShowFoldersFirst, {})

    setInitialized(true)
  }, [initialized, initialView])

  const [fileActionState, onFileAction] = useBlobFileActionHandler({
    ...props,
    baseFolder: folder,
    baseFolderName: folder === '/' ? rootFolderName : undefined,
    setFolder: (folder) => {
      setFolder(folder)
      if (props.onFolderChange) { props.onFolderChange(folder) }
    },
    setView
    })

  // calculate the navigation piece
  const folderChain: FileArray<FileData> = [
    {
      id: '/',
      name: 'Home',
      isDir: true
    }
  ]
  if (folder !== '/') {
    const paths = folder.replace(/^\//, '').split(path.sep)
    paths.forEach((part, i) => {
      const fullPath = '/' + path.join(...paths.slice(0, i), part)
      folderChain.push({
        id: fullPath,
        name: part,
        isDir: true
      })
    })
  }

  let files = view === 'list' ?
    Array.from(selectAllFilesUnderFolder(props.files, folder)) :
    Array.from(selectImmediateChildren(props.files, folder))

  return <FileBrowser ref={fileBrowserHandle}
    files={files} folderChain={folderChain}
    fileActions={[DownloadAllAsZip, DownloadSelection]}
    onFileAction={onFileAction}
  >
  {!settings?.hideNavigation && <FileNavbar />}
  <FileToolbar />
  <div className={`loading-bar ${fileActionState.acting ? 'visible' : 'invisible'}`}></div>
  <FileList />
  <FileContextMenu />
  </FileBrowser>
}

function * selectAllFilesUnderFolder(files: { [folderPath: string]: BlobRow[] }, baseFolder: string): Generator<FileData> {
  const seen = new Set<string>()
  
  const folderPaths = Object.keys(files)
  for(const folderPath of folderPaths) {
    if (!folderPath.startsWith(baseFolder)) { continue }

    if (folderPath !== baseFolder) {
      yield {
        id: folderPath,
        fileName: folderPath + '/',
        name: path.relative(baseFolder, folderPath),
        isDir: true
      }
    }

    if (files[folderPath]) {
      const sortedFiles = files[folderPath].sort(byUpdatedAtAscending)
      for (const file of sortedFiles) {
        let fullFilePath = path.join(folderPath, file.file_name)
        const originalFullFilePath = fullFilePath
        let count = 0
        while (seen.has(fullFilePath)) {
          count += 1
          fullFilePath = path.basename(originalFullFilePath, path.extname(originalFullFilePath)) + `(${count})` + path.extname(originalFullFilePath)
        }
        seen.add(fullFilePath)

        yield {
          id: fullFilePath,
          key: file.key,
          fileName: fullFilePath,
          name: path.relative(baseFolder, fullFilePath),
          isDir: false,
          size: file.byte_size,
          modDate: new Date(file.created_at),
        }
      }
    }
  }
}

function * selectImmediateChildren(files: { [folderPath: string]: BlobRow[] }, baseFolder: string): Generator<FileData> {
  const basePathParts = baseFolder.replace(/^\//, '').split(path.sep).filter(present)
  const folderPaths = Object.keys(files)

  const seen = new Set<string>()

  for(const folderPath of folderPaths) {
    if (!folderPath.startsWith(baseFolder)) { continue }

    const pathParts = folderPath.replace(/^\//, '').split(path.sep).filter(present)
    const subFolderPath = '/' + pathParts.slice(0, basePathParts.length + 1).join(path.sep)

    if (seen.has(subFolderPath)) { continue }
    seen.add(subFolderPath)

    yield {
      id: subFolderPath,
      fileName: subFolderPath + '/',
      name: path.relative(baseFolder, subFolderPath),
      isDir: true
    }
  }

  if (files[baseFolder]) {
    const sortedFiles = files[baseFolder].sort(byUpdatedAtAscending)
    for (const file of sortedFiles) {
      let fullFilePath = path.join(baseFolder, file.file_name)
      const originalFullFilePath = fullFilePath
      let count = 0
      while (seen.has(fullFilePath)) {
        count += 1
        fullFilePath = path.basename(originalFullFilePath, path.extname(originalFullFilePath)) + `(${count})` + path.extname(originalFullFilePath)
      }
      seen.add(fullFilePath)

      yield {
        id: fullFilePath,
        key: file.key,
        fileName: fullFilePath,
        name: path.relative(baseFolder, fullFilePath),
        isDir: false,
        size: file.byte_size,
        modDate: new Date(file.created_at),
      }
    }
  }
}
