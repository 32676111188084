import { useState } from "react";
import { SubmissionModel, SubmittedSubmissionModel, isSubmitted, submittedToText } from "../reduxToolkit/submissionsSlice";
import { BlobRow } from "../../types/supabase";
import { useClient } from "../providers/supabase";
import { useAppSelector } from "../hooks/reduxToolkit";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { BlobFileBrowser } from "./blobFileBrowser";
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone";
import { bySubmittedAtDescNullsFirst } from "../../lib/util/sort";
import { ToDoList } from "./todoList";
import { VirtualToDo } from "../../lib/todos/types";
import { selectToDosForSubmission } from "../reduxToolkit/selectors/todos";

import './submissionHistory.scss'

interface SubmissionHistoryProps {
  submissions: SubmissionModel[]
}

export function SubmissionHistory({ submissions }: SubmissionHistoryProps) {

  submissions = submissions.sort(bySubmittedAtDescNullsFirst)

  return <div className="submission-history">
    {submissions.filter(isSubmitted).map((submission, i) => {
      return <div className="row">
        <SubmissionHistoryRow key={submission.id} submission={submission} initiallyExpanded={false} />
      </div>
    })}
  </div>
}

function SubmissionHistoryRow({ submission, initiallyExpanded }: { submission: SubmittedSubmissionModel, initiallyExpanded?: boolean }) {
  const { id, submitted_at } = submission

  const submittedTo = submittedToText(submission)
  const incompleteTodos = useAppSelector(selectToDosForSubmission(submission.id))

  const [expanded, setExpanded] = useState(initiallyExpanded || false)

  return <div key={id} className={`submission-history__row ${expanded && 'expanded'} col-12 card`}>
    <div className="card-body">
      <div  className={`submission-history__row-header`} onClick={!expanded ? ((e) => {e.preventDefault(); e.stopPropagation(); setExpanded(true) }) : undefined}>
        <div className="card-title">
          <p>
            {submitted_at ?
              `Submitted to ${submittedTo} on ${formatDateInTimeZone(submitted_at, { format: 'MMM dd' })}` :
              'Pending'}
          </p>
          {incompleteTodos.length > 0 &&
              <i className="material-icons text-danger">warning</i>}
        </div>
      </div>

      <button className="btn btn-link submission-history__row-collapse-toggle"
            aria-expanded={expanded} aria-controls={`#submission-history-${id}`}
            onClick={(e) => {e.preventDefault(); e.stopPropagation(); setExpanded(!expanded) }}
            >
            <i className={`material-icons`}>{expanded ? 'expand_less' : 'expand_more'}</i>
        </button>

      <div className={`submission-history__row-collapse collapse ${expanded && 'show'} card-text`} id={`submission-history-${id}`}
          data-id={id}>
        <SubmissionWidget submission={submission}
          incompleteTodos={incompleteTodos}
          expanded={expanded} />
      </div>
    </div>
  </div>
}

function SubmissionWidget({ submission, incompleteTodos, expanded }: { submission: SubmissionModel, incompleteTodos: VirtualToDo[], expanded: boolean }) {
  const client = useClient()
  const expenses = useAppSelector((s) => s.expenses.expenses.filter((e) => submission.expense_ids?.includes(e.id)))
  const attachments = useAppSelector((s) => s.attachments.attachments.filter((a) => a.record_id === submission.id))
  const blobKeys = attachments.map((a) => a.blob_key)

  const [files, setFiles] = useState<{
    [folderPath: string]: BlobRow[]
  }>()
  useAsyncEffect(async () => {
    if (!expanded || files) { return }

    // fetch blobs
    const resp = await client.from('blobs')
      .select('*')
      .in('key', blobKeys)

    if (resp.error) { throw resp.error }
    setFiles({
      '/': resp.data
    })
  }, [expanded, files, blobKeys])

  const hasFiles = files && files['/'] && files['/'].length > 0

  const totalPaid = expenses.reduce((sum, expense) => sum + parseFloat(expense.paidAmount || '0'), 0)

  return <div className="submission-history__widget">
    {incompleteTodos.length > 0 &&
      <div className="submission-history__widget-todos">
        <div className="alert alert-warning">
          You have {incompleteTodos.length} incomplete {incompleteTodos.length === 1 ? 'task' : 'tasks'}.
        </div>
        <ToDoList todos={incompleteTodos} />
      </div>}
    <div className="submission-history__widget-expenses">
      <h5>Total: ${totalPaid}</h5>
      <table className="table table-sm">
        <tbody>
          {expenses.map((expense) => {
            return <tr key={expense.id} className="submission-history__widget-expenses-row">
                <td>{expense.date ? formatDateInTimeZone(expense.date, { format: 'M/d/yyyy' }) : 'Unknown Date'}</td>
                <td>${expense.paidAmount}</td>
                <td className="">
                  {expense.paid_with_hra &&
                      <span className="badge bg-secondary">HRA CARD</span>}
                </td>
              </tr>
          })}
        </tbody>
      </table>
    </div>

    {hasFiles && <div className="submission-history__widget-files">
      <h5>Files</h5>
      <BlobFileBrowser files={files}
        settings={{ initialView: 'list', hideNavigation: true }}
        />
    </div>}
  </div>
}

