import React, { useState, useEffect, useCallback } from "react"
import { iconForContentType } from "../../lib/iconForContentType"
import { AttachmentModel, deleteAttachment } from "../reduxToolkit/attachmentsSlice"
import { AppSupabaseClient, BlobRow } from "../../types/supabase"
import { useClient } from "../providers/supabase"
import { downloadFile } from "../../lib/util/downloadFile"
import { useAsyncAction } from "../hooks/useAsyncAction"
import { wait } from "async-toolbox"
import { useAppDispatch } from "../hooks/reduxToolkit"

import './attachment.scss'

interface AttachmentProps {
  attachment: AttachmentModel,
  blob?: BlobRow,

  downloadable?: boolean
  deletable?: boolean
  onDelete?: (attachment: AttachmentModel) => void
}

export function Attachment({
  attachment,
  blob,
  downloadable,
  deletable,
  onDelete
}: AttachmentProps) {
  const client = useClient()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(!blob)
  const [loadedBlob, setLoadedBlob] = useState<BlobRow | undefined>(blob)
  useEffect(() => {
    if (loadedBlob) { return }

    client
      .from('blobs')
      .select('*')
      .eq('key', attachment.blob_key)
      .maybeSingle()
      .then(
        ({ data }) => {
          if (data) {
            setLoadedBlob(data)
          }
          setLoading(false)
        },
        (error) => {
          console.error(error)
          setLoading(false)
        })
  }, [attachment.blob_key, loadedBlob, client])

  const attachmentOnDelete = useCallback((e: React.MouseEvent) => {
    if (!deletable) { return }
    e.stopPropagation();

    if (window.confirm('Are you sure you want to delete this attachment?')) {
      const now = new Date().toISOString()
      dispatch(deleteAttachment({
        id: attachment.id,
        updated_at: now,
        deleted_at: now,
      }))
      onDelete?.(attachment)
    }
  }, [attachment, deletable, onDelete, dispatch])

  const [attachmentDownloadState, attachmentOnDownload] = useAsyncAction(async (e: React.MouseEvent) => {
    if (!downloadable) { return }

    e.stopPropagation();
    if (loadedBlob) {
      await downloadBlob(loadedBlob, client)
    }
  }, [loadedBlob, downloadable, client])

  const icon = iconForContentType(loadedBlob?.content_type)
  let downloadIcon = <i className="material-icons-outlined attachment__download-icon">download</i>
  if (attachmentDownloadState.loading) {
    downloadIcon = <div className="spinner-border material-icons-outlined attachment__download-icon" role="status"></div>
  } else if (attachmentDownloadState.error) {
    downloadIcon = <i className="material-icons attachment__download-icon">error</i>
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a className={`attachment ${!loadedBlob && 'disabled'}`} aria-disabled={!loadedBlob} onClick={attachmentOnDownload}>

    { loading ?
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div> :
      <i className='material-icons-outlined'>{icon}</i>}
    <span className="attachment__name">{loadedBlob?.file_name}</span>

    {loadedBlob && downloadable && downloadIcon}
    {loadedBlob && deletable && <i className="material-icons-outlined attachment__delete-icon" onClick={attachmentOnDelete}>delete</i>}
  </a>
}

async function downloadBlob(blob: BlobRow, client: AppSupabaseClient) {
  const resp = await client.storage.from('wcc-hra-calculator-private')
    .download(blob.file_full_path)
  if (resp.error) { throw resp.error }

  downloadFile(blob.file_name, resp.data)
  await wait(1000)
}
