import { useNavigate } from "react-router";
import { ChangePasswordForm } from "../components/forms/changePasswordForm";
import { requireAuth } from "../wrappers/requireAuth";

function ChangePassword() {
  const navigate = useNavigate()

  return <div className='row mt-4'>
    <div className='col'>
      <h1>Change Password</h1>
      <p>
        Please choose a new password.
      </p>
      <ChangePasswordForm onChangePasswordSuccess={() => navigate('/')} />
    </div>
  </div>
}

export default requireAuth(ChangePassword)
