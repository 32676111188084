import { useMemo } from "react";
import { VirtualToDo } from "../../lib/todos/types";
import { useTodoCompletion } from "../hooks/todos/useTodoCompletion";

import './todoList.scss'

interface ToDoListProps {
  todos: VirtualToDo[]
}

export function ToDoList({todos}: ToDoListProps) {

  const [onClick, _, modal] = useTodoCompletion()

  // Memoize the original TODOs because as we complete them they'll disappear from the todo list.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originalTodos = useMemo(() => todos, [])
  // Any todos passed in are by definition incomplete
  const incompleteTodos = todos
  const incompleteTodoKeys = incompleteTodos.map((t) => t.key)
  // Any todos that no longer remain in the list have been completed during this session
  const completedTodos = originalTodos.filter((todo) => !incompleteTodoKeys.includes(todo.key))

  return <>
    <div className="todo-list">
      {incompleteTodos.map((todo) => <ToDoListItem
        key={todo.key}
        item={todo}
        onClick={onClick}
        />)}

      {completedTodos.map((todo) => <ToDoListItem
        key={todo.key}
        item={todo}
        complete
        />)}
    </div>

    {modal}
  </>
}

interface ToDoListItemProps {
  item: VirtualToDo

  complete?: boolean

  onClick?: (item: VirtualToDo) => any
}

export function ToDoListItem({item, complete, onClick}: ToDoListItemProps) {

  return <div className={`todo-list-item ${complete ? 'complete' : 'incomplete'}`}
      onClick={onClick ? (() => { onClick(item) }) : undefined}>
    <div className="todo-list-item__header">
      <i className="material-icons">{
        complete ? 'check_box' : 'check_box_outline_blank'
      }</i>
      <span>{item.title}</span>
    </div>
  </div>
}
