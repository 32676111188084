import type { TodoConfirm } from "../../../lib/todos/types"
import { useAppDispatch } from "../../hooks/reduxToolkit"
import type { AppAction } from "../../reduxToolkit/store"

interface ConfirmDispatchActionProps {
  confirm?: TodoConfirm,
  action: AppAction,
  onCompleted: () => void, onCancelled: () => void
}

export function ConfirmDispatchAction({ confirm, action, onCompleted, onCancelled }: ConfirmDispatchActionProps) {
  const dispatch = useAppDispatch()

  const dispatchAndComplete = () => {
    dispatch(action)
    onCompleted()
  }
  
  const title = typeof confirm == 'string' ? confirm : confirm?.title || 'Ready to work on this?'
  const body = typeof confirm == 'string' ? null : confirm?.body || null

  return <div className="row">
    <div className="col">
      <h3 style={{textAlign: 'center'}}>{title}</h3>
      {body && <p>{body}</p>}
      <div className="w-100 d-flex justify-content-center">
        <div className="btn-group btn-group-lg">
          <button type="button" className="btn btn-primary"
            onClick={dispatchAndComplete}>
              Yes
          </button>
          <button type="button" className="btn btn-outline-secondary"
            onClick={onCancelled}>
              No
          </button>
        </div>
      </div>
    </div>
  </div>
}
