import { Link } from "react-router-dom";
import React from 'react'
import { SignInWithEmailForm } from "../../components/forms/signInWithEmailForm";
import { SignInTabNav } from "./nav";

export function SignInWithEmail() {

  return <div className='row justify-content-center mt-4 sign-in sign-in__with-email'>
    <div className='col-12 col-lg-6'>
      <h1>Sign In</h1>
      <SignInTabNav />

      <SignInWithEmailForm />
      <hr />
      <div className='text-center'>
      </div>
    </div>
  </div>
}
