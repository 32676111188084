import {v4 as uuid4} from 'uuid'
import { SubmissionData2024CHM, SubmissionModel, updateSubmission } from "../../../../../../reduxToolkit/submissionsSlice";
import { useAsyncAction } from "../../../../../../hooks/useAsyncAction";
import { generateDocuments2024 } from "../../../../../../../lib/documents/2024";
import { present } from "../../../../../../../lib/util/present";
import { createBlob } from "../../../../../../../lib/supabase/createBlob";
import { useClient } from "../../../../../../providers/supabase";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/reduxToolkit";
import { createAttachment } from "../../../../../../reduxToolkit/attachmentsSlice";

export function useGenerateDocuments2024({ data, submission }: { data: SubmissionData2024CHM, submission: SubmissionModel }) {
  const client = useClient()
  const dispatch = useAppDispatch()
  const membership = useAppSelector((s) => s.membership)
  
  return useAsyncAction(async () => {
    const {
      sharingRequestForm,
      medicalInformationRelease,
      medicalBillWorksheets,
      maternityVerificationForm
    } = await generateDocuments2024(data)
    
    const files = [
      sharingRequestForm,
      medicalInformationRelease,
      ...medicalBillWorksheets,
      maternityVerificationForm
    ].filter(present)
    
    const blobs = await Promise.all(files.map(async file => {
      return await createBlob(file,
        {
          membership_id: membership.membershipId,
        },
        {
          client
        })
      }
    ))
    
    const attachments = blobs.map((blob, i) => {
      return dispatch(createAttachment({
        id: uuid4(),
        record_id: submission.id,
        table_name: 'submissions',
        blob_key: blob.key,
        membership_id: blob.membership_id,
        updated_at: blob.updated_at,
        created_at: blob.created_at,
      })).payload
    })
    
    const newData: SubmissionData2024CHM = {
      ...data,
      documentsGenerated: true
    }
    dispatch(updateSubmission({
      id: submission.id,
      updated_at: new Date().toISOString(),
      data: newData
    }))

    return {
      blobs,
      attachments
    }
  }, [data])
}
