
export function OnboardingStartTour({ onComplete }: { onComplete: () => void }) {
  
  const onStartTourClicked = () => {
    localStorage.setItem('startTour', 'true')
    onComplete()
  }
  
  return <div className="row mt-4">
    <div className="col-12 col-xl-8 offset-xl-2">
      <h1>All Set!</h1>
      <p>
        You're ready to start using HealthShare. Would you like a quick tour?
      </p>
      <div className="mt-4"></div>
      <button className="btn btn-primary" onClick={onStartTourClicked}>
        Yes, show me around!
      </button>
      <button className="btn btn-link" onClick={onComplete}>
        No thanks, I know what I'm doing.
      </button>
    </div>
  </div>
}
