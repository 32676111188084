import { AppSupabaseClient, MembershipRow, MembershipRoleRow } from "../../types/supabase"

export async function updateExistingMembership(
  client: AppSupabaseClient,
  params: {
    membershipId: string,
    memberNumber: string,
  }
): Promise<{ membership: MembershipRow, role: MembershipRoleRow }> {
  const { membershipId, memberNumber } = params
  const membershipUpdate = await client.from('memberships')
    .update({
      member_number: memberNumber
    })
    .eq('id', membershipId)
    .select('*')

  if (membershipUpdate.error) { throw membershipUpdate.error }
  if (!membershipUpdate.data || !membershipUpdate.data[0]) { throw new Error(`Unable to get membership after updating ${membershipId} to ${memberNumber}`) }
  const membership = membershipUpdate.data[0]

  const roleSelect = await client.from('membership_roles')
    .select('*')
    .eq('membership_id', membershipId)
  if (roleSelect.error) { throw roleSelect.error }
  if (!roleSelect.data || !roleSelect.data[0]) { throw new Error(`Unable to get membership_role after updating ${membership.id}`) }
  const role = roleSelect.data[0] as MembershipRoleRow

  return {
    membership,
    role
  }
}
