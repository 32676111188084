import { useMemo, useEffect } from "react"
import { useIsOnline } from "../providers/supabase"
import { SplashScreen } from "../screens/splashScreen"

const seconds = 1000


interface OnlineStatusInitializerProps {
  onComplete: () => void
}

export function OnlineStatusInitializer({
  onComplete
}: React.PropsWithChildren<OnlineStatusInitializerProps>) {
  const startedAt = useMemo(() => Date.now(), [])
  const isOnline = useIsOnline()

  // Wait up to 1.5 seconds for the app to get online.
  useEffect(() => {
    // timed out waiting for online status to settle
    if (Date.now() - startedAt > 1.5 * seconds) {
      onComplete()
      return
    }

    let timeout: any
    if (isOnline) {
      timeout = setTimeout(() => {
        onComplete()
      }, 100)
    } else {
      timeout = setTimeout(() => {
        onComplete()
      }, 1.5 * seconds)
    }

    return () => { clearTimeout(timeout) }
  }, [onComplete, startedAt, isOnline])

  return <SplashScreen />
}