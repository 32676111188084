import Toast from 'react-hot-toast';
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { present } from '../../../lib/util/present';
import { useMailchimpSignUpHandler } from "../../hooks/useMailchimpSignUpHandler"
import { useQueryOrganizationForUser } from "../../hooks/useQueryOrganizationForUser"

export function OnboardingHome({
  hasCompletedOnboarding
}: {
  hasCompletedOnboarding?: boolean | null | undefined
}) {
  const [organization, { loading, error }] = useQueryOrganizationForUser()
  const [{}, mailchimpSignUp] = useMailchimpSignUpHandler()
  const navigate = useNavigate()

  const [tosChecked, setTosChecked] = useState(false)
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false)
  const canContinue = tosChecked && privacyPolicyChecked
  
  useEffect(() => {
    
    // Try to load a saved promo code from local storage
    const savedCode = localStorage.getItem('promoCode')
    console.log('saved code', savedCode)
    if (present(savedCode)) {
      navigate('/redeemCode')
    }
  }, [navigate])
  
  if (error) { throw error }

  if (hasCompletedOnboarding) {
    return <div className="row">
      <div className="col-12 col-xl-8 offset-xl-2">
        <h1>Thanks!</h1>
        <p>You are now ready to use the tracker.</p>
        <p><Link to="/">Start tracking your expenses</Link></p>
      </div>
    </div>
  }
  
  if (loading) {
    return <div className="row">
      <div className="col-12 col-xl-8 offset-xl-2">
        <div className={`loading-bar`}></div>
      </div>
    </div>
  }

  if (!present(organization)) {
    return <div className="row">
      <div className="col-12 col-xl-8 offset-xl-2">
        <h1>Welcome!</h1>
        <p>
          Use of this app is currently by invitation only.
          If you have a trial code, <Link to="/redeemCode">you can log in here</Link>.
        </p>
        <p>
          If your organization is not yet using HealthShare, you are
          welcome to <MailchimpSignUpLink>sign up for our waiting list!</MailchimpSignUpLink>
        </p>
        <p>
          If you have any questions, please <Link to="/help">contact us</Link>.
        </p>
      </div>
    </div>
  }

  return <div className="row mt-4">
    <div className="col-12 col-xl-8 offset-xl-2">
      <h1>Welcome to the HealthShare Reimbursement Tracker!</h1>
      <p>
        Here you can:
        <ul>
          <li>Keep track of your medical expenses</li>
          <li>Get clarity on whether to submit to CHM or your HRA</li>
          <li>Get your forms filled out for you</li>
          <li>Be reminded of important dates and deadlines</li>
        </ul>
      </p>

      <h6>Let's get started!</h6>
      <p>
        <form>
          <div className="form-check" onClick={() => {
              setTosChecked(!tosChecked)
            }}>
            <label className="form-check-label">
              Read and agree to the&nbsp;
              <a href="https://app.termly.io/document/terms-of-service/eb660d7f-36de-4395-91fd-9b213ca3d849" target='_blank' rel="noreferrer">Terms and Conditions</a>
            </label>
            <input className="form-check-input" type="checkbox"
              checked={tosChecked} />
          </div>

          <div className="form-check" onClick={() => {
              setPrivacyPolicyChecked(!privacyPolicyChecked)
            }}>
            <label className="form-check-label">
              Read the&nbsp;
              <a href="https://app.termly.io/document/privacy-policy/bf079259-ff12-4a46-9167-dbb4415f71c1" target='_blank' rel="noreferrer">Privacy Policy</a>
            </label>
            <input className="form-check-input" type="checkbox"
              checked={privacyPolicyChecked} />
          </div>
        </form>
      </p>
      <p>
        <Link to="setMembership" className={`btn btn-link ${!canContinue && 'disabled'}`} aria-disabled
            onClick={() => mailchimpSignUp(['onboarding-started'])}>
          Next step: Enter your Membership Number
        </Link>
      </p>
    </div>
  </div>
}

function MailchimpSignUpLink({ children }: React.PropsWithChildren<{ tags?: [] }>) {
  const [{loading}, mailchimpSignUp] = useMailchimpSignUpHandler({
    onSuccess: () => {
      Toast.success('Thanks for signing up!')
    },
    onError: (err) => {
      Toast.error('Something went wrong!  Please try again later.')
    }
  })
  
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a className={`${loading && 'flicker'}`} onClick={() => mailchimpSignUp(['waitlist'])}>{children}</a>
}
