import React, { useEffect } from 'react'
import * as ReactErrorBoundary from 'react-error-boundary'
import * as Sentry from "@sentry/react";
import { useRouteError } from 'react-router-dom';
import { captureException } from '@sentry/react';

const InnerErrorBoundary = ReactErrorBoundary.ErrorBoundary

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ErrorBoundaryProps {

}

export function ErrorBoundary({children}: React.PropsWithChildren<ErrorBoundaryProps>) {
  return <InnerErrorBoundary
      fallback={<ErrorFallback />}
      onError={(error) => {
        console.error('ErrorBoundary', error)
        const eventId = Sentry.captureException(error)
        Sentry.showReportDialog({ eventId })
      }} >
    {children}
  </InnerErrorBoundary>
}

export function ErrorFallback() {
  return <div className="container">
    <div className='row'>
      <div className='col col-lg-6 offset-lg-3'>
        <div className="error-fallback-screen">
          <h1>Something went wrong!</h1>

          <p>
            We're sorry, something went wrong.  We've already been notified of the problem.  If you continue to see
            errors, please reach out at <a href="mailto:gordon@healthsharetech.com">gordon@healthsharetech.com</a>
          </p>

          <button className="btn btn-primary"
              onClick={() => window.location.href = '/'}>
            Reload your browser
          </button>
        </div>
      </div>
    </div>
  </div>
}
