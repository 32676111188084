import React, { useEffect, useState } from "react";
import { setChonkyDefaults } from "chonky";
import {ChonkyIconFA} from 'chonky-icon-fontawesome';
import { SplashScreen } from "../screens/splashScreen";

interface ChonkyInitializerProps {
  onComplete: () => void
}

export function ChonkyInitializer({
  children,
  onComplete
}: React.PropsWithChildren<ChonkyInitializerProps>) {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setChonkyDefaults({
      iconComponent: ChonkyIconFA
    })
    setLoading(false)
    onComplete()
  }, [])

  if (loading) {
    return <SplashScreen />
  }

  return <>{children}</>
}