import React from "react";
import { useNavigate } from "react-router-dom";
import { IncidentSelect } from "./formComponents/incidentSelect";

interface ExpensesNavigationProps {
  incidentId?: string,

  fuseSearch?: {
    search: (query: string) => void,
    query: string | undefined
  }
}

export function ExpensesNavigation({
  incidentId,
  fuseSearch
}: ExpensesNavigationProps) {
  const navigate = useNavigate()

  return <form className="row" onSubmit={(e) => e.preventDefault()}>

  <div className='col-12 col-lg-6'>
    <label className='form-label me-auto'><strong>Go to Incident</strong></label>
    <IncidentSelect
      id={`incident-select/${incidentId || ''}`}
      className="form-select"
      value={incidentId}
      denyNewIncidents
      onSelect={(value) => {
        if (!incidentId && !value) return
        if (incidentId && !value) return navigate(`/expenses`)

        if (value && 'id' in value && value.id !== incidentId) {
          navigate(`/incidents/${value.id}`)
        }
      }}
      />
  </div>

  <div className='col-12 col-lg-6'>
    <label className='form-label me-auto'><strong>Search Expenses</strong></label>
    {fuseSearch &&
      <input className="form-control" type="search" placeholder="date, provider, amount, etc." aria-label="Search"
        value={fuseSearch.query}
        onChange={(e) => { e.preventDefault(); fuseSearch.search(e.target.value) }} />}
  </div>


</form>
}
