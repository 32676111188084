
export function iconForContentType(mimeType: string | null | undefined): string {
  if (mimeType?.startsWith('image/')) {
    return 'image'
  }

  switch (mimeType) {
    case 'application/pdf':
      return 'receipt_long'
    default:
      return 'quiz'
  }
}
