import React from "react";
import { Link } from "react-router-dom";

export function Unauthorized({children}: React.PropsWithChildren) {

  return <div className="row">
    <div className="col">
      <h1>401 Unauthorized</h1>
      {children ||
        <p>You don't have permission to view this.</p>}

      <Link to="/">Home</Link>
    </div>
  </div>
}