import { useState, useEffect, useMemo } from "react";

interface FakeProgressBarProps {
  steps: string[];
  completeAfterMs: number;
  
  onComplete: () => void;
}

export function FakeProgressBar({ steps, completeAfterMs, onComplete }: FakeProgressBarProps) {
  const start = useMemo(() => Date.now(), []);
  const [elapsed, setElapsed] = useState(0);
  const [complete, setComplete] = useState(false);
  const startTimestamps = useMemo(() => {
    const avgStepDuration = completeAfterMs / steps.length;
    
    // randomly add or subtract up to 1/3 the average step duration to each step
    let arr = [];
    for (let i = 0; i < steps.length; i++) {
      let jitter = Math.random() * (avgStepDuration / 3)
      if (Math.random() > 0.5) {
        jitter *= -1;
      }

      arr.push(i * avgStepDuration + jitter);
    }
    return arr;
  }, [completeAfterMs, steps.length])

  useEffect(() => {
    if (complete) { return }

    let intervalId: any;
    
    intervalId = setInterval(() => {
      setElapsed(Date.now() - start);
    }, 100);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [start, complete]);
  
  useEffect(() => {
    if (!complete && elapsed >= completeAfterMs) {
      setComplete(true);
      onComplete();
    }
  }, [elapsed, completeAfterMs, onComplete, complete])

  let currentStepIndex = startTimestamps.findIndex((timestamp) => timestamp > elapsed);
  currentStepIndex = currentStepIndex === -1 ? steps.length - 1 : Math.max(0, currentStepIndex - 1);
  const currentText = steps[currentStepIndex];

  return (<>
    <div className="progress">
      <div className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-label="Progress bar"
        aria-valuenow={elapsed}
        aria-valuemin={0} aria-valuemax={completeAfterMs}
        style={ { width: `${(elapsed / completeAfterMs) * 100}%` }}>
      </div>
    </div>
    <div className="progress-text">
      {currentText}
    </div>
  </>
  );
};
