import React from 'react'
import { useSession } from '../providers/supabase'
import { Unauthorized } from '../screens/unauthorized'

export interface RequireAuthProvided {
  user: { id: string }
}

export function requireAuth<TProps extends React.PropsWithChildren<object>>(
  Component: React.ComponentType<RequireAuthProvided & TProps>
): React.ComponentType<TProps> {

  return function AuthRequired(props: TProps) {
    const session = useSession()
    if (!session) {
      return <Unauthorized />
    }

    return <Component user={session.user} {...props} />
  }
}
