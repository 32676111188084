import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { useAsyncAction } from "../../hooks/useAsyncAction";
import { useClient } from "../../providers/supabase";
import { present } from "../../../lib/util/present";
import { VerifyOtpParams } from "@supabase/supabase-js";
import { SignInTabNav } from "./nav";
import { useSignInContext } from "./context";

export function VerifyOtp() {
  const client = useClient()
  const navigate = useNavigate()
  const [search, _] = useSearchParams()
  const email = search.get('email')
  
  const {onLoginSuccess} = useSignInContext()

  const [token, setToken] = useState('')

  useEffect(() => {
    if (!email) {
      navigate('/signIn/email')
    }
  }, [email, navigate])

  const [submitState, submit] = useAsyncAction(async() => {
    const params: VerifyOtpParams = {
      email: email!,
      token: token,
      type: 'signup'
    }

    let { data, error } = await client.auth.verifyOtp(params)

    if (error) {
      if (error.status === 401 && params.type === 'signup') {
        // If the user has already signed up, we use the magicLink
        const result2 = await client.auth.verifyOtp({
          ...params,
          type: 'magiclink'
        })
        if (result2.error) { throw error }
        data = result2.data
      } else {
        throw error
      }
    }

    const session = data.session
    if (!session) {
      throw new Error('Unable to verify your code. Please contact us for help.')
    }

    if (onLoginSuccess) {
      onLoginSuccess({ session: session })
    }
  }, [email, token, onLoginSuccess])

  const canSubmit = present(token)

  return <div className='row mt-4 justify-content-center'>
    <div className='col-12 col-lg-6'>
      <h1>Sign In</h1>
      <SignInTabNav />

      <p>Check your email for a sign in code and enter it here</p>
      <form onSubmit={(e) => { e.preventDefault(); submit() } }>
        <input type="text" className='form-control'
          placeholder="6-digit code"
          value={token}
          onChange={(e) => {
            setToken(e.target.value)
          }} />

          <button type='submit' className='btn btn-primary mt-2' disabled={!canSubmit}>Submit</button>

          <div className="mb-3">
            {submitState.error &&
              <p className="text-danger">
                {submitState.error.message}
              </p>}
          </div>
      </form>

      <hr />
      <div className='text-center'>
      </div>
    </div>
  </div>
}
