import { SubmissionData2024CHM } from "../../../app/reduxToolkit/submissionsSlice";
import { fillPdf } from "../../pdf/fillPdf";
import { calculateAgeAsString } from "../../util/calculateAge";
import { present } from "../../util/present";
import { splitDateParts } from "../../util/splitDateParts";

type MedicalBillWorksheetField = typeof fields[number]
type MedicalBillWorksheetData = {
  [k in MedicalBillWorksheetField]?: string | boolean | null | undefined
}

export async function fillMedicalBillWorksheet2024(
  pdfBytes: string | Uint8Array | ArrayBuffer,
  data: SubmissionData2024CHM['medicalBillWorksheetData']
): Promise<Uint8Array> {
  if (data.expenseRows.length > 8) {
    throw new Error("Medical bill worksheet can only have up to 8 expenses")
  }

  const {
    day: start_date_day,
    month: start_date_month,
    year: start_date_year,
  } = splitDateParts(data.primaryInsuranceStartDate)
  
  const {
    day: end_date_day,
    month: end_date_month,
    year: end_date_year,
  } = splitDateParts(data.primaryInsuranceEndDate)

  const formData: MedicalBillWorksheetData = {
    member_number_2: data.memberNumber,
    patient_name_2: data.patientName,
    is_add_on_yes: data.isAddOn === true,
    is_add_on_no: data.isAddOn === false,
    "If yes which incidentillness": data.isAddOnIncidentRefText,
    
    primary_insurance_yes: data.primaryInsurance === true,
    other_insurance_yes: data.primaryInsuranceOther === true,
    other_insurance_text: data.primaryInsuranceOtherText,
    start_date_day,
    start_date_month,
    start_date_year,
    end_date_day,
    end_date_month,
    end_date_year,
    financial_assistance_pending: data.financialAssistance === 'pending',
    financial_assistance_approved: data.financialAssistance === 'approved',
    financial_assistance_denied: data.financialAssistance === 'denied',
    Provider: data.provider,
  }
  
  data.expenseRows.forEach((row, i) => {
    const i1 = i + 1
    formData[`expenses_${i1}_date` as MedicalBillWorksheetField] = row.dateOfService
    formData[`expenses_${i1}_provider` as MedicalBillWorksheetField] = row.billingProvider
    formData[`expenses_${i1}_charges` as MedicalBillWorksheetField] = row.originalCharges
    formData[`expenses_${i1}_payments` as MedicalBillWorksheetField] = row.paymentAmount
    formData[`expenses_${i1}_discounts` as MedicalBillWorksheetField] = row.discountAmount
    
    switch (row.discountType) {
      case 'shownOnBill':
        formData[`expenses_${i1}_discounts_shown` as MedicalBillWorksheetField] = true
        break
      case 'includedInCharge':
        formData[`expenses_${i1}_discounts_included` as MedicalBillWorksheetField] = true
        break
      case 'noneAvailable':
        formData[`expenses_${i1}_discounts_none` as MedicalBillWorksheetField] = true
        break
    }
    
    switch (row.paymentType) {
      case 'shownOnBill':
        formData[`expenses_${i1}_payments_shown` as MedicalBillWorksheetField] = true
        break
      case 'onPaymentPlan':
        formData[`expenses_${i1}_payments_plan` as MedicalBillWorksheetField] = true
        break
      case 'noneMade':
        formData[`expenses_${i1}_payments_none` as MedicalBillWorksheetField] = true
        break
    }
  })
  
  return fillPdf(pdfBytes, formData)
}


const fields = [
  "member_number_2",
  "patient_name_2",
  "If yes which incidentillness",
  "other_insurance_text",
  "start_date_month",
  "start_date_day",
  "start_date_year",
  "end_date_month",
  "end_date_day",
  "end_date_year",
  "Provider",
  "expenses_1_discounts",
  "expenses_2_discounts",
  "expenses_3_discounts",
  "expenses_4_discounts",
  "expenses_5_discounts",
  "expenses_6_discounts",
  "expenses_7_discounts",
  "expenses_8_discounts",
  "expenses_1_payments",
  "expenses_2_payments",
  "expenses_3_payments",
  "expenses_4_payments",
  "expenses_5_payments",
  "expenses_6_payments",
  "expenses_7_payments",
  "expenses_8_payments",
  "is_add_on_yes",
  "is_add_on_no",
  "primary_insurance_yes",
  "other_insurance_yes",
  "financial_assistance_pending",
  "financial_assistance_approved",
  "financial_assistance_denied",
  "expenses_1_date",
  "expenses_1_provider",
  "expenses_1_charges",
  "expenses_2_date",
  "expenses_3_date",
  "expenses_4_date",
  "expenses_5_date",
  "expenses_6_date",
  "expenses_7_date",
  "expenses_8_date",
  "expenses_2_provider",
  "expenses_3_provider",
  "expenses_4_provider",
  "expenses_5_provider",
  "expenses_6_provider",
  "expenses_7_provider",
  "expenses_8_provider",
  "expenses_2_charges",
  "expenses_3_charges",
  "expenses_4_charges",
  "expenses_5_charges",
  "expenses_6_charges",
  "expenses_7_charges",
  "expenses_8_charges",
  "expenses_1_discounts_shown",
  "expenses_1_discounts_included",
  "expenses_1_discounts_none",
  "expenses_2_discounts_shown",
  "expenses_2_discounts_included",
  "expenses_2_discounts_none",
  "expenses_3_discounts_shown",
  "expenses_3_discounts_included",
  "expenses_3_discounts_none",
  "expenses_4_discounts_shown",
  "expenses_4_discounts_included",
  "expenses_4_discounts_none",
  "expenses_5_discounts_shown",
  "expenses_5_discounts_included",
  "expenses_5_discounts_none",
  "expenses_6_discounts_shown",
  "expenses_6_discounts_included",
  "expenses_6_discounts_none",
  "expenses_7_discounts_shown",
  "expenses_7_discounts_included",
  "expenses_7_discounts_none",
  "expenses_8_discounts_shown",
  "expenses_8_discounts_included",
  "expenses_8_discounts_none",
  "expenses_1_payments_shown",
  "expenses_1_payments_plan",
  "expenses_1_payments_none",
  "expenses_2_payments_shown",
  "expenses_2_payments_plan",
  "expenses_2_payments_none",
  "expenses_3_payments_shown",
  "expenses_3_payments_plan",
  "expenses_3_payments_none",
  "expenses_4_payments_shown",
  "expenses_4_payments_included",
  "expenses_4_payments_none",
  "expenses_5_payments_shown",
  "expenses_5_payments_plan",
  "expenses_5_payments_none",
  "expenses_6_payments_shown",
  "expenses_6_payments_plan",
  "expenses_6_payments_none",
  "expenses_7_payments_shown",
  "expenses_7_payments_plan",
  "expenses_7_payments_none",
  "expenses_8_payments_shown",
  "expenses_8_payments_plan",
  "expenses_8_payments_none"
] as const
