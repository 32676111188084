import { useCallback, useEffect, useRef, useState } from "react"
import { present } from "../../lib/util/present"

import './dropdownSelect.scss'

export interface DropdownSelectProps<T extends { id: string } = { id: string }> {
  id?: string
  className?: string
  disabled?: boolean
  required?: boolean

  value?: T | { key: string } | string | null | false,

  onChange: (event: DropdownSelectOnChangeEvent<T>) => void

  options: Array<T>
  additionalOptions?: Array<{
    key: string,
    label: React.ReactNode
  }>

  blankOption?: string | false
  placeholder?: string

  renderOption: (value: T) => React.ReactNode
  
  children?: React.ReactNode
}

export interface DropdownSelectOnChangeEvent<T extends { id: string } = { id: string }> {
  value: T | { key: string } | null,
  preventDefault: () => void
}

export function DropdownSelect<T extends { id: string }>({
  id, className, disabled, required,
  value, options, additionalOptions,  blankOption, placeholder,
  onChange,
  renderOption,
  children
}: DropdownSelectProps<T>) {

  const [show, setShow] = useState(false)
  const [invalid, setInvalid] = useState(false)

  let idOrKey: string | null = null
  if (typeof value == 'string') {
    idOrKey = value
  } else if (value && 'id' in value) {
    idOrKey = value.id
  } else if (value && 'key' in value) {
    idOrKey = value.key
  }
  const selectedOption = options.find((x) => x.id === idOrKey)
  const selectedAdditionalOption = additionalOptions?.find((o) => o.key === idOrKey)

  useEffect(() => {
    // clear invalid state if the value changes
    setInvalid(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOrKey])

  const _onClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setInvalid(false)
    let defaultPrevented = false
    
    // find the parent that has a value
    let target = event.target as HTMLButtonElement
    let value = target.value
    while (!value && target.parentElement) {
      target = target.parentElement as HTMLButtonElement
      value = target.value
    }

    const selectedValue = options.find((x) => x.id === target.value) ||
      additionalOptions?.find((x) => x.key === target.value) ||
      null

    onChange({
      value: selectedValue,
      preventDefault: () => {
        defaultPrevented = true
      }
    })

    if (!defaultPrevented) {
      setShow(false)
    }
  }, [onChange, options, additionalOptions])

  let label: React.ReactNode = placeholder || (typeof blankOption == 'string' ? blankOption : 'Select an Option...')
  let valueSelected = false
  if (selectedOption) {
    label = renderOption(selectedOption)
    valueSelected = true
  } else if (selectedAdditionalOption) {
    label = selectedAdditionalOption.label
    valueSelected = true
  }

  return <>
    <div className={`dropdown dropdown-select ${className} ${disabled && 'disabled'} ${required && 'required'} ${invalid && 'invalid'} ${valueSelected && 'value-selected'}`}
        aria-label="dropdown select">

      <button className="btn dropdown-toggle"
          aria-haspopup="true" aria-expanded={show}
          disabled={disabled}
          onClick={(e) => { e.preventDefault(); setShow(!show) }}>
        {label}
      </button>

      <div className={`dropdown-menu ${show && 'show'}`}>
        {options.map((x) => {
          return <button className={`dropdown-item ${selectedOption === x && 'active'}`} value={x.id} key={x.id}
                onClick={_onClick}>
              {renderOption(x)}
            </button>
        })}
        {additionalOptions && additionalOptions.length > 0 && <hr />}
        {additionalOptions?.map((o) => {
          return <button className={`dropdown-item ${selectedAdditionalOption === o && 'active'}`} value={o.key} key={o.key}
                onClick={_onClick}>
              {o.label}
            </button>
        })}

        {blankOption !== false && present(value) && <>
          <hr />
          <button className={`dropdown-item`} value='' key=''
              onClick={_onClick}>
            {blankOption || 'Clear Selection'}
          </button>
        </>}
      </div>
    </div>

    {/* Put this after the dropdown so invalid-feedback is triggered (uses ~ css operator) */}
    <input id={id} style={{
        position: 'absolute',
        left: '1px',
        top: '1px',
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        opacity: 0,
        zIndex: -1,
    }} value={idOrKey || ''} required={required}
      onChange={() => { /* noop */ }}
      onInvalid={() => {
        setInvalid(true)
      }} />

      
      {children}
    </>
}
