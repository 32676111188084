import { useNavigate } from "react-router-dom";
import { RequireSubmissionProvided } from "../../../../wrappers/requireSubmission";
import { MedicalBillWorksheet2024 } from "../submitToCHM/2024/medicalBillWorksheet2024";
import { PendingSubmissionModel, SubmissionData2024CHMAddon, isSubmissionData2024, updateSubmission } from "../../../../reduxToolkit/submissionsSlice";
import { reconcileExpenses } from "../submitToCHM/2024/utils/reconcileExpenses";
import { cloneDeep, debounce, max } from "lodash";
import { useMemo } from "react";
import { usePendingExpenses } from "../../../../hooks/usePendingExpenses";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxToolkit";
import { useGenerateCHMAddon2024 } from "../submitToCHM/2024/hooks/useGenerateCHMAddon2024";
import { SubmissionNextSteps2024 } from "../submitToCHM/2024/submissionNextSteps2024";
import { formatDateInTimeZone } from "../../../../../lib/formatDateInTimeZone";
import { useCommitSubmission } from "../submitToCHM/2024/hooks/useCommitSubmission2024";



export interface SubmitCHMAddOn2024Props extends RequireSubmissionProvided {
}

export function SubmitCHMAddOn2024({ submission }: SubmitCHMAddOn2024Props) {
  const navigate = useNavigate()
  let data: SubmissionData2024CHMAddon = useSubmissionData2024Addon(submission)

  const dispatch = useAppDispatch()
  const updateData = useMemo(() => {
    return debounce((data: SubmissionData2024CHMAddon) => {
      const now = new Date().toISOString()
      dispatch(updateSubmission({
        id: submission.id,
        data,
        updated_at: now
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 1000, {
      trailing: true
    })
  }, [submission.id, dispatch])

  const [_, generateAddonDocuments2024] = useGenerateCHMAddon2024({ data, submission })
  const [__, commitSubmission] = useCommitSubmission({ data, submission })

  let inner: React.ReactNode

  if (data.documentsGenerated) {
    inner = <SubmissionNextSteps2024
      submission={submission}
      onSubmitted={() => {
        navigate(`/incidents/${submission.incident_id}`)
      }}
      />
  } else {
    inner = <MedicalBillWorksheet2024
      data={data}
      updateData={updateData}
      advance={() => {
        commitSubmission()
        generateAddonDocuments2024()
      }} />
  }

  return <div className="submitToChm2024">
      {inner}
    </div>
}

function useSubmissionData2024Addon(submission: PendingSubmissionModel): SubmissionData2024CHMAddon {
  const member_number = useAppSelector((s) => s.membership.member_number)
  const incident = useAppSelector((s) => s.incidents.incidents.find((i) => i.id === submission.incident_id))
  const expenses = usePendingExpenses(submission)
  
  const data = useMemo(() => {
    if(isSubmissionData2024(submission.data)) {
      if (submission.data.type === 'addon') {
        return reconcileExpenses(
          cloneDeep(submission.data),  // submission.data comes from the store and should not be mutated
          expenses)
      }
    }
    
    const data: SubmissionData2024CHMAddon = {
      _version: '2024-04-01',
      type: 'addon',
      workingOn: 'medicalBillWorksheet',
      medicalBillWorksheetData: {
        isAddOn: true,
        isAddOnIncidentRefText: incident?.start_date ? formatDateInTimeZone(incident.start_date, { format: 'M/d/yyyy' }) : '',
        memberNumber: member_number,
        patientName: incident?.patient_name,
        expenseRows: [],
      },
    }
    return reconcileExpenses(data, expenses)
  // updated_at is sufficient to trigger a refresh of the data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission.updated_at, incident?.updated_at, max(expenses.map((e) => e.updated_at))])
 
  return data
}

function raiseUnknownWorkingOn(workingOn: never | undefined) {
  throw new Error(`Unknown workingOn value: ${workingOn}`)
}
