import { useCallback } from "react"

export function FormCheckInlineInputGroup<TKey extends string>({
  field,
  label,
  value,
  changeData,
  validationError,
  className,
  children
}: React.PropsWithChildren<{
  field: TKey,
  label: string,
  value: boolean | null | undefined,
  changeData: (field: TKey, value: boolean | null | undefined) => void,
  validationError?: { message: string },
  className?: string,
}>) {
  return <div className={`form-check-inline-input-group input-group input-group-checkbox ${className}`} id={field}>
    <label className="input-group-text">{label}</label>
    <div className={`form-check form-check-inline ${validationError && 'is-invalid'}`}>
      <input className='form-check-input' type='radio'
        name={field} id={`${field}_yes`} value='yes'
        checked={value === true}
        onChange={
          useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
            const value = evt.target.value === 'yes' ? true : null
            changeData(field, value)
          }, [field, changeData])
        }
        ></input>
      <label className="form-check-label" htmlFor={`${field}_yes`}>Yes</label>
    </div>
    <div className={`form-check form-check-inline ${validationError && 'is-invalid'}`}>
      <input className='form-check-input' type='radio'
        name={field} id={`${field}_no`} value='no'
        checked={value === false}
        onChange={
          useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
            const value = evt.target.value === 'no' ? false : null
            changeData(field, value)
          }, [field, changeData])
        }
        ></input>
      <label className="form-check-label" htmlFor={`${field}_no`}>No</label>
    </div>

    <div className="invalid-feedback">{validationError?.message}</div>

    {children}
  </div>
}
