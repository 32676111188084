import { useNavigate } from "react-router-dom"
import { assert } from "../../lib/util/assert"
import { useConfirm } from "../providers/confirmDialog"
import { deleteAttachment } from "../reduxToolkit/attachmentsSlice"
import { deleteSubmission } from "../reduxToolkit/submissionsSlice"
import { useAppDispatch, useAppSelector } from "./reduxToolkit"
import { useAsyncAction } from "./useAsyncAction"

/**
 * Deletes the submission and all attachments associated with it, then returns to the beginning of the submission process.
 * @param param0 
 * @param navigateTo 
 * @returns 
 */
export function useResetSubmission({ id }: { id: string }, navigateTo?: string) {
  const [show] = useConfirm()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const submission = useAppSelector((s) => s.submissions.submissions.find((s) => s.id === id))
  const submissionAttachments = useAppSelector((s) => s.attachments.attachments.filter((a) => a.record_id === id))
  
  assert(submission, 'Submission not found')
  
  return useAsyncAction(async (e: React.MouseEvent) => {
    if (!await show({
      title: 'Really start over?',
      message: 'This will delete all generated PDF forms, but not your incident or expense data.',
    })) {
      return
    }
    
    const now = new Date().toISOString()
    
    submissionAttachments.forEach((a) => {
      dispatch(deleteAttachment({
        id: a.id,
        updated_at: now,
        deleted_at: now
      }))
    })

    dispatch(deleteSubmission({
      id: id,
      updated_at: now,
      deleted_at: now
    }))
    
    navigate(navigateTo ? navigateTo : `/incidents/${submission.incident_id}/submit`)
  }, [dispatch, submissionAttachments, submission.id, submission.incident_id, navigate])
}
