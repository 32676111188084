

export function byStartDateDescending(a: { start_date: string }, b: { start_date: string }) {
  return Date.parse(b.start_date) - Date.parse(a.start_date)
}

export function byStartDateAscending(a: { start_date: string }, b: { start_date: string }) {
  return Date.parse(a.start_date) - Date.parse(b.start_date) 
}

export function byStartDateAscendingNullsFirst(a: { start_date?: string | null | undefined }, b: { start_date?: string | null | undefined }) {
  if (!a.start_date && !b.start_date) {
    return 0
  }
  if (!a.start_date) {
    return -1
  }
  if (!b.start_date) {
    return 1
  }
  return Date.parse(a.start_date) - Date.parse(b.start_date) 
}

export function bySubmittedAtDesc(a: { submitted_at: string }, b: { submitted_at: string }) {
  return Date.parse(a.submitted_at) - Date.parse(b.submitted_at)
}

export function bySubmittedAtDescNullsFirst(a: { submitted_at?: string | null }, b: { submitted_at?: string| null }) {
  // nulls are "less than" non-nulls
  if (!a.submitted_at) { return 1 }
  if (!b.submitted_at) { return -1 }

  // invert ascending to get descending
  return -1 * a.submitted_at.localeCompare(b.submitted_at)
}

export function bySubmittedAtAsc(a: { submitted_at: string }, b: { submitted_at: string }) {
  return a.submitted_at.localeCompare(b.submitted_at)
}

export function bySubmittedAtAscNullsLast(a: { submitted_at?: string | null }, b: { submitted_at?: string| null }) {
  // nulls are "greater than" non-nulls
  if (!a.submitted_at) { return -1 }
  if (!b.submitted_at) { return 1 }

  return a.submitted_at.localeCompare(b.submitted_at)
}

export function byUpdatedAtDescending(a: { updated_at: string }, b: { updated_at: string }) {
  return Date.parse(b.updated_at) - Date.parse(a.updated_at)
}

export function byUpdatedAtAscending(a: { updated_at: string }, b: { updated_at: string }) {
  return Date.parse(a.updated_at) - Date.parse(b.updated_at)
}

export function byDueDateAscendingNullsLast(a: { dueDate?: string | null | undefined }, b: { dueDate?: string | null | undefined }) {
  if (!a.dueDate && !b.dueDate) {
    return 0
  }
  if (!a.dueDate) {
    return 1
  }
  if (!b.dueDate) {
    return -1
  }
  return a.dueDate.localeCompare(b.dueDate)
}

export function byDueDateAscendingNullsFirst(a: { dueDate?: string | null | undefined }, b: { dueDate?: string | null | undefined }) {
  if (!a.dueDate && !b.dueDate) {
    return 0
  }
  if (!a.dueDate) {
    return -1
  }
  if (!b.dueDate) {
    return 1
  }
  return a.dueDate.localeCompare(b.dueDate)
}
