
export function fibonnaci(state?: [number, number]) {
  let [a, b] = state ?? [0, 1];
  return [b, a + b];
}

interface FibonnaciRetryHandle { cancel: () => void }

/**
 * Executes an action, retrying with increasing delays based on the fibonnaci sequence if the action throws.
 * 
 * @param action 
 * @returns A handle with a cancel function that can be used to stop retrying.
 */
export function fibonnaciRetry(action: () => void | Promise<void>): FibonnaciRetryHandle {
  let timeout: any;
  let [a, b] = [0, 1];
  const cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  }
  const next = async () => {
    [a, b] = [b, a + b];
    try {
      await action();
    } catch(e) {
      timeout = setTimeout(next, a * 1000);
    }
  }
  timeout = setTimeout(next, a * 1000);
  
  return { cancel };
}
