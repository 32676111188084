import { AppSupabaseClient, Tables } from "../../types/supabase";
import { SyncAction, SyncableTable, RowSyncResponse } from "./action";
import { syncAdd } from "./syncAdd";
import { syncDelete } from "./syncDelete";
import { syncUpdate } from "./syncUpdate";
import type { PostgrestError } from "@supabase/supabase-js";

export type SyncResult<
  TAction extends SyncAction<SyncableTable> = SyncAction<SyncableTable>
> = {
  updated_at: string

  /** These actions were applied */
  applied: TAction[],
  /** These actions were rejected due to the server data being newer */
  rejected: TAction[],
  /** These actions caused an error */
  error: Array<TAction & { error: Error | PostgrestError }>

  /**
   * This is the data in the server that results after applying all the actions.
   * Note: the Typescript type here will infer keys based on the tables of the
   * incoming actions.
   */
  snapshot: {
    [k in TAction['table']]: Array<Tables[k]['Row']>
  }
}

/**
 * Syncs an array of actions to the server, performing the appropriate queries
 * for each action.
 * @param client
 * @param actions
 * @returns
 */
export async function syncTableActions<
  TAction extends SyncAction<SyncableTable>
>(
  client: AppSupabaseClient,
  actions: TAction[]
): Promise<SyncResult<TAction>> {
  type Table = TAction['table']

  const result: SyncResult<TAction> = {
    updated_at: '',
    applied: [],
    rejected: [],
    error: [],

    snapshot: {} as SyncResult<TAction>['snapshot']
  };

  // Apply actions one at a time
  for (const action of actions) {
    let resp: RowSyncResponse<Table>;
    switch (action.type) {
      case 'add':
        resp = await syncAdd(client, action);
        break;
      case 'delete':
        resp = await syncDelete(client, action);
        break;
      case 'update':
        resp = await syncUpdate(client, action);
        break;
      default:
        raiseUnknownAction(action);
    }

    switch (resp.result) {
      case 'applied':
        result.applied.push(action);
        break;
      case 'error':
        result.error.push({
          ...action,
          error: resp.error
        });
        break;
      case 'rejected':
        result.rejected.push(action);
        break;
      default:
        raiseUnknownResult(resp);
    }
    if (resp.data) {
      if (resp.data.updated_at > result.updated_at) {
        result.updated_at = resp.data.updated_at
      }

      const table: Table = action.table
      if (!result.snapshot[table]) { result.snapshot[table] = []; }
      result.snapshot[table].push(resp.data);
    }
  }

  // Compress the snapshots if we had multiple actions on one table
  const tablesInSnapshot = Object.keys(result.snapshot) as Table[];

  for (const table of tablesInSnapshot) {
    const snap = result.snapshot[table];
    result.snapshot[table] = snap.filter((row, i) => {
      // Filter out this row if we find another row with the same ID and greater updated_at
      // because that one takes precendence in the snapshots (regardless of order)
      if (snap.find((row2) => row2.id === row.id && row2.updated_at > row.updated_at)) {
        return false
      }
      // Filter out this row if a later row has the same updated at, because that one was
      // applied after this one (even if their "updated at" is the same down to the second)
      if (snap.find((row2, i2) => i2 > i && row2.id === row.id && row2.updated_at === row.updated_at)) {
        return false
      }
      return true
    });
  }

  return result;
}



export function raiseUnknownAction(action: never): never {
  throw new Error(`Unknown Action type: '${(action as any).type}'`)
}

export function raiseUnknownResult(resp: never): never {
  throw new Error(`Unknown Result type: '${(resp as any).result}'`)
}
