
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: "https://63a693a2b36c4674b13c08f9b3f5baae@o4504879681699840.ingest.sentry.io/4504883378061312",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  release: process.env.REACT_APP_GIT_SHA,
  beforeBreadcrumb(breadcrumb, hint) {
    // Don't capture console logs - we want to explicitly leave breadcrumbs due to HIPAA concerns.
    if (breadcrumb.category === 'console') {
      return null;
    }
    return breadcrumb;
  }
});

export function SentryProvider({children}: React.PropsWithChildren) {
  return <>{children}</>
}

const globalAddBreadcrumb = Sentry.addBreadcrumb.bind(Sentry)
const globalCaptureException = Sentry.captureException.bind(Sentry)
const globalCaptureMessage = Sentry.captureMessage.bind(Sentry)
const globalCaptureEvent = Sentry.captureEvent.bind(Sentry)

const globalSentryCtx = {
  addBreadcrumb: globalAddBreadcrumb,
  captureException: globalCaptureException,
  captureMessage: globalCaptureMessage,
  captureEvent: globalCaptureEvent,
}

export function useSentry() {
  return globalSentryCtx
}
