import { useCallback } from "react"
import { useAppDispatch } from "../hooks/reduxToolkit"
import { ExpenseModel, ExpenseUpdatePayload, isTextractJobFailed, isTextractPending, updateExpense } from "../reduxToolkit/expensesSlice"
import { EditableDate } from "./editable/editableDate"
import { EditableText } from "./editable/editableText"
import { Tooltip } from "./tooltip"

import './expenseSpreadsheet.scss'
import { useCheckDocumentAnalysis } from "../hooks/useCheckDocumentAnalysis"

interface ExpenseSpreadsheetProps {
  expenses: ExpenseModel[]

  onExpenseUpdated?: (expense: ExpenseUpdatePayload) => void
}

export function ExpenseSpreadsheet({expenses, onExpenseUpdated}: ExpenseSpreadsheetProps) {
  const dispatch = useAppDispatch()

  const doUpdate = useCallback((id: string, update: Partial<ExpenseModel>) => {
    const now = new Date().toISOString()
    const updateAction = dispatch(updateExpense({
      id,
      updated_at: now,
      ...update,
    }))

    onExpenseUpdated?.(updateAction.payload)
  }, [dispatch, onExpenseUpdated])

  return <table className="table expense-spreadsheet">

    <thead className="thead-light">
      <tr>
        <th scope="col" style={{ width: '5%' }}></th>
        <th scope="col" style={{ width: '15%' }}>Date</th>
        <th scope="col" style={{ width: '40%' }}>Provider</th>
        <th scope="col" style={{ width: '20%' }}>Listed Amt</th>
        <th scope="col" style={{ width: '20%' }}>Paid Amt</th>
      </tr>
    </thead>

    <tbody>
      {expenses.map((expense, i) => {
        return <ExpenseSpreadsheetRow key={expense.id} index={i} expense={expense} doUpdate={doUpdate} />
        })}
    </tbody>

  </table>
}

function ExpenseSpreadsheetRow({expense, index, doUpdate}: {expense: ExpenseModel, index: number, doUpdate: (id: string, update: Partial<ExpenseModel>) => void }) {
  useCheckDocumentAnalysis(expense.id)
  
  return <tr key={expense.id}>
    <td>
      <div className="d-flex">
        <h6>{index + 1}.</h6>
        <div className="ml-auto">
          {isTextractPending(expense) ?
            isTextractJobFailed(expense) ?
              <Tooltip tooltip={"Unable to automatically extract expense information"}>
                <i className="material-icons text-danger">warning</i>
              </Tooltip> :
              <Tooltip tooltip="Scanning expense to extract information.  This can sometimes take up to 10 minutes.  You can enter the information manually and it wont be overwritten.">
                <i className="material-icons flicker">sensors</i>
              </Tooltip> :
            null
          }
        </div>
      </div>
    </td>

    <td>
      <EditableDate value={expense.date}
        placeholder="(doubleclick to update)"
        onChange={(value) => doUpdate(expense.id, { date: value })} />
    </td>

    <td>
      <EditableText value={expense.provider || undefined}
        placeholder="(doubleclick to update)"
        onChange={(value) => doUpdate(expense.id, { provider: value })} />
    </td>

    <td>
      <EditableText value={expense.listedAmount || undefined}
        pattern="\$\d+(\.\d{2})?"
        prefix="$"
        placeholder="(doubleclick to update)"
        onChange={(value) => doUpdate(expense.id, { listedAmount: value })} />
    </td>

    <td>
      <EditableText value={expense.paidAmount || undefined}
        pattern="\$\d+(\.\d{2})?"
        prefix="$"
        placeholder="(doubleclick to update)"
        onChange={(value) => doUpdate(expense.id, { paidAmount: value })} />
    </td>
  </tr>
}
