
import { useState } from "react";
import { AppSupabaseClient, MembershipRoleRow, MembershipRow } from "../../../types/supabase";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit";
import { useAsyncAction } from "../../hooks/useAsyncAction";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useClient } from "../../providers/supabase";
import { selectMembershipForUser } from "../../../lib/queries/selectMembershipForUser";
import { present } from "../../../lib/util/present";
import { onSyncMembership } from "../../reduxToolkit/membershipSlice";
import { RequireAuthProvided } from "../../wrappers/requireAuth";
import { updateExistingMembership } from "../../../lib/queries/updateExistingMembership";
import { Link } from "react-router-dom";
import { selectOrganizationForUser } from "../../../lib/queries/selectOrganizationForUser";

export interface OnboardingSetMembershipDetailsProps extends RequireAuthProvided {
  onComplete: () => void
}

export function OnboardingSetMembershipDetails({user, onComplete}: OnboardingSetMembershipDetailsProps) {
  const membershipSlice = useAppSelector((s) => s.membership)
  const dispatch = useAppDispatch()
  const client = useClient()

  const init = useAsyncEffect(async () => {
    // attempt reload from server
    const row = await selectMembershipForUser(client, { userId: user.id })

      if (row) {
        const {role, membership, profile} = row

        dispatch(onSyncMembership({
          userId: user?.id,
          membership,
          role,
          profile
        }))
      }
  }, [user.id])

  if (init.error) { throw init.error }

  if (init.loading) {
    return <div className="row mt-4">
      <div className="col-12 col-xl-8 offset-xl-2">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  }

  if (membershipSlice.uninitialized) {
    return <MembershipInsertForm user={user} onInsert={() => onComplete()} />
  }

  const {membershipId, member_number, role} = membershipSlice
  if (role === 'admin') {
    return <MembershipUpdateForm user={user} existingMembership={{
      id: membershipId,
      member_number
    }} onUpdate={
      () => onComplete()
    } />
  }

  // not an admin
  return <div className="row">
    <div className="col-12">
      <p>
        Please contact us to help you correct your membership number.
        You will not be able to take advantage of all the features of this app until you set your membership number.
      </p>
      <button className="btn btn-secondary" onClick={onComplete}>
        Come back to this later
      </button>
    </div>
  </div>
}

interface MembershipInsertFormProps {
  user: { id: string }

  onInsert?: () => void
}

function MembershipInsertForm({
    user,
    onInsert
}: MembershipInsertFormProps) {
  const [memberNumber, setMemberNumber] = useState<string>()
  const client = useClient()
  const dispatch = useAppDispatch()

  const [submitResult, onSubmit] = useAsyncAction(async (e: React.FormEvent) => {
    console.log('onSubmit')
    e.preventDefault();
    if (!(e.target as HTMLFormElement).checkValidity()) { return }

    if (!memberNumber) { return }

    const rows = await insertNewMembership(client, {
      userId: user.id,
      memberNumber
    })
    dispatch(onSyncMembership({
      userId: user.id,
      ...rows
    }))

    onInsert && onInsert()
  }, [user.id, memberNumber, client, onInsert])

  let errorMessage: React.ReactNode | undefined = undefined
  if (submitResult.error) {
    if (submitResult.error.message.includes('duplicate key value violates unique constraint')) {
      errorMessage = <p>
        This membership number belongs to another account.<br/>
        Please <Link to="/help">contact us for help</Link> if you would like to manage this
        CHM membership number using this email address.
      </p>
    } else {
      throw submitResult.error
    }
  }

  return <div className="row">
    <div className="col-12">
      <h1>Enter your Membership Details</h1>
      <p>
        Please enter your membership number for your CHM account.
      </p>

      {submitResult.loading &&
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>}

      <form onSubmit={onSubmit}>
        <div className="form-group col-12 col-lg-6">
          <label htmlFor="memberNumber" className="form-label">CHM Member Number</label>
          <input type="text" className="form-control" id="memberNumber"
            value={memberNumber || ''}
            disabled={submitResult.loading}
            required
            minLength={6}
            maxLength={6}
            onChange={(e) => { setMemberNumber(e.target.value) }} />

          {errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>}

            <button type='submit'
              className={`btn btn-primary mt-2`}
            >
              {submitResult.loading ?
                <span className="spinner-border" role="status">
                  <span className="visually-hidden">Submitting...</span>
                </span> :
                `Submit`}
            </button>
        </div>
      </form>
    </div>
  </div>
}

async function insertNewMembership(
  client: AppSupabaseClient,
  {userId, memberNumber}: {
    userId: string,
    memberNumber: string,
  }
): Promise<{ membership: MembershipRow, role: MembershipRoleRow }> {
  // get the current user's email address
  const userResp = await client.auth.getUser()
  if (userResp.error) { throw new Error(userResp.error.message) }
  if (userResp.data?.user?.id !== userId) { throw new Error(`User ID mismatch - want ${userId} got ${userResp.data?.user?.id}`) }

  // insert the new membership
  const resp = await client.from('memberships').insert({
    created_by_user_id: userId,
    member_number: memberNumber
  })
  .select('*')
  if (resp.error) { throw new Error(resp.error.message) }
  if (!resp.data || !resp.data[0]) { throw new Error(`Unable to get ID after inserting ${memberNumber}`) }
  const membership = resp.data[0]

  // make this user the admin of the membership
  const roleResp = await client.from('membership_roles').insert({
    membership_id: membership.id,
    role: 'admin',
    user_id: userId
  }).select('*')
  if (roleResp.error) { throw new Error(roleResp.error.message) }
  if (!roleResp.data || !roleResp.data[0]) { throw new Error(`Unable to get ID after inserting membership_role for ${membership.id}`) }
  const role = roleResp.data[0] as MembershipRoleRow

  const email = userResp.data.user.email
  // insert this user as a member of the matching organization
  const org = await selectOrganizationForUser(client, { email, id: userId })
  if (org) {
    await client.from('organization_memberships').upsert({
      organization_id: org.id,
      membership_id: membership.id
    })
  }

  return {
    membership,
    role
  }
}

interface MembershipUpdateFormProps {
  user: { id: string }

  existingMembership: Pick<MembershipRow, 'id' | 'member_number'>

  onUpdate?: () => void
}

function MembershipUpdateForm({
  user,
  existingMembership,
  onUpdate
}: MembershipUpdateFormProps) {
  const [memberNumber, setMemberNumber] = useState<string>(existingMembership.member_number || '')
  const client = useClient()
  const dispatch = useAppDispatch()

  const [submitResult, onSubmit] = useAsyncAction(async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('submit', memberNumber)
    if (!memberNumber) { return }

    const rows = await updateExistingMembership(client, {
      membershipId: existingMembership.id,
      memberNumber: memberNumber
    })
    dispatch(onSyncMembership({
      userId: user.id,
      ...rows
    }))

    onUpdate && onUpdate()
  }, [existingMembership.id, memberNumber, client, onUpdate])
  if (submitResult.error) { throw submitResult.error }

  return <div className="row">
    <div className="col-12">
      <h1>Update your Membership Details</h1>
      <p>
        Please enter your membership number for your CHM account.
      </p>

      {submitResult.loading &&
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>}

      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="memberNumber" className="form-label">CHM Member Number</label>
          <input type="text" className="form-control" id="memberNumber"
            value={memberNumber}
            disabled={submitResult.loading}
            onChange={(e) => { setMemberNumber(e.target.value) }} />
        </div>
        <div className="col-12">
            <button type='submit'
              className={`btn btn-primary ${submitResult.loading ? 'disabled' : ''} mt-2`}
            >
              {submitResult.loading ?
                <span className="spinner-border" role="status">
                  <span className="visually-hidden">Submitting...</span>
                </span> :
                `Submit`}
            </button>
        </div>
      </form>

    </div>
  </div>
}
