import { useEffect } from "react";
import { useClient } from "../providers/supabase";
import { useAppDispatch, useAppSelector } from "./reduxToolkit";
import { isCompleteExpense, updateExpense } from "../reduxToolkit/expensesSlice";
import { present } from "../../lib/util/present";
import { fibonnaciRetry } from "../../lib/fibonnaci";
import { ExpenseRow } from "../../types/supabase";
import { onSyncDownComplete } from "../reduxToolkit/actions/onSyncDownComplete";
import { useSentry } from "../providers/sentry";

/**
 * Mounts a useEffect which checks the status of the document analysis job for the given expense.
 * Does nothing if the analysis is already complete or if the expense has no document analysis job.
 */
export function useCheckDocumentAnalysis(expenseId: string, options?: { if?: boolean }) {
  const supabase = useClient()
  const dispatch = useAppDispatch()
  const { captureException, addBreadcrumb } = useSentry()
  const expense = useAppSelector((s) => s.expenses.expenses.find((x) => x.id === expenseId))

  const isComplete = expense && isCompleteExpense(expense)
  const textract_job_id = expense?.textract_job_id
  const textract_job_completed_at = expense?.textract_job_completed_at
  
  useEffect(() => {
    if (options?.if === false) { return; }

    // In these cases, we don't need to check for document analysis completion:
    if (isComplete) { return; }
    if (!present(textract_job_id)) { return; }
    if (present(textract_job_completed_at)) { return; }
    
    const checkJobStatus = async () => {
      addBreadcrumb({
        timestamp: Date.now(),
        message: 'Checking document analysis status',
        category: 'document-analysis',
        level: 'info',
        data: {
          expenseId,
        }
      })
      const response = await supabase.functions.invoke('get-document-analysis', {
        body: JSON.stringify({ jobId: textract_job_id }),
        method: 'POST'
      })
      if (response.error) { throw response.error }
      
      const data: GetDocumentAnalysisResponse = response.data
      switch(data.status) {
        case 'SUCCEEDED':
        case 'PARTIAL_SUCCESS':
          // The expense has been updated on the server with the results of the analysis.
          const expense = data.data
          dispatch(onSyncDownComplete({
            expenses: { updated: [expense] }
          }))
          break;
        case 'FAILED':
          // In this case, document analysis is complete but failed. Mark the expense as failed client-side as the server
          // may not have been able to do so.
          const now = new Date().toISOString()
          dispatch(updateExpense({
            id: expenseId,
            updated_at: now,
            textract_job_completed_at: now,
            textract_job_status: 'FAILED',
          }))
          break;
        case 'IN_PROGRESS':
          // Raise an error so that fibonnaciRetry will retry.
          throw new Error('Document analysis is still in progress.')
        default:
          // Something unexpected happened.  Log it to Sentry but don't retry.
          captureException(new Error(`Unexpected document analysis status: '${(data as any).status}'`))
      }
    }

    const { cancel } = fibonnaciRetry(checkJobStatus)
    
    return cancel
  }, [options?.if, isComplete, textract_job_id, textract_job_completed_at, addBreadcrumb, expenseId, supabase.functions, dispatch, captureException])
}

type GetDocumentAnalysisResponse =
 {
   status: 'SUCCEEDED' | 'PARTIAL_SUCCESS',
   data: ExpenseRow,
   error: undefined
 } | {
  status: 'FAILED',
  data: undefined,
  error: string
 } | {
  status: 'IN_PROGRESS',
  data: undefined,
  error: undefined
 }
