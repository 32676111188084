import { StorageClient } from "@supabase/storage-js";
import { Semaphore } from "async-toolbox";

export interface DownloadedFile {
  name: string,
  data: Blob
}

interface DownloadAllOptions {
  parallelism?: number
}

type DownloadCb = (file: DownloadedFile) => Promise<void> | void

export async function downloadAll(
  bucket: string,
  files: string[],
  client: StorageClient,
  options?: DownloadAllOptions
): Promise<DownloadedFile[]>

export async function downloadAll(
  bucket: string,
  files: string[],
  client: StorageClient,
  callback: DownloadCb,
  options?: DownloadAllOptions
): Promise<unknown>

export async function downloadAll(
  bucket: string,
  files: string[],
  client: StorageClient,
  cbOrOpts?: DownloadCb | DownloadAllOptions,
  options?: DownloadAllOptions
) {
  let cb: DownloadCb | undefined = undefined
  if (typeof cbOrOpts == 'function') {
    cb = cbOrOpts
  } else {
    options = cbOrOpts
  }

  const semaphore = new Semaphore({ tokens: options?.parallelism || 2 })

  const storageApi = client.from(bucket)
  const downloadImpl = semaphore.synchronize(storageApi.download.bind(storageApi))

  return await Promise.all(files.map(async (name) => {
    const response = await downloadImpl(name)
    if (response.error) { throw response.error }

    const file = {
      name,
      data: response.data
    }

    if (cb) {
      await cb(file)
      // discard the blob so it can be garbage collected
    } else {
      return file
    }
  }))
}
