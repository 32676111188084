import { Button } from "react-bootstrap";
import toast from "react-hot-toast";

import './copyableSpan.scss'

interface CopyableSpanProps {
  className?: string;
  showIcon?: boolean; 
  children: string;
}

export function CopyableSpan({children, className, showIcon}: CopyableSpanProps) {
    return (
        <span className={`copyable-span ${className}`} 
            onClick={() => {
              navigator.clipboard.writeText(children)
              toast.success('Copied to clipboard!')
            }}>
            {children}
            <i className="material-icons">content_copy</i>
        </span>
    ) 
}
