import React, { useState } from "react"
import { useAsyncAction } from "../../hooks/useAsyncAction"
import { useClient } from "../../providers/supabase"

interface ForgotPasswordInitFormProps {
  onEmailSent?: (email: string) => void

  redirectTo?: string
}

interface ForgotPasswordInitFormState {
  email?: string
}

export function ForgotPasswordInitForm({
  onEmailSent,
  redirectTo
}: ForgotPasswordInitFormProps) {
  const client = useClient()
  const [state, setState] = useState<ForgotPasswordInitFormState>({})

  const [{loading: submitting, error, data}, submit] = useAsyncAction(async (e: React.FormEvent) => {
    e.preventDefault()
    if (!state.email) { throw new Error(`Email is required`) }

    const resp = await client.auth.resetPasswordForEmail(state.email, {
      redirectTo: redirectTo || (window.location.href + `?email=${state.email}`),
    })
    if (resp.error) { throw resp.error }

    if (onEmailSent) { onEmailSent(state.email) }
    return resp.data
  }, [onEmailSent, state.email, redirectTo])

  const canSubmit = state.email

  return <form onSubmit={submit}>
    <div className="mb-3">
      <span className='form-label'>Email</span>
      <input name="email" type="text"
        className="form-control"
        required
        disabled={submitting}
        value={state.email || ''}
        onChange={(e) => { setState({...state, email: e.target.value }) }}></input>
    </div>

    <div className="mb-3">
      {submitting ?
        <button className='btn btn-primary disabled'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </button> :
        <button type='submit' className='btn btn-primary' disabled={!canSubmit}>Submit</button>}
    </div>

    <div className="mb-3">
      {error &&
        <p className="text-danger">
          {error.message}
        </p>}
    </div>
  </form>
}
