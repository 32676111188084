import { useInfiniteHits, useInstantSearch, useSearchBox } from "react-instantsearch";
import { TypesenseSearch } from "../../components/typesenseSearch";
import { present } from "../../../lib/util/present";
import { translate } from "pdf-lib";
import { useState, useRef } from "react";

export function SearchTab() {
  return <div className="help-search">
    <TypesenseSearch indexName="knowledgebase">
      <div className="row">
        <div className="col-12">
          <SearchBox />
        </div>
        
        <div className="col-12">
          <div className="help-search-results">
            <Hits />
          </div>
        </div>
      </div>
    </TypesenseSearch>
  </div>
}

function SearchBox({searchAsYouType = true}) {
  const {query, refine} = useSearchBox({})
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef<HTMLInputElement>(null);

  function setQuery(newQuery: string) {
    setInputValue(newQuery);

    if (searchAsYouType) {
      refine(newQuery);
    }
  }
  function onReset() {
    setQuery('');

    if (!searchAsYouType) {
      refine('');
    }
  }
  
  const hasQuery = present(query);

  return (
    <div className={`ais-SearchBox ${hasQuery && 'has-query'}`}>
      <form
        noValidate
        onReset={onReset}
        className={`ais-SearchBox-form`}
        action=""
        role="search"
        onSubmit={(event) => {
          event.preventDefault();
          refine(inputValue);
        }}
      >
        <button
          type="submit"
          className={`ais-SearchBox-submit`}
        >
          <i className="material-icons">search</i>
        </button>
        <button
          type="reset"
          className={`ais-SearchBox-reset`}
        >
           <i className="material-icons">clear</i>
        </button>
        <input
          ref={inputRef}
          className={'ais-SearchBox-input'}
          aria-label="Search"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={'Search the Knowledgebase'}
          spellCheck={false}
          maxLength={512}
          type="search"
          value={inputValue}
          onChange={(event) => setQuery(event.currentTarget.value)}
          autoFocus={true}
        />
      </form>
    </div>
  )
}

function Hits() {
  const {uiState} = useInstantSearch()
  const {hits, isLastPage, showMore} = useInfiniteHits<KnowledgebaseHit>({
  })
  
  const hasQuery = Object.values(uiState).some(indexState => present(indexState.query))
  
  return <div className="help-search__hits">
      {hasQuery && hits.map(hit => <Hit key={hit.id} hit={hit} />)}
    {!isLastPage && <button className="help-search__hits-more" onClick={showMore}>More</button>}
  </div>
}

function Hit(props: {hit: KnowledgebaseHit}) {
  const { hit } = props;
  return <a className="a-block help-search__result" target="_blank" rel="noreferrer"
    href={`https://healthsharetech.freshdesk.com/support/solutions/articles/${hit.freshworksId}`}>
  <div className="card" id={`#${hit.id}`}>
    <div className="card-body">
      <h4 className="card-title">{hit.title}</h4>
      <p className="card-text">{hit.description_text}</p>
      
      <p className="card-subtitle">
        <span className="text-muted">{hit.hierarchy[0]}</span>
        <button className="btn btn-link">Read More <i className="material-icons">arrow_forward</i></button>
      </p>
      
    </div>
  </div>
  </a>
}

interface KnowledgebaseHit {
  id: string
  freshworksId: string
  title: string
  description_text: string | null
  updated_at: string,
  category_id: string
  hierarchy: string[]
  
  [key: string]: any
}
