import { useCallback, useState } from "react"
import usePortal from "react-useportal"
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router";
import { ConfirmDispatchAction } from "../../components/todos/confirmDispatchAction";
import { ConfirmNavigateAction } from "../../components/todos/confirmNavigateAction";
import { EditExpenseAction } from "../../components/todos/editExpenseAction";
import { VirtualToDo, raiseUnknownTodoAction } from "../../../lib/todos/types";
import { useAppDispatch } from "../reduxToolkit";
import { AttachReceiptAction } from "../../components/todos/attachReceiptAction";

/**
 * Creates an onClick handler for a ToDo list item that will perform the action associated with the todo item.
 *
 * The action is performed in a modal dialog that is created and destroyed as needed.  The modal must be mounted
 * by the caller.
 * @returns [onClick, { currentAction }, modal]
 * @example
 *  const [onClick, { currentAction }, modal] = useTodoCompletion()
 *
 *  return <div className="todo-list">
 *    {incompleteTodos.map((todo) => <ToDoListItem key={todo.key} onClick={() => onClick(todo)} />)}
 *    {modal}
 *  </div>
 */
export function useTodoCompletion() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()


  const [currentAction, setCurrentAction] = useState<VirtualToDo['action'] | null>(null)

  const { Portal } = usePortal()
  const [uniqueId, setUniqueId] = useState<number>(Math.random())
  const domId = `modal-todo-list-${uniqueId}`

  const onClick = useCallback((todo: VirtualToDo) => {
    if (!todo.action) { return }

    if (todo.action.type === 'dispatch') {
      if (!todo.action.confirm) {
        // Immediately dispatch w/o confirmation
        dispatch(todo.action.action)
        return
      }
    }
    if (todo.action.type === 'navigate') {
      if (!todo.action.confirm) {
        // Immediately dispatch w/o confirmation
        navigate(todo.action.route)
      }
      return
    }

    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(domId)!)
    modal?.show()
    setCurrentAction(todo.action)
  }, [dispatch, domId, navigate])

  const onCompleted = useCallback(() => {
    const modal = bootstrap.Modal.getInstance(document.getElementById(domId)!)
    modal?.hide()
    setUniqueId(Math.random())
    setCurrentAction(null)
  }, [domId])

  const onCancelled = useCallback(() => {
    const modal = bootstrap.Modal.getInstance(document.getElementById(domId)!)
    modal?.hide()
    setUniqueId(Math.random())
    setCurrentAction(null)
  }, [domId])

  let title: string | null = null
  let action: JSX.Element | null = null
  if (currentAction) {
    switch(currentAction.type) {
      case 'editExpense':
        title = 'Edit Expense'
        action = <EditExpenseAction {...currentAction.parameters} onCompleted={onCompleted} />
        break;
      case 'attachReceipt':
        title = 'Attach Itemized Bill'
        action = <AttachReceiptAction {...currentAction.parameters} onCompleted={onCompleted} />
        break;
      case 'dispatch':
        action = <ConfirmDispatchAction confirm={currentAction.confirm} action={currentAction.action} onCompleted={onCompleted} onCancelled={onCancelled} />
        break;
      case 'navigate':
        action = <ConfirmNavigateAction route={currentAction.route} confirm={currentAction.confirm} onCompleted={onCompleted} onCancelled={onCancelled} />
        break;
      default:
        raiseUnknownTodoAction(currentAction)
    }
  }


  const modal = <Portal>
      <div className="modal modal-lg fade" id={domId} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="exampleModalLabel">{title}</h2>
              <button type="button" className="btn-close"
                onClick={onCancelled}
                data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {action}
            </div>
          </div>
        </div>
      </div>
    </Portal>

  return [
    onClick,
    {
      currentAction,
    },
    modal
  ] as const
}
