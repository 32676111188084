import { formatDateInTimeZone } from "../../../../../lib/formatDateInTimeZone";
import toast from 'react-hot-toast';
import { BlobRow } from "../../../../../types/supabase";
import { CopyableSpan } from "../../../../components/copyableSpan";
import { useShareBlobs } from "../../../../hooks/useShareBlobs";
import { isMobile } from "../../../../../lib/util/isMobile";
import { useCallback } from "react";
import { merge } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxToolkit";
import { useConfirm } from "../../../../providers/confirmDialog";
import { useClient } from "../../../../providers/supabase";
import { useSentry } from "../../../../providers/sentry";
import { setSettings } from "../../../../reduxToolkit/membershipSlice";
import { useAsyncAction } from "../../../../hooks/useAsyncAction";
import { IncidentModel } from "../../../../reduxToolkit/incidentsSlice";

interface EmailMethodTabProps {
  incident: IncidentModel
  blobs: BlobRow[]
  zipFileName: string
}

export function EmailMethodTab({ incident, blobs, zipFileName }: EmailMethodTabProps) {
  const [shareBlobs, shareBlobsState, { canShareBlobs }] = useShareBlobs(blobs, zipFileName)
  
  const mobile = isMobile()
  const canShare = canShareBlobs()
  
  const [confirm] = useEmailMethodConfirmDialog()
  
  const [shareResult, doShare] = useAsyncAction(async () => {
    if (await confirm()) {
      await shareBlobs()
    }
    return
  }, [shareBlobs, confirm])
  
  if (shareBlobsState.error) { throw shareBlobsState.error }
  if (shareResult.error) { throw shareResult.error }
  
  if (!mobile) {
    return <>
      <p>
        CHM allows submissions via email in addition to their website.
      </p>
      <p>
        The Email Method works best on a mobile device.
        Please switch to the phone app and come back to this page, or use the Browser Method to submit your documents.
      </p>
    </>
  }
  
  if (!canShare) {
    return <>
      <p>
        CHM allows submissions via email in addition to their website.
      </p>
      <p>
        Unfortunately, your device does not support sharing files via email.
        Please use the Browser Method to submit your documents.
      </p>
    </>
  }
  
  return <>
        <h4>Next Steps:</h4>
        <p>
          CHM allows submissions via email in addition to their website.  To email the files follow these instructions:
          
          <ol>
            <li>Click the following link to open your phone's Share dialog</li>
            <li>Choose your email app from the sharing list</li>
            <li>
              In the "to:" address field write<br/>
              <CopyableSpan className="code">records@chministries.org</CopyableSpan>
            </li>
            <li>
              In the description write<br/>
              "Incident on {formatDateInTimeZone(incident.start_date, { format: 'MMM dd' })}"
            </li>
            <li>Send the email</li>
          </ol>
          
          <button className={`btn btn-outline-info ${(shareBlobsState.loading || shareResult.loading) && 'disabled'}`}
            onClick={doShare}>Email my documents</button>
        </p>
    </>
}

function useEmailMethodConfirmDialog() {
  const settings = useAppSelector((s) => s.membership.settings)
  const [show] = useConfirm()
  const dispatch = useAppDispatch()
  const client = useClient()
  const { captureException } = useSentry()
  
  const neverShowAgain = settings?.share_chm_submission_warning_dismissed === true
  
  const setPreference = useCallback(async (neverShowAgain: boolean) => {
    if (!settings) { throw new Error('Settings not loaded') }

    try {
      const now = new Date().toISOString()
      const updatedRow = {
        share_chm_submission_warning_dismissed: neverShowAgain,
        updated_at: now
      }
      const result = await client.from('settings')
        .update(updatedRow)
        .eq('id', settings.id)

      if (result.error) { throw result.error }

      dispatch(setSettings({
        settings: merge({}, settings, updatedRow)
      }))
    } catch (e) {
      console.error(e)
      captureException(e)
      toast.error('Unable to save your preference.  We will ask again later.')
    }
  }, [settings, client, dispatch, captureException])

  const confirm = useCallback<() => Promise<boolean>>(async () => {
    if (neverShowAgain) {
      return true
    }
    
    return await show({
      title: 'Email Method',
      message: 
        <div className="disclaimer">
          <p>
            Email is NOT a secure method of submitting your documents.
            It is possible that your documents could be intercepted by a third party.
            If you are concerned about the security of your documents, please use the Browser Method.
          </p>
          <p>
            Would you like to continue with the Email Method?
          </p>
        </div>,
      actions: [
        {
          title: 'Yes',
          isConfirm: true,
        },
        {
          title: 'Yes and don\'t ask again',
          style: 'outline-primary',
          isConfirm: true,
          onClick: async () => {
            setPreference(true)
          }
        },
        {
          title: 'No',
          isCancel: true,
        },
      ]
    })
  }, [neverShowAgain, setPreference, show])
  
  return [
    confirm,
  ] as const
}
