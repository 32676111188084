import { format as _format, toDate, utcToZonedTime } from 'date-fns-tz'

const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function formatDateInTimeZone(date: string | Date | number, options?: { format?: string, timeZone?: string }): string {
  const format = options?.format || 'yyyy-MM-dd\'T\'HH:mm:ssXXX'
  const timeZone = options?.timeZone || browserTimeZone

  const inTimeZone = parseDateInTimeZone(date, { format, timeZone })
  return _format(inTimeZone, format , { timeZone })
}

export function parseDateInTimeZone(date: string | Date | number, options?: { format?: string, timeZone?: string }): Date {
  const timeZone = options?.timeZone || browserTimeZone

  const inUtc = toDate(date, { timeZone })
  const inTimeZone = utcToZonedTime(inUtc, timeZone)
  return inTimeZone
}
