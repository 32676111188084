import { getOrCreateSettingsForUser } from "../../lib/queries/getOrCreateSettingsForUser"
import { useClient, useSession } from "../providers/supabase"
import { setSettings } from "../reduxToolkit/membershipSlice"
import { useAppDispatch } from "./reduxToolkit"
import { useAsyncEffect } from "./useAsyncEffect"

export function useLoadSettingsFromServer() {
  const client = useClient()
  const dispatch = useAppDispatch()
  const userId = useSession()?.user?.id

  return useAsyncEffect(async () => {
    if (!userId) { return }

    const settings = await getOrCreateSettingsForUser(client, { userId: userId })
    dispatch(setSettings({ settings }))
  }, [client, dispatch, userId])
}
