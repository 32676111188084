import { useDispatch } from "react-redux"
import { useClient } from "../providers/supabase"
import { useAsyncAction } from "./useAsyncAction"
import { useAppSelector } from "./reduxToolkit"
import { DependentRow } from "../../types/supabase"
import { NotNull } from "../../types/util"
import { selectUsersAndDependentsForMembership } from "../../lib/queries/selectUsersAndDependentsForMembership"
import { onSyncDependents } from "../reduxToolkit/dependentsSlice"

export function useUpdateDependents() {
  const client = useClient()
  const dispatch = useDispatch()
  const membershipId = useAppSelector((s) => s.membership.membershipId)
  
  return useAsyncAction(async (unsavedDependent: NotNull<Partial<DependentRow>, 'id'>) => {
    const now = new Date().toISOString()
    const newDependentRow = {
      ...unsavedDependent,
      updated_at: now
    }
    const response = await client.from('dependents')
      .update(newDependentRow)
      .eq('id', unsavedDependent.id)

    if (response.error) {
      throw response.error
    }
    
    // reload dependents from server
    const result = await selectUsersAndDependentsForMembership(client, { membershipId })

    dispatch(onSyncDependents({
      ...result
    }))

    return response
  }, [client, membershipId])
}
