interface TextractOptInCheckboxProps {
  value: boolean | null
  onChange: (value: boolean) => void
  
  disabled?: boolean
  className?: string
}

export function TextractOptInCheckbox({value, disabled, className, onChange}: TextractOptInCheckboxProps) {
  return <>
    <div className={`form-check ${className} ${disabled && 'disabled'}`}>
      <input className="form-check-input" type="checkbox" id="textract-opt-in-checkbox"
        disabled={disabled}
        checked={value || false}
        onChange={(e) => { onChange(e.target.checked) }} />
      <label className="form-check-label" htmlFor="textract-opt-in-checkbox">
        Opt-In to Automatic Document Scanning
      </label>
    </div>
    <div className="disclaimer">
      <p>
        I understand that document scanning is an experimental feature and that I may need to
        manually verify the results of scanning.  I understand that HealthShare tech makes no guarantees
        about the accuracy of the scanned data.
      </p>
      <p>
        Healthshare Tech does not use your scanned data for any purpose other than to populate your CHM forms.
        See our <a href="https://app.termly.io/document/privacy-policy/bf079259-ff12-4a46-9167-dbb4415f71c1" target='_blank' rel="noreferrer">Privacy Policy</a> for
        more information.
      </p>
    </div>
  </>
}
