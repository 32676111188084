import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AttachmentInsert, AttachmentRow, NotDeleted } from '../../types/supabase'
import type { DeepPartialNullable, NotNull } from '../../types/util'
import { PURGE } from 'redux-persist';
import { replaceOrInsert } from '../../lib/util/replaceOrInsert'
import { redactObjectExceptKeys } from '../../lib/util/redact'
import { onSyncDownComplete } from './actions/onSyncDownComplete';

export type AttachableTable = 'expenses' | 'incidents' | 'submissions'
export type AttachmentModel = NotNull<NotDeleted<AttachmentInsert>, 'id' | 'updated_at'>

type AttachmentInsertPayload = AttachmentModel

export type AttachmentsSliceState = {
  attachments: Array<AttachmentModel>

  lastLoadFromServer?: number
}

const initialState: AttachmentsSliceState = {
  attachments: []
}

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    createAttachment(state, action: PayloadAction<AttachmentInsertPayload & { table_name: AttachableTable }>) {
      if (!state.attachments) { state.attachments = [] }
      const i = state.attachments.findIndex((a) => a.id === action.payload.id)
      if (i >= 0) {
        state.attachments[i] = action.payload
      } else {
        state.attachments.push(action.payload)
      }
    },
    deleteAttachment(state, action: PayloadAction<{ id: string, updated_at: string, deleted_at: string }>) {
      // Remove it out of the attachments array
      if (!state.attachments) { state.attachments = [] }
      const i = state.attachments.findIndex((e) => e.id === action.payload.id)
      if (i >= 0) {
        state.attachments.splice(i, 1)
      }
    },
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    }).addCase(onSyncDownComplete, (state, action) => {
      for (const attachment of action.payload.attachments?.updated || []) {
        replaceOrInsert(state.attachments, attachment)
      }
      for (const deletedAttachment of action.payload.attachments?.deleted || []) {
        const i = state.attachments.findIndex((t) => t.id === deletedAttachment.id)
        if (i >= 0 && state.attachments[i].updated_at <= deletedAttachment.updated_at) {
          state.attachments.splice(i, 1)
        }
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  createAttachment,
  deleteAttachment
} = attachmentsSlice.actions

export type AttachmentsSliceAction = ReturnType<typeof attachmentsSlice.actions[keyof typeof attachmentsSlice.actions]>

export function isAttachmentsSliceAction(action: any): action is AttachmentsSliceAction {
  return action.type?.startsWith(attachmentsSlice.name)
}

export default attachmentsSlice.reducer

/*
 * Redact all sensitive information from the attachments, so that they can be sent to Analytics tools like
 * Sentry or Amplitude.
 * Due to HIPAA compliance, we cannot send any patient information to these tools.
 * The redaction operates with a whitelist to ensure that we don't accidentally send any sensitive information in the future.
*/

export function redactAttachments(state: AttachmentsSliceState): DeepPartialNullable<AttachmentsSliceState> {
  return {
    attachments: state.attachments?.map(redactAttachment)
  }
}

export function redactAttachment(attachment: Partial<AttachmentRow>): DeepPartialNullable<AttachmentRow> {
  return {
    ...redactObjectExceptKeys(attachment,
      'id', 'created_at', 'updated_at', 'created_by_user_id', 'membership_id', 'record_id', 'table_name', 'blob_key', 'deleted_at'),
  }
}

export function redactAttachmentsSliceAction(action: AttachmentsSliceAction) {
  switch(action.type) {
    case createAttachment.type:
    case deleteAttachment.type:
      return {
        type: action.type,
        payload: redactAttachment(action.payload)
      }

    default:
      return {
        type: (action as any).type,
        payload: 'REDACTED'
      }
  }
}

