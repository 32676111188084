
export function convertServerTimestampsToISO<T extends Record<string, any>>(record: T): T {
  const result = { ...record }
  for (const key in result) {
    if (/_at$/.test(key) && typeof result[key] === 'string') {
      try {
        const dt = new Date(result[key])
        // If we parsed zero, skip b/c this is likely incorrect
        if (dt.getTime() === 0) continue

        result[key] = dt.toISOString() as T[typeof key]
      } catch (e) {
        // skip if invalid date
        if (e instanceof RangeError) continue
        throw e
      }
    }
  }
  return result
}
