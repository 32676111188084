import { SupabaseClient, User } from "@supabase/supabase-js"
import { DependentRow, MembershipRow } from "../../types/supabase"
import { ExpenseModel } from "../../app/reduxToolkit/expensesSlice"
import { downloadPdf } from "../pdf/downloadPdf"
import { LetterOfExplanationExtraData, fillLetterOfExplanation } from "../pdf/2023/letterOfExplanation"
import { fillMedicalBillWorksheet, MedicalBillWorksheetData } from "../pdf/2023/medicalBillWorksheet"
import { fillMedicalReleaseHipaaForm } from "../pdf/2023/medicalReleaseHipaaForm"
import { SharingRequestFormExtraData, fillSharingRequestForm } from "../pdf/2023/sharingRequestForm"
import { IncidentModel } from "../../app/reduxToolkit/incidentsSlice"
import { ProfileModel } from "../../app/reduxToolkit/membershipSlice"

export const documentMapping = {
  'MedicalBillWorksheet.pdf': '/documents/2023/MedicalBillWorksheet.pdf',
  'SharingRequestForm.pdf': '/documents/2023/SharingRequestForm.pdf',
  'MedicalRelease(HIPAA)Form.pdf': '/documents/2023/MedicalReleaseForm.pdf',
  'LetterOfExplanation.pdf': '/documents/2023/LetterofExplanation.pdf'
}

type DocumentName = keyof typeof documentMapping

export const documentPack = Object.keys(documentMapping) as Readonly<DocumentName[]>

interface GenerateDocumentsData {
  user: Pick<User, 'email'>,
  profile: ProfileModel,
  dependent: DependentRow | null,
  membership: Pick<MembershipRow, 'member_number'>,
  incident: IncidentModel,
  expenses: ExpenseModel[]
}

export async function generateDocuments2023(
  data: GenerateDocumentsData,

  extraData?: {
    sharingRequestFormData?: SharingRequestFormExtraData,
    letterOfExplanationData?: LetterOfExplanationExtraData
  }
) {
  type DocumentPackTmp = {
    [k in DocumentName]: Promise<ArrayBuffer>
  }

  const todo = Object.keys(documentMapping) as DocumentName[]

  // download all in parallel
  const downloaded: DocumentPackTmp =
    todo.reduce((record, key) => {
      record[key] = downloadPdf(documentMapping[key])
      return record
    }, {} as DocumentPackTmp)

  // fill in some in parallel
  const transformed: DocumentPackTmp = {
    ...downloaded,
    'MedicalBillWorksheet.pdf': downloaded["MedicalBillWorksheet.pdf"].then((pdfBytes) =>
      fillMedicalBillWorksheet(pdfBytes, data)),
    'SharingRequestForm.pdf': downloaded["SharingRequestForm.pdf"].then((pdfBytes) =>
      fillSharingRequestForm(pdfBytes, data, extraData?.sharingRequestFormData)),
    'LetterOfExplanation.pdf': downloaded['LetterOfExplanation.pdf'].then((pdfBytes) =>
      fillLetterOfExplanation(pdfBytes, data, extraData?.letterOfExplanationData)),
    'MedicalRelease(HIPAA)Form.pdf': downloaded['MedicalRelease(HIPAA)Form.pdf'].then((pdfBytes) =>
      fillMedicalReleaseHipaaForm(pdfBytes, data))
  }

  const files = await Promise.all(
    (Object.keys(transformed) as Array<keyof DocumentPackTmp>)
    .map((key) => {
      return transformed[key].then((pdfBytes) =>
        new File([pdfBytes], key, { type: 'application/pdf' })
      )
    })
  )

  return files
}

export async function generateAddOn2023(
  data: MedicalBillWorksheetData,
  fileName?: string
) {
  let bytes = await downloadPdf(documentMapping['MedicalBillWorksheet.pdf'])
  bytes = await fillMedicalBillWorksheet(bytes, data, { isAddOn: true })

  return new File([bytes], fileName || `MedicalBillWorksheet_addon.pdf`, { type: 'application/pdf' })
}
