import { ExpenseModel } from "../../../app/reduxToolkit/expensesSlice"
import { IncidentModel } from "../../../app/reduxToolkit/incidentsSlice"
import { formatDateInTimeZone } from "../../formatDateInTimeZone"
import {fillPdf} from '../fillPdf'
import { present } from "../../util/present"
import { ProfileModel } from "../../../app/reduxToolkit/membershipSlice"

const formFields = [
  'Patient Name',
  'DATE of serviceRow1',
  'fill_36',
  'fill_37',
  'DATE of serviceRow2',
  'fill_39',
  'fill_40',
  'DATE of serviceRow3',
  'fill_42',
  'fill_43',
  'DATE of serviceRow4',
  'fill_45',
  'fill_46',
  'DATE of serviceRow5',
  'fill_48',
  'fill_49',
  'DATE of serviceRow6',
  'fill_51',
  'fill_52',
  'DATE of serviceRow7',
  'fill_54',
  'fill_55',
  'DATE of serviceRow8',
  'fill_57',
  'fill_58',
  'DATE of serviceRow9',
  'fill_59',
  'fill_60',
  'fill_61',
  'DATE of serviceRow10',
  'fill_62',
  'fill_63',
  'fill_64',
  'DATE of serviceRow11',
  'fill_65',
  'fill_66',
  'fill_67',
  'DATE of serviceRow12',
  'PROVIDER doctor hospital pharmacy etcRow12',
  'fill_68',
  'fill_69',
  'fill_70',
  'DATE of serviceRow13',
  'PROVIDER doctor hospital pharmacy etcRow13',
  'fill_71',
  'fill_72',
  'fill_73',
  'DATE of serviceRow14',
  'PROVIDER doctor hospital pharmacy etcRow14',
  'fill_74',
  'fill_75',
  'fill_76',
  'DATE of serviceRow15',
  'PROVIDER doctor hospital pharmacy etcRow15',
  'fill_77',
  'fill_78',
  'fill_79',
  'DATE of serviceRow16',
  'PROVIDER doctor hospital pharmacy etcRow16',
  'fill_80',
  'fill_81',
  'fill_82',
  'If  yes which incident',
  'Member1',
  'DOB',
  'PROVIDER doctor hospital pharmacy etcRow1',
  'PROVIDER doctor hospital pharmacy etcRow2',
  'PROVIDER doctor hospital pharmacy etcRow3',
  'PROVIDER doctor hospital pharmacy etcRow4',
  'PROVIDER doctor hospital pharmacy etcRow5',
  'PROVIDER doctor hospital pharmacy etcRow8',
  'PROVIDER doctor hospital pharmacy etcRow9',
  'PROVIDER doctor hospital pharmacy etcRow10',
  'PROVIDER doctor hospital pharmacy etcRow11',
  'fill_35',
  'fill_38',
  'fill_41',
  'fill_44',
  'fill_47',
  'fill_50',
  'fill_53',
  'fill_56',
  'Total Charge',
  'Total Reduction',
  'Total Payments',
  'Yes',
  'PROVIDER doctor hospital pharmacy etcRow6',
  'PROVIDER doctor hospital pharmacy etcRow7',
  'No',
  'Check Box1',
  'Check Box2'
] as const

type FormField = typeof formFields[number]

/** The field names of each table row 1-16 */
const tableRowFields: Array<[FormField, FormField, FormField, FormField, FormField]> = [
  ["DATE of serviceRow1", "PROVIDER doctor hospital pharmacy etcRow1", "fill_35", "fill_36", "fill_37"],
  ["DATE of serviceRow2", "PROVIDER doctor hospital pharmacy etcRow2", "fill_38", "fill_39", "fill_40"],
  ["DATE of serviceRow3", "PROVIDER doctor hospital pharmacy etcRow3", "fill_41", "fill_42", "fill_43"],
  ["DATE of serviceRow4", "PROVIDER doctor hospital pharmacy etcRow4", "fill_44", "fill_45", "fill_46"],
  ["DATE of serviceRow5", "PROVIDER doctor hospital pharmacy etcRow5", "fill_47", "fill_48", "fill_49"],
  ["DATE of serviceRow6", "PROVIDER doctor hospital pharmacy etcRow6", "fill_50", "fill_51", "fill_52"],
  ["DATE of serviceRow7", "PROVIDER doctor hospital pharmacy etcRow7", "fill_53", "fill_54", "fill_55"],
  ["DATE of serviceRow8", "PROVIDER doctor hospital pharmacy etcRow8", "fill_56", "fill_57", "fill_58"],
  ["DATE of serviceRow9", "PROVIDER doctor hospital pharmacy etcRow9", "fill_59", "fill_60", "fill_61"],
  ["DATE of serviceRow10", "PROVIDER doctor hospital pharmacy etcRow10", "fill_62", "fill_39", "fill_40"],
  ["DATE of serviceRow11", "PROVIDER doctor hospital pharmacy etcRow11", "fill_65", "fill_66", "fill_67"],
  ["DATE of serviceRow12", "PROVIDER doctor hospital pharmacy etcRow12", "fill_68", "fill_69", "fill_70"],
  ["DATE of serviceRow13", "PROVIDER doctor hospital pharmacy etcRow13", "fill_71", "fill_72", "fill_73"],
  ["DATE of serviceRow14", "PROVIDER doctor hospital pharmacy etcRow14", "fill_74", "fill_75", "fill_76"],
  ["DATE of serviceRow15", "PROVIDER doctor hospital pharmacy etcRow15", "fill_77", "fill_78", "fill_79"],
  ["DATE of serviceRow16", "PROVIDER doctor hospital pharmacy etcRow16", "fill_80", "fill_81", "fill_82"],
]

export interface MedicalBillWorksheetData {
  profile: ProfileModel | null | undefined,
  membership: { member_number?: string | null },
  incident: Pick<IncidentModel, 'patient_name' | 'patient_dob' | 'start_date'>,
  expenses: ExpenseModel[]
}

export async function fillMedicalBillWorksheet(
  pdfBytes: string | Uint8Array | ArrayBuffer,
  {profile, membership, incident, expenses}: MedicalBillWorksheetData,
  options?: { isAddOn?: true }
): Promise<Uint8Array> {

  if (expenses.length > 16) {
    throw new Error(`Only up to 16 expenses are supported`)
  }

  const fields: { [k in FormField]?: string | boolean | null | undefined } = {
    "Member1": membership?.member_number
  }
  
  const patient: { patient_name: string, patient_dob: string } | null =
    present(incident?.patient_name) && present(incident?.patient_dob) ?
      incident :
      present(profile?.full_name) && present(profile?.date_of_birth) ?
        {
          patient_name: profile!.full_name,
          patient_dob: profile!.date_of_birth
        } : null

  if (patient) {
    fields["Patient Name"] = patient.patient_name
    fields.DOB = formatDateInTimeZone(patient.patient_dob, { format: 'MMddyy' })
  }

  // fill out each expense row
  let totalCharge = 0.0
  let totalReduction = 0.0
  let totalPayments = 0.0
  expenses.forEach((expense, i) => {
    const f = tableRowFields[i]
    if (present(expense.date)) {
      fields[f[0]] = formatDateInTimeZone(expense.date, { format: 'MM/dd/yyyy' })
    }
    fields[f[1]] = expense.provider
    if (expense.listedAmount) {
      const listedAmount = parseFloat(expense.listedAmount)
      totalCharge += listedAmount
      fields[f[2]] = listedAmount.toFixed(2)
    }
    if (expense.listedAmount && expense.paidAmount) {
      const reduction = parseFloat(expense.listedAmount) - parseFloat(expense.paidAmount)
      totalReduction += reduction
      fields[f[3]] = reduction.toFixed(2)
    }
    if (expense.paidAmount) {
      const paidAmount = parseFloat(expense.paidAmount)
      totalPayments += paidAmount
      fields[f[4]] = paidAmount.toFixed(2)
    }
  })

  fields["Total Charge"] = totalCharge.toFixed(2)
  fields["Total Reduction"] = totalReduction.toFixed(2)
  fields["Total Payments"] = totalPayments.toFixed(2)

  if (options?.isAddOn) {
    fields['Check Box1'] = true
    fields['Check Box2'] = false
    fields["If  yes which incident"] = formatDateInTimeZone(incident.start_date, { format: 'MM/dd/yyyy' })
  } else {
    fields['Check Box1'] = false
    fields['Check Box2'] = true
  }

  return fillPdf(pdfBytes, fields)
}
