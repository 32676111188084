import React from 'react';
import { createRoot } from 'react-dom/client';
import 'jquery';
import 'bootstrap';
import reportWebVitals from './reportWebVitals';
import { Providers } from './app/providers';
import { ErrorBoundary } from './app/components/error-boundary';
import { Initializers } from './app/initializers';
import { Listeners } from './app/listeners';

import App from './App';
import './index.css';
import './App.scss';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  //<React.StrictMode>
    <ErrorBoundary>
      <Providers>
        <Initializers>
          <Listeners />
          <App />
        </Initializers>
      </Providers>
    </ErrorBoundary>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
