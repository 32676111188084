import { ChonkyActions, defineFileAction, MapFileActionsToData } from 'chonky';
import JSZip from 'jszip';
import path from 'path-browserify';
import { AppSupabaseClient, BlobRow, isFile } from '../../../types/supabase';
import { ExtensionToMimeType } from '../../../lib/extensionToMimeType';
import { downloadAll } from '../../../lib/supabase/storage/downloadAll';
import { listAll } from '../../../lib/supabase/storage/listAll';
import { downloadFile } from '../../../lib/util/downloadFile';
import { openFile } from '../../../lib/util/openFile';

export type CustomActionUnion =
  typeof DownloadAllAsZip |
  typeof DownloadSelection

const privateBucket = 'wcc-hra-calculator-private'

export const DownloadAllAsZip = defineFileAction({
    id: 'download_all_as_zip',
    button: {
        name: 'Download as Zip Archive',
        toolbar: true,
        group: 'Actions',
        icon: 'archive'
    },
} as const);

export async function handleDownloadAllAsZip(
  data: MapFileActionsToData<typeof DownloadAllAsZip>,
  deps: {
    files: {
      [folderPath: string]: BlobRow[]
    }
    baseFolder: string,
    baseFolderName?: string,
    client: AppSupabaseClient
  }
): Promise<void> {
  const {baseFolder, baseFolderName, files, client} = deps

  const filesToDownload: Array<BlobRow & { folderPath: string }> =
    blobsUnderFolderPath(files, baseFolder)

  const zip = new JSZip()

  await downloadAll(
    privateBucket,
    filesToDownload.map((f) => f.file_full_path),
    client.storage,
    async (file) => {
      const blob = filesToDownload.find((b) => b.file_full_path === file.name)
      const fullFileName = blob ? path.join(blob.folderPath, blob.file_name) : file.name
      let fileNameInZip = path.relative(baseFolder, fullFileName)
      const originalFileName = fileNameInZip
      if (!fileNameInZip) { throw new Error(`Unable to find relative path for ${blob?.folderPath} or ${file.name} in ${baseFolder}`) }

      let count = 0
      while (zip.file(fileNameInZip)) {
        count += 1
        fileNameInZip = path.basename(originalFileName, path.extname(originalFileName)) + `(${count})` + path.extname(originalFileName)
      }
      zip.file(fileNameInZip, await file.data.arrayBuffer())
    }
  )

  const zipBlob = await zip.generateAsync({ type: 'blob' })
  downloadFile(path.basename(baseFolderName || baseFolder) + '.zip', zipBlob)
}

export const DownloadSelection = defineFileAction({
  id: 'download_selection',
  requiresSelection: true,
  fileFilter: (f) => !f?.isDir,
  button: {
    name: 'Download',
    contextMenu: true,
    icon: 'download'
  }
} as const)

export async function handleDownloadSelection(
  data: MapFileActionsToData<typeof DownloadSelection>,
  deps: {
    files: {
      [folderPath: string]: BlobRow[]
    }
    baseFolder: string,
    client: AppSupabaseClient
  }
): Promise<void> {
  const {files, baseFolder, client} = deps

  const blobKeys =
    data.state.selectedFilesForAction
      .filter((f) => !f.isDir)
      .map((f) => f.key)

  const filesToDownload = blobsUnderFolderPath(files, baseFolder)
    .filter((f) => blobKeys.includes(f.key))

  await downloadAll(
    privateBucket,
    filesToDownload.map((f) => f.file_full_path),
    client.storage,
    (file) => {
      const blob = filesToDownload.find((b) => b.file_full_path === file.name)
      const fileName = path.basename(blob?.file_name || file.name)
      downloadFile(fileName, file.data)
    }
  )
}

export async function handleOpenSelection(
  data: MapFileActionsToData<typeof ChonkyActions.OpenSelection>,
  deps: {
    files: {
      [folderPath: string]: BlobRow[]
    }
    baseFolder: string,
    setFolder: (folder: string) => void
    client: AppSupabaseClient
  }
) {
  const {client, setFolder, files, baseFolder} = deps

  if (data.state.selectedFilesForAction.length <= 0) {
    return
  }
  if (data.state.selectedFilesForAction.length == 1 && data.state.selectedFilesForAction[0].isDir) {
    setFolder(data.state.selectedFilesForAction[0].id)
    return
  }

  const blobKeys =
    data.state.selectedFilesForAction
      .filter((f) => !f.isDir)
      .map((f) => f.key)

  const filesToDownload = blobsUnderFolderPath(files, baseFolder)
    .filter((f) => blobKeys.includes(f.key))

  await downloadAll(
    privateBucket,
    filesToDownload.map((f) => f.file_full_path),
    client.storage,
    (file) => {
      const blobRow = filesToDownload.find((b) => b.file_full_path === file.name)
      const fileName = path.basename(blobRow?.file_name || file.name)
      // Supabase stores all our files as text/plain, so we need to change the mime type
      const blob = new Blob([file.data], {type: blobRow?.content_type || ExtensionToMimeType[path.extname(file.name)] })
      openFile(blob, fileName)
    }
  )
}

export async function handleOpenFiles(
  data: MapFileActionsToData<typeof ChonkyActions.OpenFiles>,
  deps: {
    files: {
      [folderPath: string]: BlobRow[]
    }
    baseFolder: string,
    setFolder: (folder: string) => void
    client: AppSupabaseClient
  }
) {
  const {client, setFolder, files, baseFolder} = deps

  const file = data.payload.targetFile
  if (!file) { return }

  if (file.isDir) {
    return setFolder(file.id)
  }

  const blobToDownload = blobsUnderFolderPath(files, baseFolder)
    .find((blob) => blob.key === file.key)
  if (!blobToDownload) { throw new Error(`Could not find blob with key ${file.key}`) }

  const resp = await client.storage
    .from(privateBucket)
    .download(blobToDownload.file_full_path)
  if (resp.error) { throw resp.error }

  // Supabase stores all our files as text/plain, so we need to change the mime type
  const blob = new Blob([resp.data], { type: blobToDownload.content_type })
  openFile(blob, blobToDownload.file_name)
}

function blobsUnderFolderPath(
  files: {
    [folderPath: string]: BlobRow[]
  },
  baseFolder: string
): Array<BlobRow & { folderPath: string }> {
  return Object.keys(files)
  .filter((folderPath) => folderPath.startsWith(baseFolder))
  .flatMap((folderPath) => files[folderPath].map((blob) => {
    return {
      ...blob,
      folderPath
    }
  }))
}
