import { useCallback, useEffect, useState } from "react"
import $ from 'jquery'
import * as bootstrap from 'bootstrap'
import { ValidationError } from "../../validation"

interface FloatingActionButtonProps {
  home?: () => void
  advance: () => void
  
  errors: ValidationError[]
}

export function FloatingActionButtons(
  {
    home,
    advance,
    errors
  }: FloatingActionButtonProps
) {
  const [currentlyFocused, setCurrentlyFocused] = useState<string | null>(errors[0]?.field || null)

  const focusNext = useCallback(() => {
    const currentIdx = errors.findIndex(e => e.field === currentlyFocused)
    if (currentIdx === -1) {
      setCurrentlyFocused(errors[0].field)
      return
    } else {
      const nextIdx = (currentIdx + 1) % errors.length
      setCurrentlyFocused(errors[nextIdx].field)
    }
  }, [errors, currentlyFocused])
  
  useEffect(() => {
    if (!currentlyFocused) { return }
      
    const el = $(`#${currentlyFocused}`)
    if (!el.length) { return }

    // expand the parent accordion
    const button = el.closest('.accordion-item').find('.accordion-button')
    if (button.attr('aria-expanded') === 'false') {
      button.trigger('click')
    }
    // focus the input
    el[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    el.trigger('focus')
  }, [currentlyFocused])

  return <div className="submitToChm2024__FloatingButtons">
    {home && <button className="btn btn-text submitToChm2024__FloatingButtons-Home"
        onClick={home}>
      <i className="material-icons">home</i>
    </button>}
    
    <button className="btn btn-text submitToChm2024__FloatingButtons-Next"
        onClick={errors.length > 0 ? focusNext : advance}>
      <i className="material-icons">arrow_forward</i>
    </button>
  </div>
}
