import Rusha from 'rusha'
import * as Sentry from "@sentry/react";

let rushaWorker: ReturnType<typeof Rusha.createWorker>
let queued: {
  [id: string]: {
    resolve: (hash: string) => void
    reject: (err: Error) => void
  }
} = {}

export async function hashFileSha1(file: File) {
  if (!rushaWorker) { initWorker() }

  const msgId = Math.random().toString(36).substring(7)
  rushaWorker.postMessage({
    id: msgId,
    data: file,
  })


  return new Promise<string>((resolve, reject) => {
    queued[msgId] = { resolve, reject }
  })
}

function initWorker() {
  rushaWorker = Rusha.createWorker();

  rushaWorker.addEventListener('message', (e) => {
    if (e.data.id && queued[e.data.id]) {
      const { resolve, reject } = queued[e.data.id]
      if (e.data.error) {
        reject(new Error(e.data.error))
      } else {
        resolve(e.data.hash)
      }
      delete queued[e.data.id]
    }
  })

  rushaWorker.addEventListener('messageerror', (e) => {
    console.log('rusha message error', e)
    Sentry.captureException(e)
  })
}
