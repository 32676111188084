import { PdfPreview } from "./preview/pdfPreview";

export interface FilePreviewProps {
  file: File,
  onDelete?: () => void
}

export function FilePreview({file, onDelete}: FilePreviewProps) {

  return <div className="card" onDoubleClick={() => {
    window.open(URL.createObjectURL(file))
  }}>
    <div className="card-body">
      <div className="card-text">
        {file.type === 'application/pdf' ?
          <PdfPreview file={file} /> :
          <img src={URL.createObjectURL(file)} alt='preview' />}

        {onDelete &&
          <button className='btn btn-link quick-add__blob__delete'
              onClick={onDelete}>
            <i className='material-icons'>delete</i>
          </button>}
      </div>
    </div>
  </div>
}
