import { present } from "../../../lib/util/present"
import type { Patient } from "../dependentsSlice"
import type { RootState } from "../store"

export function selectPatientsByUnit(s: RootState): { [unitId: string]: Patient[] } {
  const patientsByUnit: { [unitId: string]: Patient[] } = {}
  if (!s.dependents?.lastSync) {
    return patientsByUnit
  }

  const {dependents, profiles} = s.dependents.lastSync
  const membershipId = s.membership?.membershipId
  if (!membershipId) {
    return patientsByUnit
  }

  // According to CHM, each adult is one unit and all children are combined into one unit.
  // https://www.chministries.org/understanding-units/

  profiles?.forEach((p) => {
    if (present(p.full_name) && present(p.date_of_birth)) {
      patientsByUnit[`${membershipId}/${p.full_name}_${p.date_of_birth}`] = [{
        date_of_birth: p.date_of_birth,
        full_name: p.full_name
      }]
    }
  })
  dependents?.forEach((d) => {
    if (!d.relationship) { return }
    
    switch(d.relationship) {
      case 'spouse':
        patientsByUnit[`${membershipId}/${d.full_name}_${d.date_of_birth}`] = [{
          date_of_birth: d.date_of_birth,
          full_name: d.full_name
        }]
        break;
      case 'child':
        const children = patientsByUnit[`${membershipId}/children`] || []

        patientsByUnit[`${membershipId}/children`] = [...children, {
          date_of_birth: d.date_of_birth,
          full_name: d.full_name
        }]
        break;
      default:
        raiseUnexpectedRelationship(d.relationship)   
    }
  })

  return patientsByUnit
}

function raiseUnexpectedRelationship(relationship: never): never {
  throw new Error(`Unexpected relationship: ${relationship}`)
}
