
import { useState } from 'react'
import './checklist.scss'

interface ChecklistProps {
  items: React.ReactNode[]

  className?: string

  onChange?: (params: {completed: boolean, stepsCompleted: number[]}) => void
}

export function Checklist({ items, className, onChange }: ChecklistProps) {
  const [stepsCompleted, setStepsCompleted] = useState<number[]>([])

  const isCompleted = (stepNumber: number) => stepsCompleted.includes(stepNumber)

  const canCheckOff = (stepNumber: number) => {
    // can't check if it's already checked
    if (stepsCompleted.includes(stepNumber)) { return false }
    // can't check until all previous steps are checked
    for (let i = 0; i < stepNumber; i++) {
      if (!stepsCompleted.includes(i)) { return false }
    }
    return true
  }

  const toggleStep = (stepNumber: number) => {
    let newStepsCompleted: number[] | undefined = undefined
    if (isCompleted(stepNumber)) {
      newStepsCompleted = stepsCompleted.filter((s) => s < stepNumber)
    } else {
      if (canCheckOff(stepNumber)) {
        newStepsCompleted = [...stepsCompleted, stepNumber]
      }
    }

    if (newStepsCompleted) {
      setStepsCompleted(newStepsCompleted)
      if (onChange) {
        onChange({
          completed: newStepsCompleted.length === items.length,
          stepsCompleted: newStepsCompleted
        })
      }
    }
  }

  return <ul className={`checklist ${className}`}>
          {items.map((item, stepNumber) =>
            <li key={stepNumber}
                className={`checklist__item ${isCompleted(stepNumber) ? 'completed' : ''}`}
                onClick={() => toggleStep(stepNumber)}>
              {item}
              {isCompleted(stepNumber) ?
                <i className="material-icons">check_box</i> :
                <i className="material-icons">check_box_outline_blank</i>}
            </li>
          )}
        </ul>
}
