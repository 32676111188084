import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"

export function SignInRedirectWithReturnTo() {
  const location = useLocation()
  const navigate = useNavigate()
  
  useEffect(() => {
    // On load, save the current location to local storage and redirect to sign in
    localStorage.setItem('returnTo', location.pathname)
    console.info('SignInRedirectWithReturnTo', location.pathname)
    navigate('/signIn/email')
  }, [location])

  return <div className="sign-in__redirect">
    <div className="sign-in__redirect__content">
      <h1>Sign In Required</h1>
      <Link to="/signIn/email">
        Go to Sign In
      </Link>
    </div>
  </div>
}
