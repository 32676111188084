import { SubmissionData2024CHM } from "../../../app/reduxToolkit/submissionsSlice";
import { fillPdf } from "../../pdf/fillPdf";
import { writeSignature } from "../../pdf/writeSignature";
import { present } from "../../util/present";
import { splitDateParts } from "../../util/splitDateParts";

const fields = [
  "member_number_4",
  "patient_name_4",
  "expected_due_date_month",
  "expected_due_date_day",
  "expected_due_date_year",
  "babys_dob_month",
  "babys_dob_day",
  "babys_dob_year",
  "other_fop_text",
  "fop_start_date_month",
  "fop_start_date_day",
  "fop_start_date_year",
  "fop_end_date_month",
  "fop_end_date_day",
  "fop_end_date_year",
  "Member name",
  "Member signature",
  "consent_signature_date_month",
  "consent_signature_date_day",
  "consent_signature_date_year",
  "hospital_yes",
  "birthing_center_yes",
  "home_yes",
  "chosen_hospital_yes",
  "chosen_hospital_no",
  "fop_primary_insurance_yes",
  "fop_medicaid_yes",
  "fop_financial_assistance_yes",
  "fop_other_yes"
] as const

type MaternityVerificationFormField = typeof fields[number]
type MaternityVerificationFormData = {
  [k in MaternityVerificationFormField]?: string | boolean | null | undefined
}

export async function fillMaternityVerificationForm2024(
  pdfBytes: ArrayBuffer,
  data: SubmissionData2024CHM['maternityVerificationFormData']
): Promise<ArrayBuffer> {
  
  const {
    day: memberSignatureDay,
    month: memberSignatureMonth,
    year: memberSignatureYear
  } = splitDateParts(data.consentDate)
  
  const {
    day: paymentFormsStartDateDay,
    month: paymentFormsStartDateMonth,
    year: paymentFormsStartDateYear
  } = splitDateParts(data.paymentFormsStartDate)
  
  const {
    day: paymentFormsEndDateDay,
    month: paymentFormsEndDateMonth,
    year: paymentFormsEndDateYear
  } = splitDateParts(data.paymentFormsEndDate)
 
  const formData: MaternityVerificationFormData = {
    member_number_4: data.memberNumber,
    patient_name_4: data.patientName,
    
    hospital_yes: data.deliveryLocation === 'hospital',
    birthing_center_yes: data.deliveryLocation === 'birthing-center',
    home_yes: data.deliveryLocation === 'home',
    
    chosen_hospital_yes: data.chosenHospital === true,
    chosen_hospital_no: data.chosenHospital === false,
    
    fop_primary_insurance_yes: data.paymentForms === 'primary-insurance',
    fop_medicaid_yes: data.paymentForms === 'medicaid',
    fop_financial_assistance_yes: data.paymentForms === 'financial-assistance',
    fop_other_yes: data.paymentForms === 'other',
    other_fop_text: data.otherPaymentForms,
    fop_start_date_month: paymentFormsStartDateMonth,
    fop_start_date_day: paymentFormsStartDateDay,
    fop_start_date_year: paymentFormsStartDateYear,
    fop_end_date_month: paymentFormsEndDateMonth,
    fop_end_date_day: paymentFormsEndDateDay,
    fop_end_date_year: paymentFormsEndDateYear,
    
    "Member name": data.consentMemberName,
    consent_signature_date_day: memberSignatureDay,
    consent_signature_date_month: memberSignatureMonth,
    consent_signature_date_year: memberSignatureYear,
  }

  if (data.isActualDateOfBirth) {
    const {
      day: babysDobDay,
      month: babysDobMonth,
      year: babysDobYear
    } = splitDateParts(data.dueDate)
    formData.babys_dob_day = babysDobDay
    formData.babys_dob_month = babysDobMonth
    formData.babys_dob_year = babysDobYear
  } else {
    const {
      day: expectedDueDateDay,
      month: expectedDueDateMonth,
      year: expectedDueDateYear
    } = splitDateParts(data.dueDate)
    formData.expected_due_date_day = expectedDueDateDay
    formData.expected_due_date_month = expectedDueDateMonth
    formData.expected_due_date_year = expectedDueDateYear
  }

  pdfBytes = await fillPdf(pdfBytes, formData);
  
  if (present(data.signatureData)) {
    pdfBytes = await writeSignature(pdfBytes, {
      formField: 'Member signature',
      signature: data.signatureData
    })
  }
  
  return pdfBytes
}
