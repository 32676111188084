import { createAsyncThunk } from "@reduxjs/toolkit";
import {v4 as uuid4} from 'uuid'
import { createAttachment } from "../attachmentsSlice";
import { RootState } from "../store";
import { updateSubmission } from "../submissionsSlice";
import { updateIncident } from "../incidentsSlice";
import { updateExpense } from "../expensesSlice";

interface CompleteSubmissionPayload {
  submission: { id: string, membership_id: string, incident_id: string, data?: any },
  submittedByUserId: string,
  pendingExpenses: { id: string }[],
  blobs: { key: string }[],
}

export const completeSubmission = createAsyncThunk('submissions/completeSubmission',
  async (payload: CompleteSubmissionPayload, {getState, dispatch}) => {
    try {
    const { submission, submittedByUserId, pendingExpenses, blobs } = payload

    const state = getState() as RootState

    const submissionAttachments = state.attachments.attachments.filter((a) => a.record_id === submission.id)
    const blobsToAttach = blobs?.filter((b) => !submissionAttachments.find((a) => a.blob_key === b.key)) || []

    const now = new Date().toISOString()
    // Attach all the remaining blobs to the submission
    for (const blob of blobsToAttach) {
      dispatch(createAttachment({
        id: uuid4(),
        updated_at: now,
        blob_key: blob.key,
        membership_id: submission.membership_id,
        record_id: submission.id,
        table_name: 'submissions',
        created_at: now,
        created_by_user_id: submittedByUserId,
      }))
    }

    // Mark the submission as submitted
    dispatch(updateSubmission({
      id: submission.id,
      updated_at: now,
      submitted_at: now,
      submitted_by_user_id: submittedByUserId,
      data: submission.data,
      expense_ids: pendingExpenses.map((e) => e.id),
    }))

    dispatch(updateIncident({
      id: submission.incident_id,
      updated_at: now,
      submitted_at: now,
    }))

    // mark all expenses as submitted
    for (const expense of pendingExpenses) {
      dispatch(updateExpense({
        id: expense.id,
        updated_at: now,
        submitted_at: now,
        submission_id: submission.id,
      }))
    }
  } catch (e) {
    console.error(e)

  }
  })
