import { cloneDeep } from "lodash";
import { useMemo, useState, useCallback } from "react";
import { SubmissionData2024CHM } from "../../../../../reduxToolkit/submissionsSlice";
import { formatDateInTimeZone } from "../../../../../../lib/formatDateInTimeZone";
import { formatCurrency } from "../../../../../../lib/formatCurrency";
import { DropdownSelect } from "../../../../../components/dropdownSelect";
import { FloatingActionButtons } from "./components/floatingActionButtons";
import { ValidationError, validateMedicalBillWorksheetData2024 } from "../validation";
import { CheckboxYesNoInputGroup } from "./components/checkboxYesNoInputGroup";
import { Tooltip } from "../../../../../components/tooltip";
import { TextInputGroup } from "./components/textInputGroup";
import { FormSelectInputGroup } from "./components/formSelectInputGroup";

interface MedicalBillWorksheet2024Props<TData extends Pick<SubmissionData2024CHM, 'medicalBillWorksheetData'>> {
  data: TData
  updateData: (data: TData) => void
  advance: () => void
  home?: () => void
}

export function MedicalBillWorksheet2024<TData extends Pick<SubmissionData2024CHM, 'medicalBillWorksheetData'>>(
  {
    data: upstreamData,
    updateData,
    advance,
    home
  }: MedicalBillWorksheet2024Props<TData>
) {
  const originalData = useMemo(() => {
    return cloneDeep(upstreamData)
  }, [])
  const [data, setData] = useState(originalData.medicalBillWorksheetData)

  const errors = useMemo<ValidationError[]>(() => {
    return validateMedicalBillWorksheetData2024(data)
  }, [data])

  const changeData = useCallback(<TKey extends keyof SubmissionData2024CHM['medicalBillWorksheetData']>(key: TKey, value: SubmissionData2024CHM['medicalBillWorksheetData'][TKey]) => {
    setData((data) => {
      if (value === data[key]) { return data }

      const newData = {
        ...data,
        [key]: value
      }
      updateData({
        ...originalData,
        medicalBillWorksheetData: newData
      })
      return newData
    })
  }, [updateData, originalData])
  
  const updateExpense = useCallback((index: number, expense: SubmissionData2024CHM['medicalBillWorksheetData']['expenseRows'][number]) => {
    const newExpenseRows = [...data.expenseRows]
    newExpenseRows[index] = {
      ...newExpenseRows[index],
      ...expense
    }

    changeData('expenseRows', newExpenseRows)
  }, [changeData, data.expenseRows])
  
  const primaryInsuranceError = errors.find((e) => e.field === 'primaryInsurance')
  const primaryInsuranceOtherError = errors.find((e) => e.field === 'primaryInsuranceOther')
  
  return <div className="submitToChm2024-MedicalBillWorksheet">
    <FloatingActionButtons home={home} advance={advance} errors={errors} />

    <form onSubmit={((evt) => { evt?.preventDefault(); advance() })}>
      <div className="accordion" id='medical-bill-worksheet'>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button" type="button"
                data-bs-toggle='collapse' data-bs-target='#patient-and-illness'
                aria-expanded="true" aria-controls="patient-and-illness">
              Member Number and Patient Information
            </button>
          </h2>

          <div className="accordion-collapse collapse show" id='patient-and-illness' data-bs-parent='sharing-request-form'>
            <div className="accordion-body">
              <div className="input-group">
                <label className="input-group-text">
                  Member Number:
                </label>
                <div className={`form-control disabled`}>
                <Tooltip tooltip="Your CHM member number is set in your profile.  If this is wrong please contact us to correct it.">
                  {data.memberNumber}
                </Tooltip>
                </div>
              </div>
              
              <TextInputGroup
                field='patientName'
                label='Patient name:'
                value={data.patientName}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'patientName')} />

              <CheckboxYesNoInputGroup field={'isAddOn'}
                className="is-add-on"
                label={'Is this an add-on?'}
                value={data.isAddOn}
                changeData={(_, value) => {
                  changeData('isAddOn', value)
                  if (!value) {
                    changeData('isAddOnIncidentRefText', null)
                  }
                }}
                validationError={errors.find((e) => e.field === 'isAddOn')}
                />
                
              {data.isAddOn && <TextInputGroup
                field='isAddOnIncidentRefText'
                label='Which Incident?:'
                value={data.isAddOnIncidentRefText}
                changeData={changeData} />}
            </div>
          </div>
        </div>
    
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button"
                data-bs-toggle='collapse' data-bs-target='#primary-payment-options'
                aria-expanded="false" aria-controls="primary-payment-options">
              Primary payment options and financial assistance
            </button>
          </h2>
          
          <div className="accordion-collapse collapse" id='primary-payment-options' data-bs-parent='medical-bill-worksheet'>
            <div className="accordion-body">
              <p className="mb-2"><i>CHM is secondary to all other payment options; we request that you use any financial assistance resources when available.</i></p>

              <div className={`form-check form-check-inline ${primaryInsuranceError ? 'is-invalid' : 'other'}`}>
                <input className='form-check-input' type='checkbox'
                  name='primaryInsurance_yes' id='primaryInsurance_yes' value='yes'
                  checked={data.primaryInsurance === true}
                  onChange={
                    useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                      const value = evt.target.checked
                      changeData('primaryInsurance', value)
                      if (value) {
                        changeData('primaryInsuranceOther', false)
                        changeData('primaryInsuranceOtherText', null)
                      }
                    }, [changeData])
                  }
                  ></input>
                <label className="form-check-label" htmlFor="primaryInsurance_yes">Primary Insurance</label>
              </div>
              <div className="invalid-feedback">{primaryInsuranceError?.message}</div>
              
              <div className={`form-check form-check-inline ${primaryInsuranceOtherError ? 'is-invalid' : 'other'}`}>
                <input className='form-check-input' type='checkbox'
                  name='primaryInsuranceOther' id='primaryInsuranceOther' value='yes'
                  checked={data.primaryInsuranceOther === true}
                  onChange={
                    useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
                      const value = evt.target.checked
                      changeData('primaryInsuranceOther', value)
                      if (value) {
                        changeData('primaryInsurance', false)
                      } else {
                        changeData('primaryInsuranceOtherText', null)
                      }
                    }, [changeData])
                  }
                  ></input>
                  <label className="form-check-label" htmlFor="primaryInsuranceOther">Other</label>
              </div>
              <div className="invalid-feedback">{primaryInsuranceOtherError?.message}</div>
              
              <TextInputGroup
                field='primaryInsuranceOtherText'
                className={data.primaryInsuranceOther ? '' : 'd-none'}
                label=''
                placeholder="Please specify..."
                value={data.primaryInsuranceOtherText}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'primaryInsuranceOtherText')} />
              
              <TextInputGroup
                field='primaryInsuranceStartDate'
                label='Start Date:'
                value={data.primaryInsuranceStartDate}
                inputType='date'
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'primaryInsuranceStartDate')} />
              
              <TextInputGroup
                field='primaryInsuranceEndDate'
                label='End Date:'
                value={data.primaryInsuranceEndDate}
                inputType='date'
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'primaryInsuranceEndDate')} />
              
              <FormSelectInputGroup
                  field='financialAssistance'
                  label='Financial Assistance:'
                  value={data.financialAssistance}
                  changeData={changeData}
                  validationError={errors.find((e) => e.field === 'financialAssistance')}
                  className="mb-3">
                <option value="">Choose an Option...</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="denied">Denied</option>
              </FormSelectInputGroup>
              
              <TextInputGroup
                field='provider'
                label='Provider:'
                value={data.provider}
                changeData={changeData}
                validationError={errors.find((e) => e.field === 'provider')} />
            </div>
          </div>
        </div>

        <div className="accordion-item d-md-none">
            <ExpensesTableMobile expenseRows={data.expenseRows} errors={errors} updateExpense={updateExpense} />
        </div>
        <div className="accordion-item d-none d-md-block">
            <ExpensesTableDesktop expenseRows={data.expenseRows} errors={errors} updateExpense={updateExpense} />
        </div>
      </div>
    </form>
  </div>
}

interface ExpenseTableProps {
  expenseRows: SubmissionData2024CHM['medicalBillWorksheetData']['expenseRows']
  errors: ValidationError[]

  updateExpense: (index: number, expense: SubmissionData2024CHM['medicalBillWorksheetData']['expenseRows'][number]) => void
}

function ExpensesTableMobile({expenseRows, updateExpense, errors}: ExpenseTableProps) {
  return <div className="expenses-table-mobile">
      <h2 className="accordion-header">
        <button className="accordion-button" type="button"
            data-bs-toggle='collapse' data-bs-target='#expenses-table-mobile'
            aria-expanded="true" aria-controls="expenses-table-mobile">
              
          <div className="expenses-table-mobile__th" style={{width: '20%'}}>
            Date
          </div>
          <div className="expenses-table-mobile__th" style={{width: '50%'}}>
            Provider
          </div>
          <div className="expenses-table-mobile__th" style={{width: '30%'}}>
            Original Charges
          </div>
        </button>
      </h2>
      
      <div className="accordion-collapse collapse show" id='expenses-table-mobile' data-bs-parent='medical-bill-worksheet'>
        {expenseRows.map((expense, index) => {
          const {dateOfService: date, billingProvider, paymentAmount, discountAmount, originalCharges} = expense

          return <div className="expenses-table-mobile__tr">
            <div className="expenses-table-mobile__tr-group expenses-table-mobile__tr-group__primary">
              <div className="expenses-table-mobile__td"  style={{width: '20%'}}>
                {date ? formatDateInTimeZone(date, { format: 'MMM d' }) : ''}
              </div>
              <div className="expenses-table-mobile__td" style={{width: '50%'}}>
                {billingProvider || ''}
              </div>
              <div className="expenses-table-mobile__td" style={{width: '30%'}}>
                {typeof originalCharges == 'string' ? formatCurrency(originalCharges) : ''}
              </div>
            </div>

            <div className="expenses-table-mobile__tr-group expenses-table-mobile__tr-group__secondary">
              <div className="expenses-table-mobile__td" style={{width: '50%'}}>
                <div>
                  <label>Payments:</label> <span>{typeof paymentAmount == 'string' ? formatCurrency(paymentAmount) : ''}</span>
                </div>
                <ChargeOptionsDropdown
                  options={[
                    { id: 'shownOnBill', label: 'Shown on Bill' },
                    { id: 'onPaymentPlan', label: 'On a Payment Plan' },
                    { id: 'noneMade', label: 'None Made' }
                  ]}
                  value={expense.paymentType}
                  error={errors.find((e) => e.field === `expenseRows[${index}].paymentType`)}
                  onChange={(value) => {
                    updateExpense(index, {
                      ...expense,
                      paymentType: value
                    })
                  }} />
              </div>
              <div className="expenses-table-mobile__td" style={{width: '50%'}}>
                <label>Discounts:</label> <span>{typeof discountAmount == 'string' ? formatCurrency(discountAmount) : ''}</span>
                <ChargeOptionsDropdown
                  options={[
                    { id: 'shownOnBill', label: 'Shown on Bill' },
                    { id: 'includedInCharge', label: 'Included in Charge' },
                    { id: 'noneAvailable', label: 'None Available' }
                  ]}
                  value={expense.discountType}
                  error={errors.find((e) => e.field === `expenseRows[${index}].discountType`)}
                  onChange={(value) => {
                    updateExpense(index, {
                      ...expense,
                      discountType: value
                    })
                  }} />
              </div>
            </div>
          </div>
        })}
      </div>
    </div>
}

function ExpensesTableDesktop({expenseRows, errors, updateExpense}: ExpenseTableProps) {
  return <div className="expenses-table-desktop">
      <h2 className="accordion-header">
        <button className="accordion-button" type="button"
            data-bs-toggle='collapse' data-bs-target='#expenses-table-mobile'
            aria-expanded="true" aria-controls="expenses-table-mobile">
              
          <div className="expenses-table-mobile__th" style={{width: '20%'}}>
            Date of Service
          </div>
          <div className="expenses-table-mobile__th" style={{width: '20%'}}>
            Billing Provider
          </div>
          <div className="expenses-table-mobile__th" style={{width: '20%'}}>
            Original Charges
          </div>
          <div className="expenses-table-mobile__th" style={{width: '20%'}}>
            Discounts
          </div>
          <div className="expenses-table-mobile__th" style={{width: '20%'}}>
            Payments
          </div>
        </button>
      </h2>
      
      <div className="accordion-collapse collapse show" id='expenses-table-mobile' data-bs-parent='medical-bill-worksheet'>
        {expenseRows.map((expense, index) => {
          const {dateOfService: date, billingProvider, paymentAmount, discountAmount, originalCharges} = expense

          return <div className="expenses-table-mobile__tr">
            <div className="expenses-table-mobile__tr-group expenses-table-mobile__tr-group__primary">
              <div className="expenses-table-mobile__td"  style={{width: '20%'}}>
                {date ? formatDateInTimeZone(date, { format: 'MMM d' }) : ''}
              </div>
              <div className="expenses-table-mobile__td" style={{width: '20%'}}>
                {billingProvider || ''}
              </div>
              <div className="expenses-table-mobile__td" style={{width: '20%'}}>
                {typeof originalCharges == 'string' ? formatCurrency(originalCharges) : ''}
              </div>
              <div className="expenses-table-mobile__td" style={{width: '20%'}}>
                {typeof discountAmount == 'string' ? formatCurrency(discountAmount) : ''}
                <ChargeOptionsDropdown
                  options={[
                    { id: 'shownOnBill', label: 'Shown on Bill' },
                    { id: 'includedInCharge', label: 'Included in Charge' },
                    { id: 'noneAvailable', label: 'None Available' }
                  ]}
                  value={expense.discountType}
                  error={errors.find((e) => e.field === `expenseRows[${index}].discountType`)}
                  onChange={(value) => {
                    updateExpense(index, {
                      ...expense,
                      discountType: value
                    })
                  }} />
              </div>
              <div className="expenses-table-mobile__td" style={{width: '20%'}}>
                {typeof paymentAmount == 'string' ? formatCurrency(paymentAmount) : ''}
                <ChargeOptionsDropdown
                  options={[
                    { id: 'shownOnBill', label: 'Shown on Bill' },
                    { id: 'onPaymentPlan', label: 'On a Payment Plan' },
                    { id: 'noneMade', label: 'None Made' }
                  ]}
                  value={expense.paymentType}
                  error={errors.find((e) => e.field === `expenseRows[${index}].paymentType`)}
                  onChange={(value) => {
                    updateExpense(index, {
                      ...expense,
                      paymentType: value
                    })
                  }} />
              </div>
            </div>
          </div>
        })}
      </div>
    </div>
}

interface ChargeOptionsDropdownProps<TOptions extends string> {
  options: { id: TOptions, label: string }[]
  value: TOptions | null | undefined,
  onChange: (value: TOptions | null | undefined) => void
  error?: ValidationError | null
}


function ChargeOptionsDropdown<TOptions extends string>({options, value, error, onChange}: ChargeOptionsDropdownProps<TOptions>) {
  return <>
    <div className={`charge-options-dropdown ${error ? 'is-invalid' : ''}`}>
      <DropdownSelect<{ id: TOptions, label: string }> className="charge-options-dropdown"
        value={value}
        options={options}
        onChange={(e) => {
          if (e.value && 'id' in e.value) {
            onChange(e.value.id as TOptions | null | undefined)
          }
        }}
        placeholder="choose an option..."
        renderOption={(x) => x.label}>
        
      </DropdownSelect>
    </div>
    
    <div className="invalid-feedback">{error?.message}</div>
  </>
}
