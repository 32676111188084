import { useNavigate } from "react-router";
import { CreateIncident } from "../components/forms/createIncident";
import { IncidentSelect } from "../components/formComponents/incidentSelect";

import './bulkAddExpense.scss'

export function BulkAddExpense() {
  const navigate = useNavigate()

  return <div className="bulk-add-expense">
    <h1>Bulk Add Expense</h1>
    <p>
      We need to create an incident to add your expenses to.
    </p>

    <div className="bulk-add-expense__form">
      <CreateIncident onSubmit={(incident) => {
        navigate(`/incidents/${incident.id}/bulkAddExpense`)
      }}/>
    </div>

    <hr />
    <p>
      Want to add to an existing incident instead? <br/>
      select one from the drop down below:
    </p>
    <IncidentSelect
      className="form-select"
      value=""
      blankOption="(no filter)"
      denyNewIncidents
      onSelect={(value) => {
        if (!value || !('id' in value)) return

        navigate(`/incidents/${value.id}/bulkAddExpense`)
      }}
      />
  </div>
}
