import { raiseUnknownSubmissionType } from "../../../../types/supabase";
import { RequireSubmissionProvided, requireSubmission } from "../../../wrappers/requireSubmission";
import { SubmitCHMAddOn } from "./submitCHMAddOn";
import { SubmitPersonalResponsibility } from "./submitPersonalResponsibility";
import { SubmitToCHM } from "./submitToCHM";
import { SubmitToHRA } from "./submitToHRA";

interface SubmitShowProps extends RequireSubmissionProvided {
}

export function SubmitShow({submission}: SubmitShowProps) {
  const type = submission.submission_type
  switch(type) {
    case 'CHM':
      return <SubmitToCHM submission={submission} />
    case 'CHM-addon':
      return <SubmitCHMAddOn submission={submission} />
    case 'HRA':
      return <SubmitToHRA submission={submission} />
    case 'CHM-personal-responsibility':
      return <SubmitPersonalResponsibility submission={submission} />
    default:
      raiseUnknownSubmissionType(type)
  }
}

export default requireSubmission(SubmitShow)
