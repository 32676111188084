import { useEffect, useState } from "react";
import { useIsOnline } from "../providers/supabase";

export function OnlineRequired({
  onConnectionEstablished
}: {
  onConnectionEstablished?: () => any
}) {
  const isOnline = useIsOnline()
  const [wasOnline, setWasOnline] = useState(isOnline)

  useEffect(() => {
    if (isOnline && !wasOnline) {
      onConnectionEstablished?.()
    }
  }, [isOnline, wasOnline, onConnectionEstablished])

  return <div className="container">
    <div className="row">
      <div className="col-12" style={{ textAlign: 'center' }}>
        <h1>Sorry about this!</h1>
        <p>
          You appear to be offline.  We need an internet connection to get some things
          set up for you.
        </p>
        <p>
          Please connect to the internet and try again.
        </p>
        <button type='button' onClick={() => isOnline ? onConnectionEstablished?.() : window.location.reload()}>
            I'm online!
          </button>
      </div>
    </div>
  </div>
}

export function requireOnline<TProps extends React.PropsWithChildren<object>>(
  Component: React.ComponentType<TProps>
): React.ComponentType<TProps> {

  return function AuthRequired(props: TProps) {
    const isOnline = useIsOnline()
    if (!isOnline) {
      return <OnlineRequired />
    }

    return <Component {...props} />
  }
}
