import Rusha from 'rusha'
import { present } from './present';

export function redactName(name: string): string
export function redactName(name: string | null | undefined): string | null | undefined
export function redactName(name: string | null | undefined): string | null | undefined {
  if (!name) {
    return name
  }

  return Rusha.createHash().update(name).digest('hex');
}

export function redactObjectExceptKeys<T extends Record<string, any>>(
  payload: T,
  ...keys: Array<keyof T>
): T  {
  return (Object.keys(payload) as Array<keyof T>).reduce((acc, key) => {
    if (keys.includes(key)) {
      // do not redact
      acc[key] = payload[key]
    } else {
      // It's OK to not redact null values, but we should redact any present values.
      // Knowing what values are null is important for debugging but does not reveal PII.
      const value = payload[key]
      acc[key] = present(value) ? 'REDACTED' as any : value
    }
    return acc
  }, {} as T)
}

export function redactObject<T extends { id: string, updated_at: string, deleted_at?: string | null }> (payload: T): T {
  return redactObjectExceptKeys(payload, 'id', 'updated_at', 'deleted_at')
}
