import React, { useState } from "react";
import process from 'process'
import { ChonkyInitializer } from "./initializers/chonky";
import { StartupFlowInitializer } from "./initializers/startupFlow";
import { OnlineStatusInitializer } from "./initializers/onlineStatus";

globalThis.process = process

const AllInitializers: React.ComponentType<{ onComplete: () => void }>[] = [
  ChonkyInitializer,
  OnlineStatusInitializer,
  StartupFlowInitializer,
]

export function Initializers({children}: React.PropsWithChildren) {
  const [idx, setIdx] = useState(0)

  if (idx >= AllInitializers.length) {
    return <>{children}</>
  }

  const CurrentInitializer = AllInitializers[idx]
  return <CurrentInitializer onComplete={() => setIdx(idx + 1)} />
}