import { PDFDocument, RotationTypes } from "pdf-lib"

export async function writeSignature(
  pdfBytes: ArrayBuffer,
  params: {
    signature: string,
    formField: string
  }): Promise<ArrayBuffer> {

  const pdfDoc = await PDFDocument.load(pdfBytes)

  const embeddedPng = await pdfDoc.embedPng(params.signature)
  pdfDoc.getForm().getField(params.formField).acroField.getWidgets().forEach((widget) => {
    const rect = widget.getRectangle()

    // expand over the rectangle by a few pixels so it looks like an actual signature
    rect.y -= 10;
    rect.x += 25;
    rect.height += 25;
    rect.width -= 50;
    // fit the width to the image's aspect ratio
    const {width, height} = embeddedPng.scaleToFit(rect.width, rect.height)

    // Random angle between -2 and 2 degrees
    const rotation = Math.random() * 4 - 2

    pdfDoc.getPage(0).drawImage(
      embeddedPng,
      {
        ...rect,
        width,
        height,
        rotate: {
          angle: rotation,
          type: RotationTypes.Degrees
        }
      }
    )
  })

  return await pdfDoc.save()
}
