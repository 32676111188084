import { isSubmissionData2023, isSubmissionData2024 } from "../../../reduxToolkit/submissionsSlice";
import { RequireSubmissionProvided } from "../../../wrappers/requireSubmission";
import { SubmitToCHM2023 } from "./submitToCHM/submitToCHM2023";
import { SubmitToChm2024 } from "./submitToCHM/submitToChm2024";

interface SubmitToCHMProps extends RequireSubmissionProvided {
}


export function SubmitToCHM({ submission }: SubmitToCHMProps) {
  const data = submission.data
  if (data) {
    // resume pending submission
    if (isSubmissionData2023(data)) {
      return <SubmitToCHM2023 submission={submission} />
    } else if (isSubmissionData2024(data)) {
      return <SubmitToChm2024 submission={submission} />
    }
    
    throw new Error(`Unknown submission data: ${data}`)
  }

  if (submission.incident.start_date <= '2023-12-31') {
    return <SubmitToCHM2023 submission={submission} />
  } else {
    return <SubmitToChm2024 submission={submission} />
  }
}
