import { ChonkyActions, ChonkyActionUnion, GenericFileActionHandler } from "chonky";
import { useCallback, useState } from "react";
import { useClient } from "../../providers/supabase";
import { CustomActionUnion, DownloadAllAsZip, DownloadSelection, handleDownloadAllAsZip, handleDownloadSelection, handleOpenFiles, handleOpenSelection } from "./actions";
import { BlobRow } from "../../../types/supabase";

type FileActionHandlerDeps = {
  files: {
    [folderPath: string]: BlobRow[]
  }
  baseFolder: string,
  baseFolderName?: string,
  setFolder: (folder: string) => void,
  setView: (view: 'list' | 'grid' | 'compact') => void
}

type FileActionHandler = GenericFileActionHandler<ChonkyActionUnion | CustomActionUnion>

interface FileActionHandlerState {
  acting: boolean
}

export function useBlobFileActionHandler(
  {
    files,
    baseFolder,
    baseFolderName,
    setFolder,
    setView
  }: FileActionHandlerDeps
): [FileActionHandlerState, FileActionHandler] {
  const client = useClient()

  const [acting, setActing] = useState(false)

  const handler = useCallback<FileActionHandler>(async (data) => {
    try {
      switch(data.id) {
        case ChonkyActions.OpenSelection.id:
          setActing(true)
          return await handleOpenSelection(data, {
            files,
            baseFolder,
            client,
            setFolder
          })
        case ChonkyActions.OpenFiles.id:
          setActing(true)
          return await handleOpenFiles(data, {
            files,
            baseFolder,
            client,
            setFolder
          })

        case ChonkyActions.EnableGridView.id:
          setView('grid')
          break;

        case ChonkyActions.EnableCompactView.id:
          setView('compact')
          break;

        case ChonkyActions.EnableListView.id:
          setView('list')
          break;

        case DownloadAllAsZip.id:
          setActing(true)
          return await handleDownloadAllAsZip(data, {
            files,
            baseFolder,
            baseFolderName: baseFolderName,
            client
          })

        case DownloadSelection.id:
          setActing(true)
          return await handleDownloadSelection(data, {
            files,
            baseFolder,
            client
          })
      }
    } finally {
      setActing(false)
    }
  }, [baseFolder, baseFolderName, client, files, setFolder, setView])

  return [{ acting }, handler]
}
