import { useCallback, useState } from 'react';
import { BlobUploadDropzone } from '../components/blobUploadDropzone';
import { NewExpenseRowForm, NewExpenseRowFormProps } from '../components/forms/newExpenseRowForm';
import { OnUploadedCallbackParams } from '../hooks/useBlobUpload';
import { useBlobOcr } from '../hooks/useBlobOcr';
import { FilePreview } from './filePreview';
import { useSentry } from '../providers/sentry';

import './quickAddExpense.scss'

import FileUploadIcon from '../../icons/file_upload_icon.svg'
import KeyboardIcon from '../../icons/keyboard_icon.svg'

export type QuickAddExpenseProps = NewExpenseRowFormProps

export function QuickAddExpense(props: QuickAddExpenseProps) {
  return <>
    <div className='d-none d-lg-block'>
      <QuickAddExpenseDesktop {...props} />
    </div>
    <div className='d-lg-none'>
      <QuickAddExpenseMobile {...props} />
    </div>
  </>
}

function QuickAddExpenseDesktop({onInserted, prefilledData, ...formProps}: QuickAddExpenseProps) {
  const {captureException} = useSentry()

  const [result, {error, reset}, createExpenseFromBlob] = useBlobOcr({
    prefilledExpense: prefilledData,
    onCompleted(result) {
      if (result.expense) {
        onInserted?.(result.expense, null)
      } else {
        captureException(new Error('No expense returned from blob OCR'))
      }
    },
  })

  const [view, setView] = useState<'snap' | 'form'>('snap')

  return <div className="row quick-add quick-add__desktop">
    {view === 'snap' && <>
      <div className="col-12 col-lg-5 quick-add__snap">
        <h5>Upload receipt or&nbsp;itemized&nbsp;bill</h5>
        <SnapAPhoto onUpload={createExpenseFromBlob} onDelete={reset} />
        <span className='mt-1 underline'>(PDF Or Image)</span>
        {error && <div className="alert alert-danger" role="alert">
          Something went wrong! {error.message || error.toString()}
        </div>}
      </div>

      <div className='col-12 col-lg-1 quick-add__separator'>
        <span className="quick-add__separator-or">OR</span>
      </div>

      <div className="col-12 col-lg-6 quick-add__manual">
        <h5>Enter new expense&nbsp;manually</h5>
        <button className={`btn btn-primary quick-add__keyboard-button`}
          onClick={() => {reset(); setView('form')}}>
            <img src={KeyboardIcon} alt="Enter new expense manually" />
        </button>
      </div>

    </>}

    {view === 'form' && <>
      <div className="col-12">
        <NewExpenseRowForm
          {...formProps}
          prefilledData={prefilledData}
          onInserted={onInserted} />
          <button className='btn btn-link' onClick={() => setView('snap')}>Or Snap a Photo</button>
      </div>
    </>}
  </div>

}

function QuickAddExpenseMobile({onInserted, prefilledData, ...formProps}: QuickAddExpenseProps) {
  const {captureException} = useSentry()

  const [result, {error, reset}, createExpenseFromBlob] = useBlobOcr({
    onCompleted(result) {
      if (result.expense) {
        onInserted?.(result.expense, null)
      } else {
        captureException(new Error('No expense returned from blob OCR'))
      }
    },
  })

  const [view, setView] = useState<'snap' | 'form'>('snap')

  return <div className="row quick-add quick-add__mobile">
    {view === 'snap' && <>
      <div className="col-12 quick-add__snap">
        <h6>Upload receipt or&nbsp;itemized&nbsp;bill</h6>
        <SnapAPhoto onUpload={createExpenseFromBlob} onDelete={reset} />
        <span className='underline'>(PDF Or Image)</span>
        {error && <div className="alert alert-danger" role="alert">
          Something went wrong! {error.message || error.toString()}
        </div>}
      </div>
      <div className='col-12 quick-add__separator'>
        <span className="quick-add__separator-or">OR</span>
      </div>
      <div className="col-12 quick-add__manual">
        <h6>Enter new expense manually</h6>
        <button className={`btn btn-primary quick-add__keyboard-button`}
          onClick={() => {reset(); setView('form')}}>
            <img src={KeyboardIcon} alt="Enter new expense manually" />
        </button>
      </div>
    </>}

    {view === 'form' && <>
      <div className="col-12">
        <h3>Add Expense</h3>
        <NewExpenseRowForm
          {...formProps}
          prefilledData={prefilledData}
          onInserted={onInserted} />
      </div>
      <div className='col-12 col-lg-1 quick-add__separator'>
        <span className="quick-add__separator-or">OR</span>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <button className='btn btn-primary' onClick={() => setView('snap')}>Snap a Photo</button>
      </div>
    </>}

  </div>
}


interface SnapAPhotoProps {
  onUpload: (params: OnUploadedCallbackParams) => void
  onDelete?: () => void
}

function SnapAPhoto({onUpload, onDelete}: SnapAPhotoProps) {
  const [uploadedBlob, setUploadedBlob] = useState<OnUploadedCallbackParams>()

  const _onUpload = useCallback((params: OnUploadedCallbackParams) => {
    setUploadedBlob(params)
    onUpload(params)
  }, [onUpload])

  return <div className="snap-a-photo">
    {uploadedBlob ?
      <FilePreview file={uploadedBlob.file} onDelete={() => {setUploadedBlob(undefined); onDelete?.()}} /> :
      <BlobUploadDropzone onUpload={_onUpload}>
        {<img src={FileUploadIcon} alt="Upload Receipt" />}
      </BlobUploadDropzone> }
  </div>
}
