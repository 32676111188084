import { useCallback, useState } from 'react';
import {v4 as uuid4} from 'uuid'
import { BlobUploadDropzone } from '../components/blobUploadDropzone';
import { EditExpenseRowForm } from '../components/forms/editExpenseRowForm';
import { useAppDispatch, useAppSelector } from '../hooks/reduxToolkit';
import { OnUploadedCallbackParams } from '../hooks/useBlobUpload';
import { createAttachment } from '../reduxToolkit/attachmentsSlice';
import { EditExpenseOnUpdatedFn, EditExpenseRowFormProps } from './forms/editExpenseRowForm';
import { Attachment } from './attachment';
import Toast from 'react-hot-toast';
import { PdfPreview } from '../components/preview/pdfPreview';

import './editExpense.scss'

export type EditExpenseProps = EditExpenseRowFormProps

export function EditExpense({onUpdated, expense, ...formProps}: EditExpenseProps) {
  const dispatch = useAppDispatch()

  const attachments = useAppSelector((s) => s.attachments.attachments?.filter((a) => a.record_id == expense.id) || [])

  const [showDropzone, setShowDropzone] = useState<boolean>(attachments.length <= 0)
  const [uploadedBlob, setUploadedBlob] = useState<OnUploadedCallbackParams>()

  const onUpload = useCallback(async (params: OnUploadedCallbackParams) => {
    setUploadedBlob(params)
  }, [])

  const _onUpdated = useCallback<EditExpenseOnUpdatedFn>((expense) => {
    if (uploadedBlob && !attachments.find((a) => a.blob_key === uploadedBlob.blob.key)) {
      dispatch(createAttachment({
        id: uuid4(),
        record_id: expense.id,
        table_name: 'expenses',
        blob_key: uploadedBlob.blob.key,
        membership_id: expense.membership_id!,
        updated_at: expense.updated_at,
        created_at: expense.created_at,
      }))
    }

    if (onUpdated) {
      onUpdated(expense)
    }
  }, [onUpdated, dispatch, uploadedBlob, attachments])

  return <div className="row edit-expense">
    {showDropzone &&
      <div className="col-12 col-lg-4 edit-expense__blob">
        {uploadedBlob ?
          <div className="card">
            <div className="card-body">
              <p className="card-text">
                {uploadedBlob.file.type == 'application/pdf' ?
                  <PdfPreview file={uploadedBlob.file} /> :
                  <img src={URL.createObjectURL(uploadedBlob.file)} alt='preview' />}
                <button className='btn btn-link edit-expense__blob__delete'
                    onClick={() => {
                      setUploadedBlob(undefined)
                    }}>
                  <i className='material-icons'>delete</i>
                </button>
              </p>
            </div>
          </div> :
          <BlobUploadDropzone onUpload={onUpload}>
            <i className='material-icons'>file_download</i>
            Click to Upload an Itemized Bill
          </BlobUploadDropzone> }
      </div>}

    <div className="col-12 col-lg-8">
      <EditExpenseRowForm
        expense={expense}
        {...formProps}
        onUpdated={_onUpdated} />
    </div>

    <div className='col-12'>
      <div className="edit-expense__attachments">
        {
          attachments.map((attachment) => {
            return <Attachment key={attachment.id} attachment={attachment} deletable
              onDelete={() => {
                Toast.success('Attachment deleted')
              }}/>
          })
        }

        {(!showDropzone) && <>
          <hr />
          <button className='btn btn-link edit-expense__attachments__add' onClick={() => {
            setUploadedBlob(undefined)
            setShowDropzone(true)
          }}>
            Add Another Receipt or File
          </button>
        </>}

      </div>
    </div>

  </div>
}
