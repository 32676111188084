import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ExpenseInsert, ExpenseRow, ExpenseUpdate, NotDeleted } from '../../types/supabase'
import type { DeepPartialNullable, NotNull } from '../../types/util'
import { PURGE } from 'redux-persist';
import { redactName, redactObjectExceptKeys } from '../../lib/util/redact'
import { replaceOrInsert } from '../../lib/util/replaceOrInsert'
import { present } from '../../lib/util/present'
import { onSyncDownComplete } from './actions/onSyncDownComplete';

/**
 * The required fields for creating a new expense.  At minimum we must provide
 * a filled ExpenseModel.
 */
export type ExpenseInsertPayload = ExpenseModel
/**
 * The required fields for updating an existing expense.
 * Updating only requires us to set the values that we intend to update,
 *  but we must match the ID and provide a new updated_at timestamp.
 */
export type ExpenseUpdatePayload = NotNull<NotDeleted<ExpenseUpdate>, 'id' | 'updated_at'>

/**
 * The local model of an Expense row, which may be a row from the server or a row that has been
 * inserted locally and not yet synced to the server.
 */
export type ExpenseModel = {
  id: string
  updated_at: string

  created_at: string
  created_by_user_id: string

  membership_id: string
  incident_id?: string | null

  date?: string | null

  listedAmount?: string | null
  paidAmount?: string | null
  patient_dob?: string | null
  patient_name?: string | null
  provider?: string | null

  submission_id?: string | null
  submitted_at?: string | null
  
  textract_job_id?: string | null
  textract_job_started_at?: string | null
  textract_job_completed_at?: string | null
  textract_job_status?: string | null

  deleted_at?: string | null
  
  paid_with_hra?: boolean | null
}

export const requiredExpenseFields = ['date', 'incident_id', 'listedAmount', 'paidAmount', 'patient_dob', 'patient_name', 'provider'] as const
type RequiredExpenseFields = typeof requiredExpenseFields[number]

/**
 * Represents an ExpenseModel that has been fully filled in, and is ready to be submitted for reimbursement.
 * Incomplete expenses cannot be submitted.
 */
export type CompleteExpense = NotNull<ExpenseModel, RequiredExpenseFields>
export function isCompleteExpense(e: ExpenseModel | ExpenseRow): e is CompleteExpense {
  return requiredExpenseFields.every((f) => present(e[f]))
}
export function assertIsCompleteExpense(e: ExpenseModel | ExpenseRow): asserts e is CompleteExpense {
  if (!isCompleteExpense(e)) {
    throw new Error(`Expense ${e.id} is not complete`)
  }
}

export type SubmittedExpense = NotNull<CompleteExpense, 'submission_id' | 'submitted_at'>
export function isSubmittedExpense(e: ExpenseModel | ExpenseRow): e is SubmittedExpense {
  return isCompleteExpense(e) && present(e.submission_id) && present(e.submitted_at)
}
export function assertIsSubmittedExpense(e: ExpenseModel | ExpenseRow): asserts e is SubmittedExpense {
  if (!isSubmittedExpense(e)) {
    throw new Error(`Expense ${e.id} is not submitted`)
  }
}

export function isCompleteExceptForIncidentID(e: ExpenseModel | ExpenseRow): e is NotNull<ExpenseModel, Exclude<RequiredExpenseFields, 'incident_id'>> {
  return requiredExpenseFields.filter((f) => f !== 'incident_id').every((f) => present(e[f]))
}

export function isTextractPending(e: ExpenseModel | ExpenseRow): e is NotNull<ExpenseModel, 'textract_job_id' | 'textract_job_started_at' | 'textract_job_status'> {
  return present(e.textract_job_id) && present(e.textract_job_started_at) &&
    !present(e.textract_job_completed_at)
}

// 10 minutes
const TEXTRACT_TIMEOUT_MILLISECONDS = 10 * 60 * 1000

export function isTextractJobFailed(e: ExpenseModel | ExpenseRow): e is NotNull<ExpenseModel, 'textract_job_id' | 'textract_job_started_at' | 'textract_job_status'> {
  if (!present(e.textract_job_status)) { return false }

  if (e.textract_job_status === 'FAILED') { return true }
  
  if (!present(e.textract_job_completed_at) && present(e.textract_job_started_at)) {
    return Date.parse(e.textract_job_started_at) < (Date.now() - TEXTRACT_TIMEOUT_MILLISECONDS)
  }

  return false
}

export type ExpensesSliceState = {
  expenses: Array<ExpenseModel>
  deleted?: Array<{ id: string, updated_at: string, deleted_at?: string }>
}

const initialState: ExpensesSliceState = {
  expenses: [],
}

export const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    addExpense(state, action: PayloadAction<ExpenseInsertPayload>) {
      const model: ExpenseModel = {
        ...action.payload,
      }
      state.expenses.push(model)
    },
    updateExpense(state, action: PayloadAction<ExpenseUpdatePayload>) {
      const i = state.expenses.findIndex((t) => t.id === action.payload.id)
      if (i < 0) {
        throw new Error(`Could not find expense with id ${action.payload.id}`)
      }

      // Replace the record with the updated values
      state.expenses[i] = {
        ...state.expenses[i],
        ...action.payload,
      }
    },
    deleteExpense(state, action: PayloadAction<{ id: string, updated_at: string, deleted_at: string }>) {
      // Remove it out of the expenses array
      const i = state.expenses.findIndex((e) => e.id === action.payload.id)
      if (i >= 0) {
        state.expenses.splice(i, 1)
      }
    },
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    })
    builder = builder.addCase(onSyncDownComplete, (state, action) => {
      // Update any expenses or incidents that have been updated on the server
      for (const expense of action.payload.expenses?.updated || []) {
        replaceOrInsert(state.expenses, expense)
      }

      for (const deletedExpense of action.payload.expenses?.deleted || []) {
        const i = state.expenses.findIndex((t) => t.id === deletedExpense.id)
        if (i >= 0 && state.expenses[i].updated_at <= deletedExpense.updated_at) {
          state.expenses.splice(i, 1)
        }
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  addExpense,
  updateExpense,
  deleteExpense,
} = expensesSlice.actions

export type ExpensesSliceAction = ReturnType<typeof expensesSlice.actions[keyof typeof expensesSlice.actions]>

export function isExpensesSliceAction(action: any): action is ExpensesSliceAction {
  return action.type?.startsWith(expensesSlice.name)
}

export default expensesSlice.reducer

/*
 * Redact all sensitive information from the expenses and incidents, so that they can be sent to Analytics tools like
 * Sentry or Amplitude.
 * Due to HIPAA compliance, we cannot send any patient information to these tools.
 * The redaction operates with a whitelist to ensure that we don't accidentally send any sensitive information in the future.
*/

export function redactExpenses(state: ExpensesSliceState): DeepPartialNullable<ExpensesSliceState> {
  return {
    expenses: state.expenses.map(redactExpense),
  }
}

export function redactExpense(expense: Partial<ExpenseModel>): DeepPartialNullable<ExpenseModel> {
  return {
    ...redactObjectExceptKeys(expense,
      'id', 'created_at', 'updated_at', 'created_by_user_id', 'date', 'membership_id',
       'incident_id', 'submission_id', 'submitted_at', 'deleted_at'),
    patient_name: redactName(expense.patient_name),
  }
}

export function redactExpensesSliceAction(action: ExpensesSliceAction) {
  switch(action.type) {
    case addExpense.type:
    case updateExpense.type:
    case deleteExpense.type:
      return {
        type: action.type,
        payload: redactExpense(action.payload)
      }

    default:
      return {
        type: (action as any).type,
        payload: 'REDACTED'
      }
  }
}

// Assert we can assign an ExpenseRow to an ExpenseModel
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertExpenseRowAssignable: ExpenseModel = {} as ExpenseRow

// Assert we can assign an ExpenseModel to an ExpenseInsert
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertExpenseRowAssignable2: ExpenseInsert = {} as ExpenseModel

// Assert we can update an ExpenseRow from a partial ExpenseModel
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertExpenseRowAssignable3: ExpenseUpdate = {} as ExpenseUpdatePayload
