/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'

export type AsyncActionResult<T> =
  {
    loading: false,
    error?: undefined,
    data?: undefined
  } | {
    loading: true,
    error?: undefined,
    data?: undefined
  } | {
    loading: false,
    error: Error,
    data?: undefined
  } | {
    loading: false,
    error?: undefined,
    data: T
  }

export function useAsyncAction<T extends (...args: any[]) => Promise<any>>(action: T, deps?: DependencyList) {
  const [result, setResult] = useState<AsyncActionResult<Awaited<ReturnType<T>>>>({
    loading: false,
  })
  const invocationCount = useRef<number>(0)

  const wrapped: T = useCallback((...args: any[]) => {
    // restart the sequence
    setResult({
      loading: true,
    })
    const invocationId = invocationCount.current + 1 % (Number.MAX_SAFE_INTEGER / 2)
    invocationCount.current = invocationId

    // run the action
    const promise = action(...args)
    // handle results
    promise.then(
        (data) => {
          if (invocationId !== invocationCount.current) {
            // this is a stale invocation, ignore it
            return
          }

          // success!
          setResult({
            loading: false,
            data: data as any
          })
          return data
        },
        (err) => {
          console.log('error!', err, invocationId, invocationCount.current)
          if (invocationId !== invocationCount.current) {
            // this is a stale invocation, ignore it
            return
          }
          
          setResult({
            loading: false,
            error: err
          })
          throw err
        })
        
    // return the promise so the invoker can also handle the result
    return promise
  }, deps || []) as T

  return [
    result,
    wrapped,
    reset
  ] as const

  function reset() {
    setResult({
      loading: false,
    })
  }
}
