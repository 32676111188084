import { AppSupabaseClient, OrganizationRow } from "../../types/supabase"
import { present } from "../util/present"

type SelectOrganizationForUserParams = {
  email: string | null | undefined
  id?: string | null | undefined
}

export async function selectOrganizationForUser(
  client: AppSupabaseClient,
  {email, id: userId}: SelectOrganizationForUserParams
): Promise<OrganizationRow | null> {
  if (present(userId)) {
    // see if the user already has an organization membership
    const membershipResponse = await client.from('membership_roles')
      .select('membership_id')
      .eq('user_id', userId)
    if (membershipResponse.error) { throw new Error(membershipResponse.error.message) }
    
    const membershipIds = membershipResponse.data?.map((row) => row.membership_id).filter(present) || []
    if (membershipIds.length > 0) {
      const orgMemResponse = await client.from('organization_memberships')
        .select('organization_id')
        .in('membership_id', membershipIds)
        .limit(1)
        .maybeSingle()
      if (orgMemResponse.error) { throw new Error(orgMemResponse.error.message) }
      
      if (orgMemResponse.data?.organization_id) {
        const orgId = orgMemResponse.data.organization_id
        const orgResponse = await client.from('organizations')
          .select('*')
          .eq('id', orgId)
        if (orgResponse.error) { throw new Error(orgResponse.error.message) }

        if (orgResponse.data?.length > 0) {
          return orgResponse.data[0]
        }
      }
    }
  }

  if (present(email)) {
    // See if an organization has a matching email domain
    const domain = email.split('@')[1]
    const orgResponse = await client.from('organizations')
      .select('*')
      .contains('email_domains', [domain])
    if (orgResponse.error) { throw new Error(orgResponse.error.message) }
    
    if (orgResponse.data?.length > 0) {
      return orgResponse.data[0]
    }
  }
  
  // At this point, we haven't found an organization for them yet, but there may be a link via a promo code redemption.
  if (present(userId)) {
    const redemptionResponse = await client.from('promo_code_redemptions')
      .select('organization_id')
      .eq('user_id', userId)
    if (redemptionResponse.error) { throw new Error(redemptionResponse.error.message) }
    
    if (redemptionResponse.data?.length > 0) {
      const orgResponse = await client.from('organizations')
        .select('*')
        .eq('id', redemptionResponse.data[0].organization_id)
      if (orgResponse.error) { throw new Error(orgResponse.error.message) }

      if (orgResponse.data?.length > 0) {
        // Found it!
        return orgResponse.data[0]
      }
    }
  }
  
  return null
}
