import { useNavigate } from "react-router-dom"
import type { TodoConfirm } from "../../../lib/todos/types"

interface ConfirmNavigateActionProps {
  route: string,
  confirm?: TodoConfirm,
  onCompleted: () => void,
  onCancelled: () => void
}

export function ConfirmNavigateAction({ route, confirm, onCompleted, onCancelled }: ConfirmNavigateActionProps) {
  const navigate = useNavigate()

  const navigateAndComplete = () => {
    navigate(route)
    onCompleted()
  }
  
  const title = typeof confirm == 'string' ? confirm : confirm?.title || 'Ready to work on this?'
  const body = typeof confirm == 'string' ? null : confirm?.body || null

  return <div className="row">
    <div className="col">
      <h3 style={{textAlign: 'center'}}>{title}</h3>
      {body && <p>{body}</p>}
      <div className="w-100 d-flex justify-content-center">
        <div className="btn-group btn-group-lg">
          <button type="button" className="btn btn-primary"
            onClick={navigateAndComplete}>
              Yes
          </button>
          <button type="button" className="btn btn-outline-secondary"
            onClick={onCancelled}>
              No
          </button>
        </div>
      </div>
    </div>
  </div>
}
