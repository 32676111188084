import { isCompleteExpense, type CompleteExpense } from "../expensesSlice";
import type { IncidentModel } from "../incidentsSlice";
import { RootState } from "../store";
import { SubmissionModel } from "../submissionsSlice";

export type SubmissionById =
  SubmissionModel & {
    incident: IncidentModel,
    expenses?: CompleteExpense[]
  }

export function selectSubmissionById(id: string): (state: RootState) => SubmissionById | null {
  return (state) => {
    const submission = state.submissions.submissions.find((i) => i.id === id)
    if (!submission) {
      return null
    }
    
    const incident = state.incidents.incidents.find((i) => i.id === submission.incident_id)
    if (!incident) {
      return null
    }
    let expenses: CompleteExpense[] | undefined = undefined
    if (submission.expense_ids) {
      expenses = state.expenses.expenses
        .filter((e) => submission.expense_ids?.includes(e.id))
        .filter(isCompleteExpense) // all expenses must be complete expenses
    }

    return {
      ...submission,
      incident,
      expenses
    }
  }
}
