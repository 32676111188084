import { RequireSubmissionProvided } from "../../../../wrappers/requireSubmission";
import { useCallback, useMemo } from "react";
import { PendingSubmissionModel, SubmissionData2024CHM, SubmissionData2024CHMStep, SubmissionData2024Expense, SubmissionData2024Steps, isSubmissionData2024, updateSubmission } from "../../../../reduxToolkit/submissionsSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxToolkit";
import { useUser } from "../../../../hooks/useUser";
import { cloneDeep, debounce, max } from "lodash";
import { SharingRequestForm2024 } from "./2024/sharingRequestForm2024";
import { MedicalBillWorksheet2024 } from "./2024/medicalBillWorksheet2024";
import { present } from "../../../../../lib/util/present";
import { usePendingExpenses } from "../../../../hooks/usePendingExpenses";
import { selectDependentForIncident } from "../../../../reduxToolkit/selectors/dependents";
import { MedicalInformationRelease2024 } from "./2024/medicalInformationRelease2024";
import { MaternityVerificationForm2024 } from "./2024/maternityVerificationForm2024";
import { SubmissionOverview2024 } from "./2024/submissionOverview2024";
import { validateSubmissionData2024CHM } from "./validation";
import { useNavigate } from "react-router";
import { SubmissionNextSteps2024 } from "./2024/submissionNextSteps2024";
import { reconcileExpenses } from "./2024/utils/reconcileExpenses";
import { formatDateInTimeZone } from "../../../../../lib/formatDateInTimeZone";

import './submitToChm2024.scss'


export interface SubmitToCHM2024Props extends RequireSubmissionProvided {
}

export function SubmitToChm2024({ submission }: SubmitToCHM2024Props) {
  const navigate = useNavigate()
  let data: SubmissionData2024CHM = useSubmissionData2024(submission)

  const dispatch = useAppDispatch()
  const updateData = useCallback((data: SubmissionData2024CHM) => {
      const now = new Date().toISOString()
      dispatch(updateSubmission({
        id: submission.id,
        data,
        updated_at: now
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission.id, dispatch])

  const advance = useCallback((to?: SubmissionData2024CHM['workingOn']) => {
    if (typeof to != 'string') { to = undefined } // in case we pass in an event
    
    const current = data.workingOn
    if (!to) {
      // find the next step that has a validation error
      const steps = SubmissionData2024Steps
      const errors = validateSubmissionData2024CHM(data)
      const idx = current ? steps.indexOf(current) : -1
      for(let i = idx + 1; i < steps.length; i = (i + 1) % steps.length) {
        const step = steps[i]
        if (errors[`${step}Data`]?.length) {
          to = step
          break
        }
        if (i === idx) {
          // we've gone all the way around and found no errors
          to = undefined
          break
        }
      }
    }
    
    if (to == current) {
      // we've gone all the way around again, go home
      to = undefined
    }

    updateData({
      ...data,
      workingOn: to
    })
  }, [data, updateData])
  
  const home = useCallback(() => {
    updateData({
      ...data,
      workingOn: undefined
    })
  }, [data, updateData])
  
  let inner: React.ReactNode

  if (data.documentsGenerated) {
    inner = <SubmissionNextSteps2024
      submission={submission}
      onSubmitted={() => {
        navigate(`/incidents/${submission.incident_id}`)
      }}
      />
  } else {
    switch(data.workingOn) {
      case 'sharingRequestForm':
        inner = <SharingRequestForm2024 data={data} updateData={updateData} advance={advance} home={home} />
        break;
      case 'medicalBillWorksheet':
        inner = <MedicalBillWorksheet2024 data={data} updateData={updateData} advance={advance} home={home} />
        break;
      case 'medicalInformationRelease':
        inner = <MedicalInformationRelease2024 data={data} updateData={updateData} advance={advance} home={home} />
        break;
      case 'maternityVerificationForm':
        inner = <MaternityVerificationForm2024 data={data} updateData={updateData} advance={advance} home={home} />
        break;
      default:
        inner = <SubmissionOverview2024 submission={submission} data={data} updateData={updateData} advance={advance} home={home} />
        break;
    }
  }

  return <div className="submitToChm2024">
      {inner}
    </div>
}

export interface FormStepProps {
  data: SubmissionData2024CHM
  updateData: (data: SubmissionData2024CHM) => void
  advance: (to?: SubmissionData2024CHM['workingOn']) => void
  home: () => void
}

function useSubmissionData2024(submission: PendingSubmissionModel): SubmissionData2024CHM {
  const {member_number, profile} = useAppSelector((s) => s.membership)
  const user = useUser()
  const incident = useAppSelector((s) => s.incidents.incidents.find((i) => i.id === submission.incident_id))
  const expenses = usePendingExpenses(submission)
  const dependent = useAppSelector(selectDependentForIncident(incident))
  
  const data = useMemo(() => {
    if(isSubmissionData2024(submission.data)) {
      if (submission.data.type === 'CHM') {
        return reconcileExpenses(
          cloneDeep(submission.data),  // submission.data comes from the store and should not be mutated
          expenses)
      }
    }
    
    const isSelf = incident?.patient_name === profile?.full_name && incident?.patient_dob === profile?.date_of_birth
    const isChild = dependent?.relationship === 'child'
    const today = formatDateInTimeZone(new Date(), { format: 'MM/dd/yyyy' })
    
    let printedNameOfAuthorizedRepresentative: string | null | undefined = null
    if (dependent?.authorized_representative_name) {
      printedNameOfAuthorizedRepresentative = dependent.authorized_representative_name
    } else {
      printedNameOfAuthorizedRepresentative = isChild ? profile?.full_name : null
    }
    let authorizedRepresentativeRelationship: string | null | undefined = null
    if (dependent?.authorized_representative_relationship) {
      authorizedRepresentativeRelationship = dependent.authorized_representative_relationship
    } else {
      authorizedRepresentativeRelationship = isChild ? 'Parent' : null
    }
    let signatureData: string | null | undefined = null
    if (dependent?.signature_data) {
      signatureData = dependent.signature_data
    } else if (isSelf) {
      signatureData = profile?.signature_data
    } else if (isChild) {
      signatureData = profile?.signature_data
    }

    const data: SubmissionData2024CHM = {
      _version: '2024-04-01',
      type: 'CHM',
      workingOn: undefined,
      sharingRequestFormData: {
        memberNumber: member_number,
        memberName: profile?.full_name,
        phone: profile?.phone,
        email: ('email' in user) ? user.email : undefined,
        credits: true,
        patientName: incident?.patient_name,
        patientDOB: incident?.patient_dob,
        illness: incident?.description,
        illnessDate: incident?.start_date,

        signatureData,
        signatureDate: today,
        
      },
      medicalBillWorksheetData: {
        isAddOn: false,
        memberNumber: member_number,
        patientName: incident?.patient_name,
        expenseRows: [],
      },
      medicalInformationReleaseData: {
        patientName: incident?.patient_name,
        memberNumber: member_number,
        patientDOB: incident?.patient_dob,
        ssnLast4: isSelf ? profile?.ssn_last_4 : dependent?.ssn_last_4,
        address: profile?.address,
        phoneNumber: profile?.phone,

        initialsData: nameToInitials(
          // Initials are me if this is a self-submission
          isSelf ?
            profile?.full_name :
            // Otherwise the authorized rep if there is one saved for this dependent
            printedNameOfAuthorizedRepresentative ?
              printedNameOfAuthorizedRepresentative :
              // Otherwise if this is a child, the parent
              isChild ?
                profile?.full_name :
                // Otherwise the patient
                incident?.patient_name
        ),

        signatureData,
        printedNameOfPatient: incident?.patient_name,
        printedNameOfAuthorizedRepresentative,
        authorizedRepresentativeRelationship,
        date: today,
      },
      maternityVerificationFormData: {
        credits: true,
        memberNumber: member_number,
        patientName: incident?.patient_name,

        consentMemberName: printedNameOfAuthorizedRepresentative || profile?.full_name,
        signatureData,
        consentDate: today,
      }
    }
    return reconcileExpenses(data, expenses)
  // updated_at is sufficient to trigger a refresh of the data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission.updated_at, incident?.updated_at, max(expenses.map((e) => e.updated_at))])
 
  return data
}


function raiseUnknownWorkingOn(workingOn: never) {
  throw new Error(`Unknown workingOn value: ${workingOn}`)
}

function nameToInitials(name: string | null | undefined): string | null | undefined {
  return name?.split(' ').filter(present).map((name) => name[0]).join('').toLocaleUpperCase()
}
