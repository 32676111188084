import { Link, Outlet, useNavigate } from "react-router-dom";
import { formatDateInTimeZone } from "../../../lib/formatDateInTimeZone";

import './submit.scss'
import { RequireIncidentProvided, requireIncident } from "../../wrappers/requireIncident";

interface IncidentsSubmitProps extends RequireIncidentProvided {
}

function IncidentsSubmit({incident}: IncidentsSubmitProps) {

  const navigate = useNavigate()

  return <div className="incidents-submit mt-4">
    <div className="row">
      <div className="col-12">
        <h1>
          {incident.description}
        </h1>
        <p>
          <strong>Patient:</strong> <br />
          {incident.patient_name}
        </p>
        <p>
          <strong>Start Date:</strong> <br />
          {formatDateInTimeZone(incident.start_date, { format: 'MMM d, yyyy' })}
        </p>
      </div>
    </div>

    <hr className='hr-thick' />

    <Outlet />

  </div>
}

export default requireIncident(
      IncidentsSubmit
    )

