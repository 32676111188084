import { useClient, useSession } from "../providers/supabase"
import { isFilledProfile, setProfile } from "../reduxToolkit/membershipSlice"
import { useAppDispatch } from "./reduxToolkit"
import { useAsyncEffect } from "./useAsyncEffect"

export function useLoadProfileFromServer() {
  const client = useClient()
  const dispatch = useAppDispatch()
  const userId = useSession()?.user?.id

  return useAsyncEffect(async () => {
    if (!userId) { return }

    const resp = await client.from('profiles')
      .select('*')
      .eq('id', userId)

    if (resp.error) { throw resp.error }

    const profile = resp.data && resp.data[0]
    if (!profile) { return }

    if (isFilledProfile(profile)) {
      dispatch(setProfile({ profile }))
    }
  }, [client, dispatch, userId])
}
