import { AppSupabaseClient, MembershipRoleRow, MembershipRow, ProfileRow } from "../../types/supabase"

type SelectMembershipForUserResult =
  {
    role: MembershipRoleRow
    membership: MembershipRow,
    profile: ProfileRow
  }

export async function selectMembershipForUser(
  client: AppSupabaseClient,
  params: {
    userId: string
  }
): Promise<SelectMembershipForUserResult | null> {
  const resp = await client.from('membership_roles')
    .select(`*, memberships (*)`)
    .eq('user_id', params.userId)
    .limit(1)

  if (resp.error) { throw resp.error }

  const resp2 = await client.from('profiles')
    .select('*')
    .eq('id', params.userId)
    .limit(1)

  if (resp2.error) { throw resp2.error }

  const mRow = resp.data[0]
  if (!mRow) { return null }
  const pRow = resp2.data[0]
  if (!pRow) { return null }

  const membership =
    Array.isArray(mRow.memberships) ? mRow.memberships[0] : mRow.memberships!
  return {
    role: mRow as MembershipRoleRow,
    membership,
    profile: pRow as ProfileRow
  }
}
