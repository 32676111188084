import { AppSupabaseClient, Tables } from "../../types/supabase";
import { NotNull } from "../../types/util";
import type { RowSyncResponse, SyncableTable } from "./action";

export type Add<Table extends SyncableTable> = {
  type: 'add',
  table: Table,
  record: NotNull<Tables[Table]['Insert'], 'id' | 'updated_at'>
}

export async function syncAdd<Table extends SyncableTable>(
  client: AppSupabaseClient,
  action: Add<Table>
): Promise<RowSyncResponse<Table>> {
  const resp = await client.from(action.table)
    .insert(action.record as any)
    .select('*');

  if (resp.error) {
    if (resp.error.message.includes('violates unique constraint')) {
      // This is a duplicate insert - return the existing row
      const fromServer = await client.from(action.table)
        .select('*')
        .eq('id', action.record.id)

      return {
        result: 'rejected',
        data: (fromServer.data && fromServer.data[0]) as any
      }
    }
    return {
      result: 'error',
      error: resp.error
    };
  }

  // Our insert was applied and we have data - return it
  if (resp.data && resp.data[0]) {
    return {
      result: 'applied',
      data: resp.data[0] as any
    };
  }
  return {
    result: 'error',
    error: new Error(`Unable to insert row with ID '${action.record.id}'`)
  };
}
